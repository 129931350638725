import React, { FC, SVGProps } from 'react'

const OpenLinkSvg: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='13'
			height='13'
			viewBox='0 0 13 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M4.77532 1.7002H3.05119C2.28941 1.7002 1.67188 2.31773 1.67188 3.07951V10.3209C1.67188 11.0827 2.28941 11.7002 3.05119 11.7002H10.2926C11.0544 11.7002 11.6719 11.0827 11.6719 10.3209V8.59675M11.6719 4.80364V1.7002H8.56843M11.4995 1.87261L6.49946 6.87261'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default OpenLinkSvg
