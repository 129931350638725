import React, { useEffect, useState } from 'react'
import styles from './PaymentsPage.module.scss'
import Container from '../../components/UI/Container/Container'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import DateRangePicker from '../../components/UI/DateRangePicker/DateRangePicker'
import StatItem from '../../components/StatItem/StatItem'
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import { PaymentsDashboardService } from '../../API/PaymentsDashboardService'
import { PaymentsStatData } from '../../types/PaymentsStatData'
import Button, { ButtonStyles } from '../../components/UI/Button/Button'
import PlusIcon from '../../images/PlusIcon'

const PaymentsPage = () => {
	const [isCreatePaymentModalVisible, setIsCreatePaymentModalVisible] = useState(false)
	const [isCreatePaymentModalActive, setIsCreatePaymentModalActive] = useState(false)
	const { business } = useAppContext()
	const [toDate, setToDate] = useState<Date | null>(new Date())
	const [fromDate, setFromDate] = useState<Date | null>(() => {
		const today = new Date()
		const yesterday = new Date(today)
		yesterday.setDate(today.getDate() - 1)
		return yesterday
	})
	const durationMs = toDate && fromDate ? toDate.getTime() - fromDate.getTime() : 0
	const prevFromDate = fromDate ? new Date(fromDate.getTime() - durationMs) : null

	const [isDashboardLoading, dashboardData, getDashboard] = useAPI<PaymentsStatData[]>(
		() => PaymentsDashboardService.getDashboard(business?.id, fromDate, toDate),
		false
	)
	const [, prevDashboardData, getPrevDashboard] = useAPI<PaymentsStatData[]>(
		() => PaymentsDashboardService.getDashboard(business?.id, prevFromDate, fromDate),

		false
	)

	useEffect(() => {
		if (business) {
			getDashboard()
			getPrevDashboard()
		}
	}, [business, fromDate, toDate])

	return (
		<Container>
			<PageWrapper>
				<div className={styles.paymentsPage}>
					<div className={styles.pageHead}>
						<header className={styles.header}>
							<h2>Payments</h2>
						</header>
						<div className={styles.buttons}>
							<DateRangePicker
								fromDate={fromDate}
								toDate={toDate}
								setToDate={setToDate}
								setFromDate={setFromDate}
							/>
							<Button
								icon={<PlusIcon />}
								onClick={() => setIsCreatePaymentModalActive(true)}
								style={ButtonStyles.TRANSPARENT}
							>
								Create Payment
							</Button>
						</div>
					</div>
					{isDashboardLoading ? (
						<Spinner />
					) : (
						<div className={styles.pageStats}>
							<StatItem
								title={'Gross income'}
								data={dashboardData?.map(revenue => ({
									count: revenue.totalAmount,
									dateOrHour: revenue.dateOrHour,
								}))}
								isMoney={true}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevDashboardData?.map(revenue => ({
									count: revenue.totalAmount,
									dateOrHour: revenue.dateOrHour,
								}))}
								durationMs={durationMs}
								isHorizontal={true}
							/>

							<StatItem
								title={'Net income'}
								data={dashboardData?.map(revenue => ({
									count: revenue.netAmount,
									dateOrHour: revenue.dateOrHour,
								}))}
								isMoney={true}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevDashboardData?.map(revenue => ({
									count: revenue.netAmount,
									dateOrHour: revenue.dateOrHour,
								}))}
								durationMs={durationMs}
								isHorizontal={true}
							/>

							<StatItem
								title={'Payments'}
								data={dashboardData?.map(revenue => ({
									count: revenue.count,
									dateOrHour: revenue.dateOrHour,
								}))}
								isMoney={false}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevDashboardData?.map(revenue => ({
									count: revenue.count,
									dateOrHour: revenue.dateOrHour,
								}))}
								durationMs={durationMs}
								isHorizontal={true}
							/>
						</div>
					)}

					<div className={styles.transactions}>
						<PaymentsTable
							businessId={business?.id}
							isCreatePaymentModalActive={isCreatePaymentModalActive}
							isCreatePaymentModalVisible={isCreatePaymentModalVisible}
							setIsCreatePaymentModalVisible={setIsCreatePaymentModalVisible}
							setIsCreatePaymentModalActive={setIsCreatePaymentModalActive}
						/>
					</div>
				</div>
			</PageWrapper>
		</Container>
	)
}

export default PaymentsPage
