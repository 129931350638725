import React, { FC } from 'react'
import styles from './PaymentMainTable.module.scss'
import { PaymentDetailed } from '../../types/PaymentDetailed'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { getPaymentMethodIconId } from '../../utils/getPaymentMethodIconId'
import getSymbolFromCurrency from 'currency-symbol-map'
import { format } from 'date-fns'
import { Statuses } from '../../types/Status'
import CopyIcon from '../../images/CopyIcon'
import { useCopy } from '../../hooks/useCopy'

interface PaymentMainTableProps {
	payment?: PaymentDetailed
}

const PaymentMainTable: FC<PaymentMainTableProps> = ({ payment }) => {
	const directCryptoCharge = payment?.directCryptoCharge
	const copyToClipboard = useCopy()

	return (
		<div className={styles.paymentDetails}>
			<div className={styles.paymentDetailsItemsWrapper}>
				<div className={styles.paymentDetailsItem}>
					<span>Last update</span>
					<p>
						{payment?.timeline[payment?.timeline.length - 1].time &&
							format(new Date(payment?.timeline[0].time), 'MMM dd, yyyy, hh:mm aa')}
					</p>
				</div>
				<div className={styles.paymentDetailsItem}>
					<span>Customer</span>
					<p>{payment?.customer?.email || '-'}</p>
				</div>

				<div className={styles.paymentDetailsItem}>
					<span>Currency</span>
					<p>{payment?.currency || 'USD'}</p>
				</div>
				<div className={styles.paymentDetailsItem}>
					<div className={`${styles.checkoutLinkWrapper}`}>
						<div className={styles.checkoutLink}>
							<span>Checkout link</span>
							<a href={`https://checkout.hoodpay.io/${payment?.id}`} target={'_blank'}>
								https://checkout.hoodpay.io/{payment?.id}
							</a>
						</div>
						<CopyIcon
							onClick={() => copyToClipboard(`https://checkout.hoodpay.io/${payment?.id}`)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PaymentMainTable
