import React from 'react'

const Checked2Svg = () => {
	return (
		<svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.37537 5.52734L3.62537 8.02734L9.87537 1.52734'
				stroke='#578643'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default Checked2Svg
