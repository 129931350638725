import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { AppContextType } from '../types/AppContextType'
import Modal from '../components/Modals/Modal'
import { closeModal } from '../utils/CloseModal'
import { ActionMessage } from '../types/ActionMessage'
import { useAPI } from '../hooks/useAPI'
import { UserService } from '../API/UserService'
import { User } from '../types/User'
import { useLocation, useNavigate } from 'react-router-dom'
import { Business } from '../types/Business'
import Spinner from '../components/UI/Spinner/Spinner'
import InitialLoader from '../components/InitialLoader/InitialLoader'

export const AppContext = createContext<AppContextType | null>(null)

const AppContextProvider: FC<{
	children: ReactNode
}> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [actionMessage, setActionMessage] = useState<ActionMessage>({
		message: 'Unhandled error',
		title: 'Error',
		status: false,
		isActive: false,
	})
	const [user, setUser] = useState<User>()
	const [business, setBusiness] = useState<Business>()
	const navigate = useNavigate()
	const location = useLocation()

	const getUser = async () => {
		setIsLoading(true)
		const response = await UserService.get()
			.catch(error => {
				setActionMessage({
					status: false,
					isActive: true,
					message: error.response?.data.errors[0] || 'Unhandled error',
					title: error.response?.data.message || 'error',
				})

				if (error.response.status === 401) {
					navigate('/auth/login')
					localStorage.removeItem('token')
					localStorage.removeItem('businessId')
				}
			})
			.finally(() => {
				setIsLoading(false)
			})

		if (response) {
			setUser(response.data.data)
		}
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			getUser()
		} else if (!location.pathname.includes('auth')) {
			navigate('/auth/login')
			localStorage.removeItem('businessId')
		}
	}, [])

	useEffect(() => {
		// @ts-ignore
		window.Intercom('update')

		if (user && user.businesses.length === 0) {
			navigate('/auth/new-business')
		}
	}, [location])

	useEffect(() => {
		if (user) {
			const businessId = localStorage.getItem('businessId')
			let businessItem: Business | undefined

			if (user.businesses.length === 0) {
				navigate('/auth/new-business')
			}
			if (businessId) {
				businessItem = user.businesses.find(business => business.id === Number(businessId))
			} else if (user.businesses.length) {
				businessItem = user.businesses[0]
				localStorage.setItem('businessId', user.businesses[0].id.toString())
			}

			setBusiness(businessItem)

			// @ts-ignore
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'oh1pwt9c',
				businessId: businessItem?.id,
				name: businessItem?.name,
				userId: user?.id,
				email: user?.email,
			})
		}
	}, [user])

	if (isLoading) return <Spinner />

	return (
		<AppContext.Provider
			value={{
				setActionMessage,
				actionMessage,
				user,
				setUser,
				business,
				setBusiness,
				getUser,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export default AppContextProvider
