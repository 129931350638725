import { privateAPI } from './API'

export const UserSecurityService = {
	googleTFAStart: async () => {
		return await privateAPI.post(
			`/v1/dash/user/settings/security/tfa/providers/google/challenge/start`
		)
	},
	googleTFAComplete: async (key: string | undefined, code: string) => {
		return await privateAPI.post(
			`/v1/dash/user/settings/security/tfa/providers/google/challenge/complete`,
			{
				key,
				code,
			}
		)
	},
	googleTFADisable: async (code: string) => {
		return await privateAPI.post(`/v1/dash/user/settings/security/tfa/providers/google/disable`, {
			code,
		})
	},
}
