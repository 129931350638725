import React from 'react'

const PersonSvg = () => {
	return (
		<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.78568 8.22656C9.5806 8.22656 11.0357 6.77149 11.0357 4.97656C11.0357 3.18164 9.5806 1.72656 7.78568 1.72656C5.99075 1.72656 4.53568 3.18164 4.53568 4.97656C4.53568 6.77149 5.99075 8.22656 7.78568 8.22656Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2.63318 16.2266H12.9382C14.0801 16.2266 14.9597 15.2447 14.4265 14.235C13.642 12.7497 11.8537 10.9766 7.78568 10.9766C3.71769 10.9766 1.92935 12.7497 1.14492 14.235C0.611646 15.2447 1.49126 16.2266 2.63318 16.2266Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PersonSvg
