import React from 'react'

const CodeBracketsSvg = () => {
	return (
		<svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.9852 4.50195L15.4852 7.75195L11.9852 11.002M4.48517 4.50195L0.985168 7.75195L4.48517 11.002M9.48517 1.50195L6.98517 14.002'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CodeBracketsSvg
