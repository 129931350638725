import React, { useEffect } from 'react'
import styles from './Settings.module.scss'
import Container from '../../components/UI/Container/Container'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import SettingsCompanyInfoPage from './SettingsCompanyInfoPage/SettingsCompanyInfoPage'
import SettingsCheckoutPage from './SettingsCheckoutPage/SettingsCheckoutPage'
import SettingsAccountPage from './SettingsAccountPage/SettingsAccountPage'
import SettingsDeveloperPage from './SettingsDeveloperPage/SettingsDeveloperPage'
import SettingsLegalPage from './SettingsLegalPage/SettingsLegalPage'
import SettingsTeamPage from './SettingsTeamPage/SettingsTeamPage'
import SettingsBillingPage from './SettingsBillingPage/SettingsBillingPage'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { useAPI } from '../../hooks/useAPI'
import { Settings } from '../../types/Settings'
import { BusinessSettingsService } from '../../API/BusinessSettingsService'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import SettingsCryptocurrencyPage from './SettingsCryptocurrencyPage/SettingsCryptocurrencyPage'
import MarketSVG from '../../images/MarketSVG'
import BrushSVG from '../../images/BrushSVG'
import BanknoteSVG from '../../images/BanknoteSVG'
import CryptocurrencySVG from '../../images/CryptocurrencySVG'
import DevicesSVG from '../../images/DevicesSVG'
import TaxIcon from '../../images/TaxIcon'
import PersonSvg from '../../images/PersonSVG'
import CodeBracketsSvg from '../../images/CodeBracketsSVG'
import CoinsSVG from '../../images/CoinsSVG'
import PageNavigation from '../../components/PageNavigation/PageNavigation'
import SettingsPaymentsPage from './SettingsPaymentsPage/SettingsPaymentsPage'
import SettingsDevicesPage from './SettingsDevicesPage/SettingsDevicesPage'
import SettingsTaxPage from './SettingsTaxPage/SettingsTaxPage'
import Banknote2SVG from '../../images/Banknote2SVG'

const SettingsPage = () => {
	const { business } = useAppContext()
	const navigate = useNavigate()
	const location = useLocation()
	const [isLoading, settings, getSettings] = useAPI<Settings>(
		(businessId: number) => BusinessSettingsService.get(businessId),
		false
	)

	const links = [
		{
			path: '/settings/general',
			title: 'General',
			icon: <MarketSVG />,
		},
		{
			path: '/settings/checkout',
			title: 'Checkout',
			icon: <BrushSVG />,
		},
		{
			path: '/settings/payments',
			title: 'Payments',
			icon: <Banknote2SVG />,
		},
		{
			path: '/settings/cryptocurrency',
			title: 'Cryptocurrency',
			icon: <CryptocurrencySVG />,
		},
		// {
		// 	path: '/settings/devices',
		// 	title: 'Devices',
		// 	icon: <DevicesSVG />,
		// },
		{
			path: '/settings/tax',
			title: 'Tax',
			icon: <TaxIcon />,
		},
		{
			path: '/settings/account',
			title: 'Account',
			icon: <PersonSvg />,
		},
		{
			path: '/settings/developer',
			title: 'Developer',
			icon: <CodeBracketsSvg />,
		},
		{
			path: '/settings/billing',
			title: 'Billing',
			icon: <CoinsSVG />,
		},
	]

	useEffect(() => {
		if (business?.id && !isLoading) {
			getSettings(business.id)
		}
	}, [business])

	if (isLoading) {
		return <Spinner />
	}

	return (
		<Container>
			<PageWrapper>
				<div className={styles.settingsPage}>
					<h1>Settings</h1>
					<div className={styles.settingsPageInner}>
						<PageNavigation links={links} />
						<div className={styles.settingsPageTabPage}>
							<Routes>
								<Route
									path={'general'}
									element={
										<SettingsCompanyInfoPage settings={settings} getSettings={getSettings} />
									}
								/>
								<Route
									path={'legal'}
									element={<SettingsLegalPage settings={settings} getSettings={getSettings} />}
								/>
								<Route path={'account'} element={<SettingsAccountPage />} />
								<Route path={'team'} element={<SettingsTeamPage />} />
								<Route
									path={'tax'}
									element={<SettingsTaxPage settings={settings} getSettings={getSettings} />}
								/>
								<Route
									path={'checkout'}
									element={<SettingsCheckoutPage settings={settings} getSettings={getSettings} />}
								/>
								<Route
									path={'devices'}
									element={<SettingsDevicesPage settings={settings} getSettings={getSettings} />}
								/>
								<Route
									path={'payments'}
									element={<SettingsPaymentsPage settings={settings} getSettings={getSettings} />}
								/>
								<Route path={'billing'} element={<SettingsBillingPage />} />
								<Route
									path={'cryptocurrency'}
									element={
										<SettingsCryptocurrencyPage settings={settings} getSettings={getSettings} />
									}
								/>
								<Route path={'developer'} element={<SettingsDeveloperPage />} />
							</Routes>
						</div>
					</div>
				</div>
			</PageWrapper>
		</Container>
	)
}

export default SettingsPage
