import React from 'react'

const RefreshIcon = () => {
	return (
		<svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.92243 12.8972C5.50551 15.4802 9.69348 15.4802 12.2766 12.8972C14.8596 10.3141 14.8596 6.12611 12.2766 3.54304C9.69348 0.959965 5.50551 0.959965 2.92243 3.54304C2.3442 4.12127 1.89542 4.77988 1.57602 5.48301M0.985103 1.60571L0.985103 4.79886C0.985103 5.30274 1.39355 5.71119 1.89743 5.71119L5.09058 5.71119'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default RefreshIcon
