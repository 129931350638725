import React, { FC, useEffect, useState } from 'react'
import styles from './PaymentMethodModals.module.scss'
import hoodpayLogo from '../../../images/HoodpayLogo.svg'
import bitcoinLogo from '../../../images/BitcoinSvg.svg'
import litecoinLogo from '../../../images/LitecoinSvg.svg'
import WarningLink from '../../UI/WarningLink/WarningLink'
import Radio from '../../UI/Radio/Radio'
import { ScriptPubKeyType } from '../../../types/ScriptPubKeyType'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { BusinessSettingsPayments } from '../../../API/BusinessSettingsPayments'
import { useAppContext } from '../../../hooks/useAppContext'
import { Cryptocurrencies } from '../../../types/Cryptocurrencies'
import { Settings } from '../../../types/Settings'
import * as crypto from 'crypto'

interface CardToCryptoModalProps {
	settings?: Settings
	getSettings: (businessId: number | undefined) => void
}

const CardToCryptoModal: FC<CardToCryptoModalProps> = ({ settings, getSettings }) => {
	const [bitcoinKeyType, setBitcoinKeyType] = useState(ScriptPubKeyType.SegwitP2SH as string)
	const [litecoinKeyType, setLitecoinKeyType] = useState(ScriptPubKeyType.SegwitP2SH as string)
	const [bitcoinKey, setBitcoinKey] = useState('')
	const [litecoinKey, setLitecoinKey] = useState('')
	const { business } = useAppContext()

	const [isUpdateOnRampCryptoLoading, , updateOnRampCrypto] = useAPI(
		cryptos => BusinessSettingsPayments.updateOnRampCrypto(business?.id, cryptos),
		false,
		'Card to Crypto updated successfully',
		{
			onSuccess: () => getSettings(business?.id),
		}
	)

	const onSaveHandler = () => {
		const cryptos = [
			{
				cryptoCoinName: Cryptocurrencies.BITCOIN,
				xPub: bitcoinKey,
				scriptPubKeyType: bitcoinKeyType as ScriptPubKeyType,
			},
			{
				cryptoCoinName: Cryptocurrencies.LITECOIN,
				xPub: litecoinKey,
				scriptPubKeyType: litecoinKeyType as ScriptPubKeyType,
			},
		]

		updateOnRampCrypto(cryptos)
	}

	useEffect(() => {
		if (settings) {
			const litecoinSetting = settings.onRampCryptoSettings?.cryptos.find(
				crypto => crypto.cryptoCoin === Cryptocurrencies.LITECOIN
			)
			const bitcoinSetting = settings.onRampCryptoSettings?.cryptos.find(
				crypto => crypto.cryptoCoin === Cryptocurrencies.BITCOIN
			)
			if (litecoinSetting) {
				setLitecoinKeyType(litecoinSetting.scriptPubKeyType)
				setLitecoinKey(litecoinSetting.xPub)
			}
			if (bitcoinSetting) {
				setBitcoinKeyType(bitcoinSetting.scriptPubKeyType)
				setBitcoinKey(bitcoinSetting.xPub)
			}
		}
	}, [settings])

	return (
		<div className={styles.paymentMethodModal}>
			<header className={styles.header}>
				<img src={hoodpayLogo} alt={'hoodpay'} />
				<h1 className={styles.h1}>Card To Crypto</h1>
			</header>
			<p className={styles.description}>
				To receive crypto payouts, please connect your Xpub key below.
			</p>
			<p className={styles.description}>
				When using an Xpub key, you will provide us with your key and we will generate multiple
				addresses for customers.
			</p>
			<WarningLink
				href={'https://support.hoodpay.io/en/collections/4027601-payment-integrations'}
				text={'View detailed help article for setup'}
			/>
			<div className={styles.fieldWrapper}>
				<h2>
					<img src={bitcoinLogo} alt='bitcoin' />
					BITCOIN EXTENDED PUBLIC KEY
				</h2>
				<Input
					value={bitcoinKey}
					setValue={setBitcoinKey}
					placeholder={'xpub6-*********************'}
				/>
			</div>

			<div className={styles.radios}>
				<label className={styles.radioWrapper}>
					<Radio
						id={ScriptPubKeyType.SegwitP2SH}
						value={ScriptPubKeyType.SegwitP2SH}
						setValue={setBitcoinKeyType}
						checked={bitcoinKeyType === ScriptPubKeyType.SegwitP2SH}
					/>
					<span>Segwit</span>
				</label>
				<label className={styles.radioWrapper}>
					<Radio
						id={ScriptPubKeyType.Segwit}
						value={ScriptPubKeyType.Segwit}
						setValue={setBitcoinKeyType}
						checked={bitcoinKeyType === ScriptPubKeyType.Segwit}
					/>
					<span>Native Segwit</span>
				</label>
				<label className={styles.radioWrapper}>
					<Radio
						id={ScriptPubKeyType.Legacy}
						value={ScriptPubKeyType.Legacy}
						setValue={setBitcoinKeyType}
						checked={bitcoinKeyType === ScriptPubKeyType.Legacy}
					/>
					<span>Legacy</span>
				</label>
			</div>

			<div className={styles.fieldWrapper}>
				<h2>
					<img src={litecoinLogo} alt='litecoin' />
					LITECOIN EXTENDED PUBLIC KEY
				</h2>
				<Input
					value={litecoinKey}
					setValue={setLitecoinKey}
					placeholder={'xpub6-*********************'}
				/>
			</div>

			<div className={styles.radios}>
				<label className={styles.radioWrapper}>
					<Radio
						id={ScriptPubKeyType.SegwitP2SH + 'litecoin'}
						value={ScriptPubKeyType.SegwitP2SH}
						setValue={setLitecoinKeyType}
						checked={litecoinKeyType === ScriptPubKeyType.SegwitP2SH}
					/>
					<span>Segwit</span>
				</label>
				<label className={styles.radioWrapper}>
					<Radio
						id={ScriptPubKeyType.Segwit + 'litecoin'}
						value={ScriptPubKeyType.Segwit}
						setValue={setLitecoinKeyType}
						checked={litecoinKeyType === ScriptPubKeyType.Segwit}
					/>
					<span>Native Segwit</span>
				</label>
				<label className={styles.radioWrapper}>
					<Radio
						id={ScriptPubKeyType.Legacy + 'litecoin'}
						value={ScriptPubKeyType.Legacy}
						setValue={setLitecoinKeyType}
						checked={litecoinKeyType === ScriptPubKeyType.Legacy}
					/>
					<span>Legacy</span>
				</label>
			</div>

			<footer className={styles.footer}>
				<Button
					style={ButtonStyles.SECONDARY}
					onClick={onSaveHandler}
					isLoading={isUpdateOnRampCryptoLoading}
				>
					Save
				</Button>
			</footer>
		</div>
	)
}

export default CardToCryptoModal
