import React from 'react'

const DollarSvg = () => {
	return (
		<svg width='13' height='17' viewBox='0 0 13 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.4851 3.15283H3.61011C2.16036 3.15283 0.985107 4.32808 0.985107 5.77783C0.985107 7.22753 2.16036 8.40283 3.61011 8.40283H8.86011C10.3098 8.40283 11.4851 9.57813 11.4851 11.0278C11.4851 12.4775 10.3098 13.6528 8.86011 13.6528H0.985107M5.98511 15.6528V1.15283'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default DollarSvg
