import React, { FC, SVGProps } from 'react'

const ArrowSvg: FC<SVGProps<any>> = props => {
	return (
		<svg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.3502 13.5314L12.7931 11.9809L16.8807 7.90002H0V5.63136H16.8807L12.7931 1.54376L14.3502 0L21.1159 6.76569L14.3502 13.5314Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default ArrowSvg
