import React, { useEffect, useState } from 'react'
import styles from './SettingsAccountPage.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { useAppContext } from '../../../hooks/useAppContext'
import { useAPI } from '../../../hooks/useAPI'
import { UserService } from '../../../API/UserService'
import { UserSettingsService } from '../../../API/UserSettingsService'
import { useNavigate } from 'react-router-dom'
import SideModal from '../../../components/SideModals/SideModal'
import { closeModal } from '../../../utils/CloseModal'
import GoogleTFASideModal from '../../../components/Modals/GoogleTFAModal'
import settingsStyles from '../Settings.module.scss'
import OpenLinkSVG from '../../../images/OpenLinkSVG'
import DeactivateAccountModal from '../../../components/Modals/DeactivateAccountModal'
import Modal from '../../../components/Modals/Modal'

const SettingsAccountPage = () => {
	const { user, getUser } = useAppContext()
	const [email, setEmail] = useState('')
	const [isDeactivateAccountModalActive, setIsDeactivateAccountModalActive] = useState(false)
	const [isDeactivateAccountModalVisible, setIsDeactivateAccountModalVisible] = useState(false)
	const [isGoogleTFASideModalActive, setIsGoogleTFASideModalActive] = useState(false)
	const [isGoogleTFASideModalVisible, setIsGoogleTFASideModalVisible] = useState(false)
	const navigate = useNavigate()

	const [isConnectTelegramLoading, , connectTelegram] = useAPI(
		() => UserSettingsService.connectTelegram(),
		false,
		undefined,
		{
			onSuccess: response => {
				window.open(response.data.data, '_blank', 'noopener')
			},
		}
	)
	const [isUpdateEmailLoading, , updateEmail] = useAPI(
		() => UserSettingsService.updateEmail(email),
		false,
		'Email changed successfully',
		{
			onSuccess: () => {
				getUser()
			},
		}
	)

	useEffect(() => {
		if (user?.email) {
			setEmail(user?.email)
		}
	}, [user])

	return (
		<div className={styles.settingsAccount}>
			{isDeactivateAccountModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsDeactivateAccountModalActive, setIsDeactivateAccountModalVisible)
					}
					visible={isDeactivateAccountModalVisible}
					setVisible={setIsDeactivateAccountModalVisible}
				>
					<DeactivateAccountModal
						closeModal={() =>
							closeModal(setIsDeactivateAccountModalActive, setIsDeactivateAccountModalVisible)
						}
					/>
				</Modal>
			)}
			{isGoogleTFASideModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsGoogleTFASideModalActive, setIsGoogleTFASideModalVisible)
					}
					visible={isGoogleTFASideModalVisible}
					setVisible={setIsGoogleTFASideModalVisible}
				>
					<GoogleTFASideModal
						closeModal={() =>
							closeModal(setIsGoogleTFASideModalActive, setIsGoogleTFASideModalVisible)
						}
						isEnabled={!!user?.isTfaEnabled}
						getUser={getUser}
					/>
				</Modal>
			)}
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Account details</h1>
						<p>
							Change your account email, setup notifications, and set additional methods of
							security.
						</p>
					</div>
					<Button onClick={updateEmail} isLoading={isUpdateEmailLoading}>
						Save
					</Button>
				</header>
				<div className={settingsStyles.fieldWrapper}>
					<h2>EMAIL</h2>
					<Input value={email} setValue={setEmail} placeholder={'your@email.com'} />
					<span className={styles.helperText}>
						This is the email address you use to sign in to Hoodpay and the email we use to contact
						you. We do not recommend changing this.
					</span>
				</div>
				<div className={settingsStyles.fieldWrapper}>
					<header>
						<div>
							<h2>TELEGRAM NOTIFICATIONS</h2>
							<p className={settingsStyles.p}>
								Start receiving notifications right to your Telegram inbox and set custom
								notification rules.
							</p>
						</div>
						<Button
							onClick={connectTelegram}
							isLoading={isConnectTelegramLoading}
							icon={<OpenLinkSVG />}
						>
							Connect Telegram
						</Button>
					</header>
				</div>
			</section>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Account security</h1>
						<p>
							Some settings may require an additional form of authentication, such as a 2FA code via
							an authenticator.
						</p>
					</div>
				</header>
				<div className={settingsStyles.fieldWrapper}>
					<header>
						<div>
							<h2>MULTI-FACTOR AUTHENTICATION</h2>
							<p className={settingsStyles.p}>
								Secure your account with time-based 6-digit codes through Google Authenticator.
							</p>
						</div>
						<Button onClick={() => setIsGoogleTFASideModalActive(true)}>
							{user?.isTfaEnabled ? 'Remove' : 'Setup'} 2FA
						</Button>
					</header>
				</div>
				<div className={settingsStyles.fieldWrapper}>
					<header>
						<div>
							<h2>RESET PASSWORD</h2>
							<p className={settingsStyles.p}>
								We recommend rotating and changing your password once every few months.
							</p>
						</div>
						<Button onClick={() => navigate('/auth/reset')} icon={<OpenLinkSVG />}>
							Reset password
						</Button>
					</header>
				</div>
				<div className={settingsStyles.fieldWrapper}>
					<header>
						<div>
							<h2>DEACTIVATE ACCOUNT</h2>
							<p className={settingsStyles.p}>
								Once you deactivate your account, you won’t be able to continue accepting payments.
							</p>
						</div>
						<Button
							onClick={() => setIsDeactivateAccountModalActive(true)}
							style={ButtonStyles.DANGER}
						>
							Deactivate account
						</Button>
					</header>
				</div>
			</section>
		</div>
	)
}

export default SettingsAccountPage
