import React from 'react'

const WalletSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.985107 3.37598V13.876C0.985107 14.9806 1.88054 15.876 2.98511 15.876H13.4851C14.5897 15.876 15.4851 14.9806 15.4851 13.876V4.87598H13.4851M13.4851 4.87598H2.73511C1.76861 4.87598 0.985107 4.09248 0.985107 3.12598C0.985107 2.15948 1.76861 1.37598 2.73511 1.37598H11.4851C12.5897 1.37598 13.4851 2.27141 13.4851 3.37598V4.87598ZM12.7351 9.62598C12.7351 9.90208 12.5112 10.126 12.2351 10.126C11.959 10.126 11.7351 9.90208 11.7351 9.62598C11.7351 9.34988 11.959 9.12598 12.2351 9.12598C12.5112 9.12598 12.7351 9.34988 12.7351 9.62598Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default WalletSvg
