import React from 'react'

const MarketSvg = () => {
	return (
		<svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.0603 3.75C1.0603 4.99264 1.8103 6.25 3.3103 6.25C4.8103 6.25 5.8103 4.99264 5.8103 3.75C5.8103 4.99264 6.8103 6.25 8.3103 6.25C9.8103 6.25 10.8103 4.99264 10.8103 3.75C10.8103 4.99264 11.8103 6.25 13.3103 6.25C14.8103 6.25 15.5603 4.99264 15.5603 3.75M3.06054 15.2502H13.5605C14.6651 15.2502 15.5605 14.3548 15.5605 13.2502V4.18322C15.5605 3.89772 15.4994 3.61553 15.3813 3.35561L14.8435 2.17239C14.5189 1.45841 13.807 1 13.0227 1H3.59837C2.81408 1 2.10218 1.45841 1.77764 2.1724L1.23981 3.35561C1.12167 3.61553 1.06054 3.89772 1.06054 4.18322V13.2502C1.06054 14.3548 1.95598 15.2502 3.06054 15.2502ZM6.05993 11.7493C6.05993 10.6447 6.9554 9.7493 8.0599 9.7493H8.5599C9.6645 9.7493 10.5599 10.6447 10.5599 11.7493V15.2493H6.05993V11.7493Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default MarketSvg
