import React, { FC, useState } from 'react'
import styles from './SettingsTaxPage.module.scss'
import { SettingsPageProps } from '../../../types/SettingsPageProps'
import ClockSVG from '../../../images/ClockSVG'

const SettingsTaxPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	return (
		<div className={styles.settingsTaxPage}>
			<div className={styles.comingSoon}>
				<ClockSVG />
				<h2>Tax documents coming soon</h2>
				<p>
					Hoodpay will automatically charge and remit sales tax around the world on your behalf. Use
					this area to access reverse invoices we generated to send you payouts. This will help you
					file any personal or business income taxes.
				</p>
			</div>
		</div>
	)
}

export default SettingsTaxPage
