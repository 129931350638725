import { Cryptocurrencies, Cryptocurrency } from '../types/Cryptocurrencies'
import { PaymentMethods } from '../types/PaymentMethods'
import applePayLogo from '../images/ApplePayLogo.svg'
import googlePayLogo from '../images/GooglePayLogo.svg'
import cashAppLogo from '../images/CashAppLogo.svg'
import cardLogo from '../images/Card.svg'
import wireLogo from '../images/WireLogo.svg'

export const getPaymentMethodName = (paymentMethod?: Cryptocurrency | PaymentMethods) => {
	switch (paymentMethod) {
		case Cryptocurrencies.BITCOIN:
			return 'Bitcoin'
		case Cryptocurrencies.BITCOIN_CASH:
			return 'Bitcoin Cash'
		case Cryptocurrencies.LITECOIN:
			return 'Litecoin'
		case Cryptocurrencies.ETH_BUSD:
			return 'Binance USD'
		case Cryptocurrencies.ETH_DAI:
			return 'Dai'
		case Cryptocurrencies.ETH_CRONOS:
			return 'Cronos'
		case Cryptocurrencies.MONERO:
			return 'Monero'
		case Cryptocurrencies.ETHEREUM:
			return 'Ethereum'
		case Cryptocurrencies.ETH_TETHER:
			return 'Tether'
		case Cryptocurrencies.ETH_BNB:
			return 'Binance coin'
		case Cryptocurrencies.ETH_USD_COIN:
			return 'USD Coin'
		case Cryptocurrencies.ETH_MATIC:
			return 'Polygon'
		case Cryptocurrencies.ETH_UNISWAP:
			return 'Uniswap'
		case Cryptocurrencies.ETH_APE_COIN:
			return 'Ape Coin'
		case Cryptocurrencies.ETH_SHIBA_INU:
			return 'Shiba Inu'
		case Cryptocurrencies.TRON:
			return 'Tron'
		case Cryptocurrencies.TRX_TETHER:
			return 'Tether'
		case Cryptocurrencies.TRX_USD_C:
			return 'USD Coin'
		case PaymentMethods.CASHAPP:
			return 'Cash App'
		case PaymentMethods.CRYPTO:
			return 'Crypto'
		case PaymentMethods.BANK_CARD:
			return 'Bank Card'
		case PaymentMethods.PAYPAL:
			return 'PayPal'
		case PaymentMethods.GOOGLE_PAY:
			return 'Google Pay'
		case PaymentMethods.APPLE_PAY:
			return 'Apple Pay'
		case PaymentMethods.WIRE:
			return 'Wire Transfer'
		case PaymentMethods.VENMO:
			return 'Venmo'
	}
}
