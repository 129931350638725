import React from 'react'

const SearchIcon = () => {
	return (
		<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.8719 15.5394L11.277 11.9445M0.97168 7.63062C0.97168 4.32163 3.65415 1.63916 6.96314 1.63916C10.2722 1.63916 12.9546 4.32163 12.9546 7.63062C12.9546 10.9396 10.2722 13.6221 6.96314 13.6221C3.65415 13.6221 0.97168 10.9396 0.97168 7.63062Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default SearchIcon
