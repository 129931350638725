import React from 'react'

const CoinsSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M4.98511 6.16211V3.41211M4.98511 3.41211C4.98511 4.37861 7.33561 5.16211 10.2351 5.16211C13.1346 5.16211 15.4851 4.37861 15.4851 3.41211M4.98511 3.41211C4.98511 2.44561 7.33561 1.66211 10.2351 1.66211C13.1346 1.66211 15.4851 2.44561 15.4851 3.41211M15.4851 3.41211V7.41211C15.4851 7.84451 15.0147 8.24021 14.2351 8.54561M11.4851 10.4121V14.4121C11.4851 15.3786 9.13461 16.1621 6.23511 16.1621C3.33562 16.1621 0.985107 15.3786 0.985107 14.4121V10.4121M11.4851 10.4121C11.4851 11.3786 9.13461 12.1621 6.23511 12.1621C3.33562 12.1621 0.985107 11.3786 0.985107 10.4121M11.4851 10.4121C11.4851 9.44561 9.13461 8.66211 6.23511 8.66211C3.33562 8.66211 0.985107 9.44561 0.985107 10.4121'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CoinsSvg
