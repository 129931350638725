import React, { FC, ReactNode, useEffect, useRef } from 'react'

interface PageWrapperProps {
	children: ReactNode | ReactNode[]
}

const PageWrapper: FC<PageWrapperProps> = ({ children }) => {
	const pageRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setTimeout(() => {
			pageRef.current?.classList.add('visible')
		}, 10)

		setTimeout(() => {
			if (pageRef.current) pageRef.current.style.all = 'unset'
		}, 510)

		return () => {
			pageRef.current?.classList.remove('visible')
		}
	}, [])

	return (
		<div className={'page'} ref={pageRef}>
			{children}
		</div>
	)
}

export default PageWrapper
