import React, { FC, useState } from 'react'
import styles from './SettingsPaymentsPage.module.scss'
import { useAppContext } from '../../../hooks/useAppContext'
import { SettingsPageProps } from '../../../types/SettingsPageProps'
import settingsStyles from '../Settings.module.scss'
import CardsPayment from '../../../components/CardsPayment/CardsPayment'
import SettingsPayment, {
	SettingsPaymentMethods,
} from '../../../components/SettingsPayment/SettingsPayment'
import SideModal from '../../../components/SideModals/SideModal'
import { closeModal } from '../../../utils/CloseModal'
import Modal from '../../../components/Modals/Modal'
import CardToCryptoModal from '../../../components/Modals/PaymentMethodSideModals/CardToCryptoModal'

const SettingsPaymentsPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	const { business } = useAppContext()
	const [isPaypalModalActive, setIsPaypalModalActive] = useState(false)
	const [isPaypalModalVisible, setIsPaypalModalVisible] = useState(false)
	const [isCashAppModalVisible, setIsCashAppModalVisible] = useState(false)
	const [isCashAppModalActive, setIsCashAppModalActive] = useState(false)
	const [isCardToCryptoModalActive, setIsCardToCryptoModalActive] = useState(false)
	const [isCardToCryptoModalVisible, setIsCardToCryptoModalVisible] = useState(false)

	return (
		<div className={styles.settingsPaymentsPage}>
			{isCardToCryptoModalActive && (
				<Modal
					closeModal={() => closeModal(setIsCardToCryptoModalActive, setIsCardToCryptoModalVisible)}
					visible={isCardToCryptoModalVisible}
					setVisible={setIsCardToCryptoModalVisible}
				>
					<CardToCryptoModal settings={settings} getSettings={getSettings} />
				</Modal>
			)}
			{/*{isPaypalModalActive && (*/}
			{/*	<SideModal*/}
			{/*		closeModal={() => closeModal(setIsPaypalModalActive, setIsPaypalModalVisible)}*/}
			{/*		visible={isPaypalModalVisible}*/}
			{/*		setVisible={setIsPaypalModalVisible}*/}
			{/*	>*/}
			{/*		<PaypalSideModal />*/}
			{/*	</SideModal>*/}
			{/*)}*/}
			{/*{isCashAppModalActive && (*/}
			{/*	<SideModal*/}
			{/*		closeModal={() => closeModal(setIsCashAppModalActive, setIsCashAppModalVisible)}*/}
			{/*		visible={isCashAppModalVisible}*/}
			{/*		setVisible={setIsCashAppModalVisible}*/}
			{/*	>*/}
			{/*		<CashappSideModal />*/}
			{/*	</SideModal>*/}
			{/*)}*/}
			{/*{isVenmoModalActive && (*/}
			{/*	<SideModal*/}
			{/*		closeModal={() => closeModal(setIsVenmoModalActive, setIsVenmoModalVisible)}*/}
			{/*		visible={isVenmoModalVisible}*/}
			{/*		setVisible={setIsVenmoModalVisible}*/}
			{/*	>*/}
			{/*		<VenmoSideModal />*/}
			{/*	</SideModal>*/}
			{/*)}*/}

			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Debit/credit cards</h1>
						<p>
							With Hoodpay, businesses can accept payments in debit/credit cards from customers and
							get paid out in cryptocurrency. To get started, simply choose the cryptocurrency below
							for payouts and we’ll take care of the rest.
						</p>
						<br />
						<p>
							Our system will automatically route payments to third-party processors. To begin
							accepting card payments, you’re required to set up both Bitcoin and Litecoin as a
							payout option below.
						</p>
					</div>
				</header>
				<CardsPayment
					setIsCardToCryptoModalActive={setIsCardToCryptoModalActive}
					settings={settings}
				/>
			</section>
			{/*<section className={settingsStyles.section}>*/}
			{/*	<header className={settingsStyles.sectionHeader}>*/}
			{/*		<div>*/}
			{/*			<h1>Wallets</h1>*/}
			{/*			<p>*/}
			{/*				To begin receiving digital wallet payments, please setup the payment methods you want*/}
			{/*				to accept during checkout.*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*	</header>*/}

			{/*	<div className={styles.walletsTabs}>*/}
			{/*		<div className={`${styles.tab}`}>Managed payments</div>*/}
			{/*		<div className={`${styles.tab}`}>Direct payments (2.5% fee)</div>*/}
			{/*	</div>*/}

			{/*	<p className={styles.secondaryText}>*/}
			{/*		When using managed payments, you will not be required to setup or connect your own*/}
			{/*		personal wallets. We will automatically send you the available balance as a payout to your*/}
			{/*		bank or crypto wallet.*/}
			{/*	</p>*/}

			{/*	<div className={styles.payments}>*/}
			{/*		<SettingsPayment*/}
			{/*			paymentMethod={SettingsPaymentMethods.paypal}*/}
			{/*			onClick={() => setIsPaypalModalActive(true)}*/}
			{/*		/>*/}
			{/*		<SettingsPayment*/}
			{/*			paymentMethod={SettingsPaymentMethods.cashApp}*/}
			{/*			onClick={() => setIsCashAppModalActive(true)}*/}
			{/*		/>*/}
			{/*		<SettingsPayment*/}
			{/*			paymentMethod={SettingsPaymentMethods.venmo}*/}
			{/*			onClick={() => setIsVenmoModalActive(true)}*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*</section>*/}
		</div>
	)
}

export default SettingsPaymentsPage
