import React, { FC, useEffect, useState } from 'react'
import styles from './Modals.module.scss'
import CloseButton from '../UI/CloseButton/CloseButton'
import Button, { ButtonStyles } from '../UI/Button/Button'
import Input from '../UI/FormComponents/Input'
import { useAPI } from '../../hooks/useAPI'
import { UserSettingsDeveloperService } from '../../API/UserSettingsDeveloperService'
import { ApiKey } from '../../types/ApiKey'
import Select from '../UI/FormComponents/Select'
import { Calendar } from 'react-date-range'
import { da } from 'date-fns/locale'
import DatePicker from '../UI/DatePicker/DatePicker'

interface GenerateAPIKeySideModalProps {
	close: () => void
	getApiKeys: () => void
}

const GenerateAPIKeyModal: FC<GenerateAPIKeySideModalProps> = ({ close, getApiKeys }) => {
	const [name, setName] = useState('')
	const [ticksInputValue, setTicksInputValue] = useState('7')
	const [customTicksDate, setCustomTicksDate] = useState(new Date())

	const calculateTicks = () => {
		const today = new Date()
		const pickedDate =
			ticksInputValue !== 'custom'
				? new Date(new Date().setDate(today.getDate() + +ticksInputValue))
				: customTicksDate

		return (pickedDate.getTime() - today.getTime()) * 10000
	}

	const [isAddApiKeyLoading, , addApiKey] = useAPI(
		() =>
			UserSettingsDeveloperService.postApiKeys({
				name: name,
				expiresInTicks: ticksInputValue === 'null' ? null : calculateTicks(),
			}),
		false,
		'API Key successfully generated',
		{
			onSuccess: () => {
				getApiKeys()
				close()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<h1 className={styles.h1}>Generate API Key</h1>

			<div className={styles.fieldWrapper}>
				<h3>API Key name</h3>
				<Input value={name} setValue={setName} placeholder={'Name'} />
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Expiration Date</h3>
				<div className={styles.ticksFieldsWrapper}>
					<div className={styles.selectWrapper}>
						<Select value={ticksInputValue} setValue={setTicksInputValue}>
							<option value='7'>7 days</option>
							<option value='30'>30 days</option>
							<option value='60'>60 days</option>
							<option value='90'>90 days</option>
							<option value='custom'>Custom</option>
							<option value='null'>No expiration</option>
						</Select>
					</div>

					{ticksInputValue === 'custom' && (
						<div className={styles.datePickerWrapper}>
							<DatePicker date={customTicksDate} setDate={setCustomTicksDate} />
						</div>
					)}
				</div>
			</div>
			<div className={styles.buttons}>
				<Button style={ButtonStyles.TRANSPARENT}>Cancel</Button>
				<Button onClick={addApiKey} isLoading={isAddApiKeyLoading}>
					Generate
				</Button>
			</div>
		</div>
	)
}

export default GenerateAPIKeyModal
