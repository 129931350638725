import React, { useEffect, useState } from 'react'
import styles from './SettingsDeveloperPage.module.scss'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { useAppContext } from '../../../hooks/useAppContext'
import { UserSettingsDeveloperService } from '../../../API/UserSettingsDeveloperService'
import { ApiKey } from '../../../types/ApiKey'
import Spinner from '../../../components/UI/Spinner/Spinner'
import GenerateAPIKeyModal from '../../../components/Modals/GenerateAPIKeyModal'
import { closeModal } from '../../../utils/CloseModal'
import SideModal from '../../../components/SideModals/SideModal'
import WebhookSharedSecretModal from '../../../components/Modals/WebhookSharedSecretModal'
import WebhookSubscriptionSideModal from '../../../components/Modals/WebhookSubscriptionModal'
import { BusinessSettingsDeveloperService } from '../../../API/BusinessSettingsDeveloperService'
import APIKeysTable from '../../../components/Tables/APIKeysTable'
import WebhookDeveloperTable from '../../../components/Tables/WebhookDeveloperTable'
import { WebhookDeveloper } from '../../../types/WebhookDeveloper'
import WebhookSubsIcon from '../../../images/WebhookSubsIcon'
import APIKeysIcon from '../../../images/APIKeysIcon'
import settingsStyles from '../Settings.module.scss'
import ContactMessageIcon from '../../../images/ContactMessageIcon'
import Input from '../../../components/UI/FormComponents/Input'
import PlusIcon from '../../../images/PlusIcon'
import Modal from '../../../components/Modals/Modal'

const SettingsDeveloperPage = () => {
	const { business, setActionMessage } = useAppContext()
	const [isGenerateAPIKeyModalActive, setIsGenerateAPIKeyModalActive] = useState(false)
	const [isGenerateAPIKeyModalVisible, setIsGenerateAPIKeyModalVisible] = useState(false)
	const [isSharedSecretModalActive, setIsSharedSecretModalActive] = useState(false)
	const [isSharedSecretModalVisible, setIsSharedSecretModalVisible] = useState(false)
	const [isWebhookSubscriptionModalActive, setIsWebhookSubscriptionModalActive] = useState(false)
	const [isWebhookSubscriptionModalVisible, setIsWebhookSubscriptionModalVisible] = useState(false)

	const [isWebhooksGetLoading, webhooksData, getWebhooks] = useAPI<{
		webhookSecret: string
		webhooks: WebhookDeveloper[]
	}>(() => BusinessSettingsDeveloperService.getWebhooks(business?.id), false)

	const [, , deleteWebhook] = useAPI<{
		webhookSecret: string
		webhooks: WebhookDeveloper[]
	}>(
		(webhookId: string) => BusinessSettingsDeveloperService.deleteWebhook(business?.id, webhookId),
		false,
		'Webhook successfully deleted',
		{ onSuccess: getWebhooks }
	)

	const [isGetApiKeysLoading, apiKeys, getApiKeys] = useAPI<ApiKey[]>(
		() => UserSettingsDeveloperService.getApiKeys(),
		true
	)

	useEffect(() => {
		if (business?.id) {
			getWebhooks()
			getApiKeys()
		}
	}, [business])

	return (
		<>
			{isGenerateAPIKeyModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsGenerateAPIKeyModalActive, setIsGenerateAPIKeyModalVisible)
					}
					visible={isGenerateAPIKeyModalVisible}
					setVisible={setIsGenerateAPIKeyModalVisible}
				>
					<GenerateAPIKeyModal
						close={() =>
							closeModal(setIsGenerateAPIKeyModalActive, setIsGenerateAPIKeyModalVisible)
						}
						getApiKeys={getApiKeys}
					/>
				</Modal>
			)}
			{isSharedSecretModalActive && (
				<Modal
					closeModal={() => closeModal(setIsSharedSecretModalActive, setIsSharedSecretModalVisible)}
					visible={isSharedSecretModalVisible}
					setVisible={setIsSharedSecretModalVisible}
				>
					<WebhookSharedSecretModal
						businessId={business?.id}
						sharedSecret={webhooksData?.webhookSecret}
						getWebhooks={getWebhooks}
					/>
				</Modal>
			)}
			{isWebhookSubscriptionModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsWebhookSubscriptionModalActive, setIsWebhookSubscriptionModalVisible)
					}
					visible={isWebhookSubscriptionModalVisible}
					setVisible={setIsWebhookSubscriptionModalVisible}
				>
					<WebhookSubscriptionSideModal
						getWebhooks={getWebhooks}
						closeModal={() =>
							closeModal(setIsWebhookSubscriptionModalActive, setIsWebhookSubscriptionModalVisible)
						}
					/>
				</Modal>
			)}
			<div className={styles.settingsDeveloper}>
				<section className={settingsStyles.section}>
					<header className={settingsStyles.sectionHeader}>
						<div>
							<h1>Developer docs</h1>
							<p className={settingsStyles.p}>
								Start accepting payments on your own site with our powerful payments platform.
							</p>
						</div>
						<Button>
							<div className={styles.buttonInner}>
								<ContactMessageIcon />
								Chat with developers
							</div>
						</Button>
					</header>
					<div className={settingsStyles.fieldWrapper}>
						<h2>BUSINESS ID</h2>
						<Input value={business?.id || ''} setValue={() => {}} disabled={true} />
						<span className={settingsStyles.smallText}>
							This is your Business ID. Partners use this to verify your account standing.
						</span>
					</div>
				</section>

				<section className={settingsStyles.section}>
					<header className={settingsStyles.sectionHeader}>
						<div>
							<h1>API keys</h1>
							<p className={settingsStyles.p}>
								These are secure account-specific keys that will help us identify any external API
								connections.
							</p>
						</div>
						<Button
							style={ButtonStyles.SECONDARY}
							onClick={() => setIsGenerateAPIKeyModalActive(true)}
						>
							<div className={styles.buttonInner}>
								<PlusIcon />
								Generate new key
							</div>
						</Button>
					</header>
					{isGetApiKeysLoading && !apiKeys?.length && <Spinner />}
					<APIKeysTable getApiKeys={getApiKeys} apiKeys={apiKeys} isLoading={isGetApiKeysLoading} />
				</section>

				<section className={settingsStyles.section}>
					<header className={settingsStyles.sectionHeader}>
						<div>
							<h1>Webhook subscriptions</h1>
							<p className={settingsStyles.p}>
								Add a URL to start receiving payment notifications and integrate your codebase.
							</p>
						</div>
						<Button
							style={ButtonStyles.SECONDARY}
							onClick={() => setIsWebhookSubscriptionModalActive(true)}
						>
							<div className={styles.buttonInner}>
								<PlusIcon />
								Add an endpoint
							</div>
						</Button>
					</header>
					<div className={settingsStyles.fieldWrapper}>
						<h2>WEBHOOK SECRET</h2>
						<Input value={webhooksData?.webhookSecret || ''} setValue={() => {}} disabled={true} />
						{/*<span className={settingsStyles.smallText}>*/}
						{/*	This is your Business ID. Partners use this to verify your account standing.*/}
						{/*</span>*/}
					</div>
					{isWebhooksGetLoading && !webhooksData?.webhooks && <Spinner />}
					<WebhookDeveloperTable
						webhooksData={webhooksData}
						isLoading={isWebhooksGetLoading}
						deleteWebhook={deleteWebhook}
					/>
				</section>
			</div>
		</>
	)
}

export default SettingsDeveloperPage
