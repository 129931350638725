import React, { FC } from 'react'
import styles from './WarningMessage.module.scss'
import WarningIcon from '../../../images/WarningIcon'
import { Warning, Warnings } from '../../../types/Warning'
import WarningBellSVG from '../../../images/WarningBellSVG'
import WarningWalletSVG from '../../../images/WarningWalletSVG'
import InviteTeamWarningIcon from '../../../images/InviteTeamWarningIcon'
import PaymentLinkWarningIcon from '../../../images/PaymentLinkWarningIcon'
import { Link } from 'react-router-dom'
import HoodpayFeeWarningIcon from '../../../images/HoodpayFeeWarningIcon'

interface WarningMessageProps {
	warningType: Warning
}

const WarningMessage: FC<WarningMessageProps> = ({ warningType }) => {
	const warningContents = {
		[Warnings.PAYMENT_RESTRICTED]: {
			title: 'Payments Restricted',
			icon: <WarningBellSVG />,
			content: (
				<p>
					After conducting a thorough review of your account, we’ve determined that your use of our
					platform is not in line with our Platform Agreement. As a result, payments to your account
					have been restricted.
				</p>
			),
			button: <a>Contact support →</a>,
			color: 'red',
		},
		[Warnings.ACTION_REQUIRED]: {
			title: 'Action Required',
			icon: <WarningIcon />,
			content: (
				<p>
					You have an unpaid balance of <strong>$536.02</strong>. If you do not pay this balance by
					March 19th, 2023 we may restrict payments to your account until the balance is paid.
				</p>
			),
			button: <a>Contact support →</a>,
			color: 'red',
		},
		[Warnings.CONNECT_WALLET]: {
			title: 'Connect Your Wallet',
			icon: <WarningWalletSVG />,
			content: (
				<p>
					To begin accepting payments, please connect your crypto wallets. You’ll get paid out
					directly with 0% in transaction fees, Hoodpay does not control your keys.
				</p>
			),
			button: <Link to={'/settings/cryptocurrency'}>Get started →</Link>,
			color: 'green',
		},
		[Warnings.CREATE_PAYMENT]: {
			title: 'Create Payment Link',
			icon: <PaymentLinkWarningIcon />,
			content: (
				<p>
					A beautifully-hosted checkout page for custom payments from customers all set up in
					minutes. Create your first payment link and get paid now, not later.
				</p>
			),
			button: <Link to={'/payments'}>Get started →</Link>,
			color: 'green',
		},
		[Warnings.INVITE_TEAM]: {
			title: 'Invite Team Members',
			icon: <InviteTeamWarningIcon />,
			content: (
				<p>
					Start inviting your accountants, support staff, administrators, and developers to manage
					payments within your Hoodpay business account.
				</p>
			),
			button: <Link to={'/settings/team'}>Get started →</Link>,
			color: 'green',
		},
		[Warnings.HOODPAY_FEE]: {
			title: 'Hoodpay Fee',
			icon: <HoodpayFeeWarningIcon />,
			content: <p>This payment was sent to Hoodpay, LLC to cover the existing fees you owed.</p>,
			button: <Link to={'/settings/billing'}>View more →</Link>,
			color: 'yellow',
		},
	}

	return (
		<div className={`${styles.warningMessage} ${styles[warningContents[warningType].color]}`}>
			<header>
				{warningContents[warningType].icon}
				<h2>{warningContents[warningType].title}</h2>
			</header>
			{warningContents[warningType].content}
			{warningContents[warningType].button}
		</div>
	)
}

export default WarningMessage
