import React, { FC, useState } from 'react'
import styles from './AuthReviewTerms.module.scss'
import PaperSVG from '../../images/PaperSVG'
import WebSVG from '../../images/WebSVG'
import CardSVG from '../../images/CardSVG'
import GunSVG from '../../images/GunSVG'
import DocumentSVG from '../../images/DocumentSVG'
import Button, { ButtonStyles } from '../UI/Button/Button'

interface AuthReviewTermsProps {
	register: () => void
	isCreateBusinessLoading: boolean
	isBusinessLogoLoading: boolean
	isRegisterLoading: boolean
}

const AuthReviewTerms: FC<AuthReviewTermsProps> = ({
	register,
	isRegisterLoading,
	isCreateBusinessLoading,
	isBusinessLogoLoading,
}) => {
	return (
		<div className={styles.authReviewTerms}>
			<h1 className={styles.h1}>Review acceptable use policy</h1>
			<p className={styles.grayText}>
				By accepting card, crypto, or wallet payments with Hoodpay, you hereby agree to not partake
				in the following risk categories:
			</p>
			<div className={styles.policyWrapper}>
				<PaperSVG />
				<div className={styles.policy}>
					<p>
						Illegal activities:
						<ul>
							<li>No transactions for illegal products, services or activities</li>
							<li>No money laundering or terrorist financing</li>
						</ul>
					</p>
				</div>
			</div>
			<div className={styles.policyWrapper}>
				<WebSVG />
				<div className={styles.policy}>
					<p>
						High-risk industries:
						<ul>
							<li>No adult content or services</li>
							<li>No gambling or betting</li>
							<li>No unregulated financial services (e.g. money transfers)</li>
							<li>No sale of drugs, controlled substances, or drug paraphernalia</li>
						</ul>
					</p>
				</div>
			</div>
			<div className={styles.policyWrapper}>
				<CardSVG />
				<div className={styles.policy}>
					<p>
						Fraud and deception:
						<ul>
							<li>No chargeback abuse or credit card fraud</li>
							<li>No phishing, identity theft, or deceptive practices</li>
							<li>No counterfeit or stolen goods</li>
						</ul>
					</p>
				</div>
			</div>
			<div className={styles.policyWrapper}>
				<GunSVG />
				<div className={styles.policy}>
					<p>
						Harmful products and services:
						<ul>
							<li>No weapons, explosives, or hazardous materials</li>
							<li>No human trafficking or exploitation</li>
							<li>No products or services promoting hate, violence, or discrimination</li>
						</ul>
					</p>
				</div>
			</div>
			<div className={styles.policyWrapper}>
				<DocumentSVG />
				<div className={styles.policy}>
					<p>
						Intellectual property rights:
						<ul>
							<li>
								No sale of copyrighted, trademarked, or patented materials without authorization
							</li>
							<li>No sale of counterfeit or unauthorized replica products</li>
						</ul>
					</p>
				</div>
			</div>
			<p className={styles.grayText}>
				If you sell content within high-risk categories, payments to your account will be restricted
				pending high-risk onboarding.
			</p>
			<p className={styles.grayText}>
				View all of our policies: <a href='#'>Terms of Use</a>, <a href='#'>Privacy Policy</a>,{' '}
				<a href='#'>Payment Processing Agreement</a>,{' '}
				<a href='#'>Agreement to Receive Electronic Disclosures</a>
			</p>

			<Button
				style={ButtonStyles.SECONDARY}
				onClick={register}
				isLoading={isBusinessLogoLoading || isCreateBusinessLoading || isRegisterLoading}
			>
				Create account
			</Button>
		</div>
	)
}

export default AuthReviewTerms
