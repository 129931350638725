import React, { FC, ReactHTMLElement, ReactNode } from 'react'
import styles from './SideModal.module.scss'
import Button, { ButtonStyles } from '../UI/Button/Button'
import { is } from 'date-fns/locale'

interface ConfirmSideModalProps {
	title: string
	confirmText: ReactNode
	onConfirm: () => void
	closeModal: () => void
	isLoading: boolean
}
const ConfirmSideModal: FC<ConfirmSideModalProps> = ({
	confirmText,
	title,
	onConfirm,
	closeModal,
	isLoading,
}) => {
	return (
		<div className={styles.sideModalInner}>
			<h1 className={styles.h1}>{title}</h1>

			<div className={styles.confirmText}>{confirmText}</div>
			<div className={styles.buttons}>
				<Button style={ButtonStyles.TRANSPARENT} onClick={closeModal}>
					Cancel
				</Button>
				<Button onClick={onConfirm} isLoading={isLoading}>
					Yes
				</Button>
			</div>
		</div>
	)
}

export default ConfirmSideModal
