import React, { FC } from 'react'
import styles from './Timeline.module.scss'
import CircleInfoIcon from '../../images/CircleInfoIcon'
import ClockIcon from '../../images/ClockIcon'
import SuccessStatusIcon from '../../images/SuccessStatusIcon'
import { Statuses } from '../../types/Status'
import { CryptoCharge, PaymentTimeline } from '../../types/PaymentDetailed'
import WarningIcon from '../../images/WarningIcon'
import { format } from 'date-fns'
import OpenLinkSVG from '../../images/OpenLinkSVG'
import { Cryptocurrencies } from '../../types/Cryptocurrencies'
import ErrorIcon from '../../images/ErrorIcon'
import ErrorStatusIcon from '../../images/ErrorStatusIcon'

interface TimelineProps {
	timeline: PaymentTimeline[]
	openTransactionLinkHandler: () => void
}

const Timeline: FC<TimelineProps> = ({ timeline, openTransactionLinkHandler }) => {
	const timelineData = {
		[Statuses.CONFIRMING]: {
			title: 'Payment confirming',
			icon: <ClockIcon />,
			description: "We're waiting for 1 confirmation for this transaction\n",
			link: true,
		},
		[Statuses.SUCCESS]: {
			title: 'Payment completed',
			icon: <SuccessStatusIcon />,
			description: 'The payment was successfully sent to your wallet',
			link: true,
		},
		[Statuses.FAILED]: {
			title: 'Payment error',
			icon: <ErrorStatusIcon />,
			description: 'Contact your dedicated account manager to resolve this issue',
			link: false,
		},
		[Statuses.EXPIRED]: {
			title: 'Payment expired',
			icon: <ErrorStatusIcon />,
			description: 'The payment was not sent within the 60 minute timeframe',
			link: false,
		},
		[Statuses.CANCELLED]: {
			title: 'Payment canceled',
			icon: <ErrorStatusIcon />,
			description: 'The payment was cancelled',
			link: false,
		},
		[Statuses.PENDING]: {
			title: 'Payment pending',
			icon: <CircleInfoIcon />,
			description: 'We’ve detected the payment on the blockchain',
			link: true,
		},
		[Statuses.AWAITING_PAYMENT]: {
			title: 'Payment created',
			icon: <CircleInfoIcon />,
			description: 'Your customer created this payment',
			link: false,
		},
		[Statuses.NEW]: {
			title: 'Payment created',
			icon: <CircleInfoIcon />,
			description: 'Your customer created this payment',
			link: false,
		},
		[Statuses.UNDERPAID]: {
			title: 'Payment underpaid',
			icon: <ErrorStatusIcon />,
			description: 'The payment was underpaid',
			link: false,
		},
	}

	return (
		<div className={styles.timeline}>
			{timeline.map(timelineItem => (
				<div className={styles.timelineItem}>
					<div className={styles.timelineProgressBarItem}>
						<div className={styles.timelineProgressBarItemIcon}>
							{timelineData[timelineItem.paymentStatus].icon}
						</div>
						<span className={styles.timelineProgressBarItemLine} />
					</div>
					<div className={styles.timelineDetail}>
						<div>
							<p> {timelineData[timelineItem.paymentStatus].title}</p>
							<time>{format(new Date(timelineItem.time), 'MMM dd, yyyy, hh:mm aa')}</time>
						</div>
						{timelineData[timelineItem.paymentStatus].link && (
							<OpenLinkSVG onClick={openTransactionLinkHandler} />
						)}
					</div>
				</div>
			))}
		</div>
	)
}

export default Timeline
