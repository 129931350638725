import React, { FC, useState } from 'react'
import ReactSlider from 'react-slider'
import './Slider.scss'

interface SliderProps {
	value: number
	setValue: (value: number) => void
	max: number
	min?: number
	valueSymbol?: string
}

const Slider: FC<SliderProps> = ({ value, setValue, max, valueSymbol, min }) => {
	const [isHover, setIsHover] = useState(false)
	const [isFocus, setIsFocus] = useState(false)

	return (
		<div>
			<ReactSlider
				value={value}
				max={max}
				min={min}
				onChange={value => setValue(value)}
				className={'slider'}
				thumbClassName={'sliderThumb'}
				thumbActiveClassName={'sliderThumbActive'}
				trackClassName={'sliderTrack'}
				renderThumb={(props, state) => (
					<div
						{...props}
						className={`sliderThumbWrapper ${isFocus && 'active'}`}
						onFocus={() => setIsFocus(true)}
						onBlur={() => setIsFocus(false)}
					>
						<div className={`sliderTooltip ${(isHover || isFocus) && 'active'}`}>
							{`${state.valueNow}${valueSymbol || ''}`}
						</div>
						<div
							className={'sliderThumb'}
							onMouseOver={() => setIsHover(true)}
							onMouseLeave={() => setIsHover(false)}
						></div>
					</div>
				)}
			/>
		</div>
	)
}

export default Slider
