import React, { FC, useEffect, useState } from 'react'
import styles from './SettingsCompanyInfoPage.module.scss'
import settingsStyles from '../Settings.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import Select from '../../../components/UI/FormComponents/Select'
import FileInput from '../../../components/UI/FormComponents/FileInput'
import { useAPI } from '../../../hooks/useAPI'
import { BusinessSettingsGeneralService } from '../../../API/BusinessSettingsGeneralService'
import { useAppContext } from '../../../hooks/useAppContext'
import { SettingsPageProps } from '../../../types/SettingsPageProps'
import { BusinessSettingsLegalService } from '../../../API/BusinessSettingsLegalService'
import Textarea from '../../../components/UI/FormComponents/Textarea'

const currencies = [
	'USD',
	'EUR',
	'JPY',
	'GBP',
	'AUD',
	'CAD',
	'CHF',
	'CNY',
	'SEK',
	'NZD',
	'PLN',
	'ILS',
	'HKD',
	'ISK',
	'PHP',
	'DKK',
	'HUF',
	'CZK',
	'RON',
	'IDR',
	'INR',
	'BRL',
	'RUB',
	'HRK',
	'THB',
	'MYR',
	'BGN',
	'NOK',
	'ZAR',
	'MXN',
	'SGD',
	'KRW',
]

const SettingsCompanyInfoPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	const { business, getUser } = useAppContext()
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [currency, setCurrency] = useState('')
	const [terms, setTerms] = useState('')
	const [privacyPolicy, setPrivacyPolicy] = useState('')
	const [websiteURl, setWebsiteURL] = useState('')
	const [logoFile, setLogoFile] = useState<File | null>(null)

	const [isTermsLoading, , updateTerms] = useAPI(
		() => BusinessSettingsLegalService.updateTerms(business?.id, terms),
		false,
		'Terms successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isPrivacyPolicyLoading, , updatePrivacyPolicy] = useAPI(
		() => BusinessSettingsLegalService.updatePrivacyPolicy(business?.id, privacyPolicy),
		false,
		'Privacy policy successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isNameLoading, , updateName] = useAPI(
		() => BusinessSettingsGeneralService.updateName(business?.id, name),
		false,
		'Name updated successfully',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isUpdateGeneralLoading, , updateGeneral] = useAPI(
		() =>
			BusinessSettingsGeneralService.updateGeneral(business?.id, {
				name: name,
				businessWebsiteUrl: websiteURl || null,
				defaultCurrency: currency,
				privacyPolicyUrl: privacyPolicy || null,
				supportEmail: email || null,
				termsOfServiceUrl: terms || null,
			}),
		false,
		'General details updated successfully',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)

	useEffect(() => {
		if (settings) {
			setName(settings.name)
			setCurrency(settings.defaultCurrency)
			setEmail(settings?.supportEmail || '')
			setPrivacyPolicy(settings?.privacyPolicyUrl || '')
			setTerms(settings?.termsUrl || '')
			setWebsiteURL(settings?.siteUrl || '')
		}
	}, [settings])

	return (
		<div className={styles.settingsGeneral}>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>General details</h1>
						<p>
							This information will be available to customers during checkout and be visible on
							receipts.
						</p>
					</div>
					<Button onClick={updateGeneral} isLoading={isUpdateGeneralLoading}>
						Save
					</Button>
				</header>
				<div className={settingsStyles.sectionBody}>
					<div className={settingsStyles.fieldWrapper}>
						<h2>Business Name</h2>
						<Input value={name} setValue={value => setName(value)} placeholder={'BusinessName'} />
					</div>
					<div className={settingsStyles.fieldWrapper}>
						<h2>Support Email</h2>
						<Input
							value={email}
							setValue={value => setEmail(value)}
							placeholder={'your@email.com'}
						/>
					</div>
					<div className={settingsStyles.fieldWrapper}>
						<h2>Currency</h2>
						<Select value={currency} setValue={value => setCurrency(value)}>
							{currencies.map(currency => (
								<option value={currency}>{currency}</option>
							))}
						</Select>
					</div>
					<div className={settingsStyles.fieldWrapper}>
						<h2>WEBSITE</h2>
						<Input
							value={websiteURl}
							setValue={value => setWebsiteURL(value)}
							placeholder={'https://example.com'}
						/>
					</div>
					<div className={settingsStyles.fieldsGrid}>
						<div className={settingsStyles.fieldWrapper}>
							<h2>TERMS OF SERVICE</h2>
							<Input
								value={terms}
								setValue={value => setTerms(value)}
								placeholder={'https://example.com/terms-of-service'}
							/>
						</div>
						<div className={settingsStyles.fieldWrapper}>
							<h2>PRIVACY POLICY</h2>
							<Input
								value={privacyPolicy}
								setValue={value => setPrivacyPolicy(value)}
								placeholder={'https://example.com/privacy-policy'}
							/>
						</div>
					</div>
				</div>
			</section>
			{/*<section className={settingsStyles.section}>*/}
			{/*	<header className={settingsStyles.sectionHeader}>*/}
			{/*		<div>*/}
			{/*			<h1>Business details</h1>*/}
			{/*			<p>*/}
			{/*				This information will not be public to customers. To accept card payments, you will be*/}
			{/*				required to enter business details below. If you are not a registered company, add*/}
			{/*				your personal details.*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*		<Button>Save</Button>*/}
			{/*	</header>*/}
			{/*	<div className={settingsStyles.sectionBody}>*/}
			{/*		<div className={settingsStyles.fieldsGrid}>*/}
			{/*			<div className={settingsStyles.fieldWrapper}>*/}
			{/*				<h2>COUNTRY</h2>*/}
			{/*				<Select value={currency} setValue={value => setCurrency(value)}>*/}
			{/*					<option value='0'>Select</option>*/}
			{/*					{currencies.map(currency => (*/}
			{/*						<option value={currency}>{currency}</option>*/}
			{/*					))}*/}
			{/*				</Select>*/}
			{/*			</div>*/}
			{/*			<div className={settingsStyles.fieldWrapper}>*/}
			{/*				<h2>PHONE NUMBER</h2>*/}
			{/*				<Input*/}
			{/*					value={email}*/}
			{/*					setValue={value => setEmail(value)}*/}
			{/*					placeholder={'+0 (000) 000 0000'}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*			<div className={settingsStyles.fieldWrapper}>*/}
			{/*				<h2>Street address</h2>*/}
			{/*				<Input*/}
			{/*					value={email}*/}
			{/*					setValue={value => setEmail(value)}*/}
			{/*					placeholder={'Street address'}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*			<div className={settingsStyles.fieldWrapper}>*/}
			{/*				<h2>Apt, suite, etc</h2>*/}
			{/*				<Input*/}
			{/*					value={email}*/}
			{/*					setValue={value => setEmail(value)}*/}
			{/*					placeholder={'Apt, suite, etc (optional)'}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*			<div className={settingsStyles.fieldWrapper}>*/}
			{/*				<h2>CITY</h2>*/}
			{/*				<Input value={email} setValue={value => setEmail(value)} placeholder={'City'} />*/}
			{/*			</div>*/}
			{/*			<div className={settingsStyles.fieldWrapper}>*/}
			{/*				<h2>State, province, or region</h2>*/}
			{/*				<Input*/}
			{/*					value={email}*/}
			{/*					setValue={value => setEmail(value)}*/}
			{/*					placeholder={'State, province, or region (optional)'}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className={settingsStyles.fieldWrapper}>*/}
			{/*			<h2>ZIP CODE</h2>*/}
			{/*			<Input value={email} setValue={value => setEmail(value)} placeholder={'00000-00000'} />*/}
			{/*		</div>*/}
			{/*		<div className={settingsStyles.fieldWrapper}>*/}
			{/*			<h2>BUSINESS DESCRIPTION</h2>*/}
			{/*			<Textarea*/}
			{/*				value={email}*/}
			{/*				setValue={value => setEmail(value)}*/}
			{/*				placeholder={*/}
			{/*					'Give us the elevator pitch about your project or business. Tell us about how you’ll use Hoodpay.'*/}
			{/*				}*/}
			{/*			/>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</section>*/}

			{/*<div className={styles.fieldBox}>*/}
			{/*	<header>*/}
			{/*		<h2>Business Logo</h2>*/}
			{/*		<p>Upload a business logo to appear on checkout, and more</p>*/}
			{/*	</header>*/}
			{/*	<div className={styles.fieldBoxBody}>*/}
			{/*		<div className={styles.imageInputWrapper}>*/}
			{/*			<div*/}
			{/*				className={`${styles.currentLogo} ${settings?.design.url ? styles.noBg : ''}`}*/}
			{/*				onClick={() => {*/}
			{/*					setLogoFile(null)*/}
			{/*					updateLogo()*/}
			{/*				}}*/}
			{/*			>*/}
			{/*				{settings?.design.url && !logoFile ? (*/}
			{/*					<img*/}
			{/*						src={`https://imagedelivery.net/${settings.design.url}/logo`}*/}
			{/*						alt='businessLogo'*/}
			{/*					/>*/}
			{/*				) : logoFile ? (*/}
			{/*					<img src={URL.createObjectURL(logoFile)} alt='businessLogo' />*/}
			{/*				) : (*/}
			{/*					'?'*/}
			{/*				)}*/}
			{/*			</div>*/}
			{/*			<FileInput*/}
			{/*				text={'Upload Image (JPG, GIF or PNG. 1MB max)'}*/}
			{/*				file={logoFile}*/}
			{/*				setFile={setLogoFile}*/}
			{/*			/>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</div>
	)
}

export default SettingsCompanyInfoPage
