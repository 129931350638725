import React, { FC, useState } from 'react'
import styles from './Modals.module.scss'
import CloseButton from '../UI/CloseButton/CloseButton'
import Input from '../UI/FormComponents/Input'
import Textarea from '../UI/FormComponents/Textarea'
import Button, { ButtonStyles } from '../UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { PaymentsService } from '../../API/PaymentsService'
import { useAppContext } from '../../hooks/useAppContext'
import { is } from 'date-fns/locale'
import PriceField from '../UI/FormComponents/PriceField'
import { BusinessesCustomersService } from '../../API/BusinessesCustomersService'

interface CreatePaymentModalProps {
	close: () => void
	setWasCustomerCreated: (value: boolean) => void
}

const CreateCustomerModal: FC<CreatePaymentModalProps> = ({ close, setWasCustomerCreated }) => {
	const { business } = useAppContext()
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')

	const [isLoading, , createCustomer] = useAPI(
		() =>
			BusinessesCustomersService.createCustomer(business?.id, {
				name: name || null,
				email: email,
			}),
		false,
		'Customer successfully created',
		{
			onSuccess: () => {
				close()
				setWasCustomerCreated(true)
			},
		}
	)

	return (
		<div className={styles.sideModalInner}>
			<h1 className={styles.h1}>Create customer</h1>

			<div className={styles.fieldWrapper}>
				<h3>Customer Name</h3>
				<Input value={name} setValue={setName} placeholder={'John Doe'} />
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Customer Email</h3>
				<Input value={email} setValue={setEmail} placeholder={'john@example.com'} />
			</div>

			<div className={styles.buttons}>
				<Button style={ButtonStyles.TRANSPARENT}>Cancel</Button>
				<Button onClick={createCustomer} isLoading={isLoading}>
					Create
				</Button>
			</div>
		</div>
	)
}

export default CreateCustomerModal
