import React, { FC } from 'react'
import SuccessStatusIcon from '../../../images/SuccessStatusIcon'
import styles from './ActiveBadge.module.scss'
import XSVG from '../../../images/XSVG'

interface ActiveBadgeProps {
	onClick: (event: React.MouseEvent) => void
	isActive: boolean
}
const ActiveBadge: FC<ActiveBadgeProps> = ({ onClick, isActive }) => {
	return (
		<div className={`${styles.activeBadge} ${!isActive && styles.inactive}`} onClick={onClick}>
			{isActive ? (
				<>
					Active
					<SuccessStatusIcon />
				</>
			) : (
				<>
					Inactive
					<XSVG />
				</>
			)}
		</div>
	)
}

export default ActiveBadge
