import React from 'react'

const ContactMessageIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.0708 14.8408C11.9368 14.8408 15.3208 12.746 15.3208 8.09082C15.3208 3.43565 11.9368 1.34082 8.0708 1.34082C4.20481 1.34082 0.820801 3.43565 0.820801 8.09082C0.820801 9.85832 1.30863 11.2567 2.12544 12.3114C2.36438 12.62 2.45931 13.03 2.2939 13.3834C2.19315 13.5987 2.08713 13.8042 1.97872 13.999C1.52449 14.815 2.15031 16.0039 3.06606 15.8205C4.0821 15.6171 5.21832 15.3128 6.1662 14.8646C6.3641 14.7711 6.5842 14.7347 6.8013 14.7622C7.2158 14.8148 7.6403 14.8408 8.0708 14.8408Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default ContactMessageIcon
