import React, { useEffect, useState } from 'react'
import styles from './AuthPage.module.scss'
import Button, { ButtonStyles } from '../../components/UI/Button/Button'
import Input from '../../components/UI/FormComponents/Input'
import Select from '../../components/UI/FormComponents/Select'
import Textarea from '../../components/UI/FormComponents/Textarea'
import FileInput from '../../components/UI/FormComponents/FileInput'
import { useAPI } from '../../hooks/useAPI'
import { BusinessesService } from '../../API/BusinessesService'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import { UserService } from '../../API/UserService'
import { User } from '../../types/User'
import { BusinessSettingsGeneralService } from '../../API/BusinessSettingsGeneralService'

const AuthSetUp = () => {
	return <div className={styles.authForm}></div>
}

export default AuthSetUp
