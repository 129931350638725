import React from 'react'
import styles from './Tables.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import RefreshIcon from '../../images/RefreshIcon'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import HoodpayLogo from '../../images/HoodpayLogo'

const TeamMembersTable = () => {
	return (
		<div className={styles.table}>
			<div className={styles.tableHeader}>
				<h3>Name</h3>
				<h3 className={styles.medium}>Permissions</h3>
				<h3>Auth</h3>
				<h3>Last Login</h3>
				<h3 className={styles.settings} />
			</div>
			<div className={styles.tableBodyWrapper}>
				<div className={styles.tableBody}>
					<div className={`${styles.tableBodyItem}`}>
						<div className={styles.nameWrapper}>
							<div className={styles.memberLogo}>
								<HoodpayLogo />
							</div>
							<div className={styles.name}>
								<h3>DemoUser</h3>
								<span>https://demo.pay.com</span>
							</div>
						</div>
					</div>
					<div className={`${styles.tableBodyItem} ${styles.medium}`}>
						<p>Owner</p>
					</div>
					<div className={`${styles.tableBodyItem}`}>
						<p>No Two-Step</p>
					</div>
					<div className={`${styles.tableBodyItem}`}>
						<p>2 minutes ago</p>
					</div>
					<div className={`${styles.tableBodyItem} ${styles.settings}`}>
						<SettingsButton />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TeamMembersTable
