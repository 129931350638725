import { privateAPI } from './API'

export const BusinessesAnalyticsDashboardService = {
	getDashboard: async (
		businessId: number | undefined,
		fromTime: null | Date,
		toTime: null | Date
	) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/analytics/dashboard`, {
			params: {
				fromTime,
				toTime,
			},
		})
	},
}
