import React, { FC, ReactNode, useEffect, useRef } from 'react'
import styles from './ActionMenu.module.scss'

interface ActionMenuProps {
	actionButtons: {
		title: string
		icon: ReactNode
		onClick: () => void
	}[]
	isActive: boolean
	setIsActive: (value: boolean) => void
}

const ActionMenu: FC<ActionMenuProps> = ({ actionButtons, isActive, setIsActive }) => {
	const actionMenuRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: any) => {
		if (!actionMenuRef.current?.contains(event.target as Node)) {
			setIsActive(false)
		}
	}

	useEffect(() => {
		if (isActive) {
			document.addEventListener('click', handleClickOutside)
			actionMenuRef.current?.classList.add(styles.active)

			setTimeout(() => {
				actionMenuRef.current?.classList.add(styles.visible)
			}, 10)
		} else {
			document.removeEventListener('click', handleClickOutside)
			actionMenuRef.current?.classList.remove(styles.visible)

			setTimeout(() => {
				actionMenuRef.current?.classList.remove(styles.active)
			}, 300)
		}
	}, [isActive])

	return (
		<div className={styles.actionMenu} ref={actionMenuRef}>
			{actionButtons.map(button => (
				<div
					key={button.title}
					className={styles.actionButton}
					onClick={event => {
						event.stopPropagation()
						event.preventDefault()
						button.onClick()
					}}
				>
					<p>{button.title}</p>
					{button.icon}
				</div>
			))}
		</div>
	)
}

export default ActionMenu
