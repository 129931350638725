import React from 'react'

const ArrowHeadSvg = () => {
	return (
		<svg width='4' height='8' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.478639 7.90756C0.380782 7.87346 0.306408 7.81382 0.271825 7.74172C0.237242 7.66963 0.245273 7.59096 0.294155 7.52297L2.68252 4.20685L0.293328 0.891314C0.268494 0.857582 0.253528 0.820774 0.249293 0.78301C0.245059 0.745247 0.251639 0.707275 0.268654 0.671284C0.28567 0.635293 0.312785 0.601995 0.348435 0.57331C0.384085 0.544625 0.427566 0.521121 0.476371 0.504151C0.525176 0.487181 0.578338 0.477082 0.632795 0.474437C0.687251 0.471791 0.741924 0.47665 0.79366 0.488735C0.845397 0.50082 0.893174 0.519892 0.934238 0.54485C0.975302 0.569808 1.00884 0.600158 1.03292 0.634153L3.51476 4.07827C3.54359 4.11819 3.55859 4.16221 3.55859 4.20685C3.55859 4.2515 3.54359 4.29552 3.51476 4.33543L1.03292 7.77956C0.983766 7.84746 0.897816 7.89906 0.793912 7.92306C0.690009 7.94705 0.576631 7.94148 0.478639 7.90756V7.90756Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default ArrowHeadSvg
