import React from 'react'

const MetricsSVG = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.73511 12.876H2.98511C2.45467 12.876 1.94597 12.6653 1.57089 12.2902C1.19582 11.9151 0.985107 11.4064 0.985107 10.876V3.37598C0.985107 2.84554 1.19582 2.33684 1.57089 1.96176C1.94597 1.58669 2.45467 1.37598 2.98511 1.37598H13.4851C14.0155 1.37598 14.5242 1.58669 14.8993 1.96176C15.2744 2.33684 15.4851 2.84554 15.4851 3.37598V10.876C15.4851 11.4064 15.2744 11.9151 14.8993 12.2902C14.5242 12.6653 14.0155 12.876 13.4851 12.876H9.73511M6.73511 12.876L4.98511 15.876M6.73511 12.876H9.73511M9.73511 12.876L11.4851 15.876M4.98511 8.87598V5.37598M8.23511 8.87598V6.37598M11.4851 8.87598V7.37598'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default MetricsSVG
