import React from 'react'

const HomeIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.823 16.2041H13.323C14.4276 16.2041 15.323 15.3087 15.323 14.2041V6.70411L8.07296 1.7041L0.822998 6.70411V14.2041C0.822998 15.3087 1.71844 16.2041 2.823 16.2041Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.82239 12.7032C5.82239 11.5986 6.71786 10.7032 7.82236 10.7032H8.32236C9.42696 10.7032 10.3224 11.5986 10.3224 12.7032V16.2032H5.82239V12.7032Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default HomeIcon
