import React, { FC, SVGProps } from 'react'

const TrashCanSvg: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='20'
			height='22'
			viewBox='0 0 20 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M19.0999 4.46596C17.4899 4.30596 15.8799 4.18596 14.2599 4.09596V4.08596L14.0399 2.78596C13.8899 1.86596 13.6699 0.485962 11.3299 0.485962H8.70994C6.37994 0.485962 6.15994 1.80596 5.99994 2.77596L5.78994 4.05596C4.85994 4.11596 3.92994 4.17596 2.99994 4.26596L0.959943 4.46596C0.539943 4.50596 0.239943 4.87596 0.279943 5.28596C0.319943 5.69596 0.679943 5.99596 1.09994 5.95596L3.13994 5.75596C8.37994 5.23596 13.6599 5.43596 18.9599 5.96596H19.0399C19.4199 5.96596 19.7499 5.67596 19.7899 5.28596C19.805 5.0862 19.7412 4.88849 19.6122 4.73521C19.4832 4.58193 19.2993 4.48527 19.0999 4.46596ZM17.2599 7.37596C17.0199 7.12596 16.6899 6.98596 16.3499 6.98596H3.70994C3.36994 6.98596 3.02994 7.12596 2.79994 7.37596C2.56994 7.62596 2.43994 7.96596 2.45994 8.31596L3.07994 18.576C3.18994 20.096 3.32994 21.996 6.81994 21.996H13.2399C16.7299 21.996 16.8699 20.106 16.9799 18.576L17.5999 8.32596C17.6199 7.96596 17.4899 7.62596 17.2599 7.37596ZM11.6899 16.986H8.35994C7.94994 16.986 7.60994 16.646 7.60994 16.236C7.60994 15.826 7.94994 15.486 8.35994 15.486H11.6899C12.0999 15.486 12.4399 15.826 12.4399 16.236C12.4399 16.646 12.0999 16.986 11.6899 16.986ZM12.5299 12.986H7.52994C7.11994 12.986 6.77994 12.646 6.77994 12.236C6.77994 11.826 7.11994 11.486 7.52994 11.486H12.5299C12.9399 11.486 13.2799 11.826 13.2799 12.236C13.2799 12.646 12.9399 12.986 12.5299 12.986Z' />
		</svg>
	)
}

export default TrashCanSvg
