import { AuthLogin } from '../types/AuthLogin'
import { publicAPI } from './API'
import { AuthRegister } from '../types/AuthRegister'
import { Await } from 'react-router-dom'

export const AuthService = {
	login: async (data: AuthLogin) => {
		return await publicAPI.post(`v1/dash/auth/login/basic`, data)
	},
	register: async (data: AuthRegister) => {
		return await publicAPI.post(`v1/dash/auth/register/basic`, data)
	},
	reset: async (email: string, recaptcha: string) => {
		return await publicAPI.post(`v1/dash/auth/reset/basic`, {
			email,
			recaptcha,
		})
	},
	completeReset: async (token: string, newPassword: string) => {
		return await publicAPI.post(`/v1/dash/auth/reset/basic/complete`, {
			token,
			newPassword,
		})
	},
	googleLogin: async (code: string, redirectUrl: string) => {
		return await publicAPI.post(`/v1/dash/auth/login/google`, {
			code,
			redirectUrl,
		})
	},
}
