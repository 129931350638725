import React from 'react'

const EarthSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.323 1.67578V5.42578C5.323 7.08268 3.97985 8.42578 2.323 8.42578H1.073M6.073 14.6758C6.073 14.6758 6.823 13.1758 6.823 11.1758C6.823 9.02588 9.2265 10.2039 9.8997 6.91908C10.1214 5.83698 10.9684 4.92578 12.073 4.92578H14.073M15.323 8.17578C15.323 12.1799 12.0771 15.4258 8.073 15.4258C4.06894 15.4258 0.822998 12.1799 0.822998 8.17578C0.822998 4.17172 4.06894 0.925781 8.073 0.925781C12.0771 0.925781 15.323 4.17172 15.323 8.17578Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default EarthSvg
