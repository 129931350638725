import React, { FC, ReactNode } from 'react'
import styles from './Modals.module.scss'
import CloseButton from '../UI/CloseButton/CloseButton'
import Button, { ButtonStyles } from '../UI/Button/Button'
import TopGateways from '../TopGateways/TopGateways'
import { TopGateway } from '../../types/TopGateway'

interface TopGatewaysModalProps {
	close: () => void
	topGateways?: TopGateway[]
}

const TopGatewaysModal: FC<TopGatewaysModalProps> = ({ close, topGateways }) => {
	return (
		<div className={styles.modalContentInner}>
			<header className={styles.header}>
				<h1>TOP PAYMENT METHODS</h1>
				<CloseButton onClick={close} />
			</header>
			<div className={styles.body}>
				<TopGateways gateways={topGateways} />
			</div>
		</div>
	)
}

export default TopGatewaysModal
