import React, { FC } from 'react'
import styles from './AnalyticsStatistics.module.scss'
import StatItem from '../StatItem/StatItem'
import { AnalyticsStatData } from '../../types/AnalyticsStatData'

interface AnalyticsStatisticsProps {
	analyticsStatDatas?: AnalyticsStatData[]
	prevAnalyticsStatDatas?: AnalyticsStatData[]
	toDate: Date | null
	fromDate: Date | null
	durationMs: number
}

const AnalyticsStatistics: FC<AnalyticsStatisticsProps> = ({
	analyticsStatDatas,
	prevAnalyticsStatDatas,
	fromDate,
	toDate,
	durationMs,
}) => {
	console.log(analyticsStatDatas)
	return (
		<div className={styles.statistics}>
			<div className={styles.statisticsBody}>
				<div className={styles.statWrapper}>
					<StatItem
						title={'GROSS REVENUE'}
						isMoney={true}
						data={analyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.grossRevenue,
						}))}
						prevData={prevAnalyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.grossRevenue,
						}))}
						toDate={toDate}
						fromDate={fromDate}
						durationMs={durationMs}
					/>
				</div>
				<div className={styles.statWrapper}>
					<StatItem
						title={'NET REVENUE'}
						isMoney={true}
						data={analyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.netRevenue,
						}))}
						prevData={prevAnalyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.netRevenue,
						}))}
						toDate={toDate}
						fromDate={fromDate}
						durationMs={durationMs}
					/>
				</div>
				<div className={styles.statWrapper}>
					<StatItem
						title={'PAYMENTS'}
						data={analyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.paymentsCount,
						}))}
						prevData={prevAnalyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.paymentsCount,
						}))}
						toDate={toDate}
						fromDate={fromDate}
						durationMs={durationMs}
					/>
				</div>
				<div className={styles.statWrapper}>
					<StatItem
						title={'CUSTOMERS'}
						data={analyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.customersCount,
						}))}
						prevData={prevAnalyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.customersCount,
						}))}
						toDate={toDate}
						fromDate={fromDate}
						durationMs={durationMs}
					/>
				</div>
				<div className={styles.statWrapper}>
					<StatItem
						title={'SPEND PER CUSTOMER'}
						data={analyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.spendPerCustomer,
						}))}
						prevData={prevAnalyticsStatDatas?.map(data => ({
							dateOrHour: data.dateOrHour,
							count: data.spendPerCustomer,
						}))}
						isMoney={true}
						toDate={toDate}
						fromDate={fromDate}
						durationMs={durationMs}
					/>
				</div>
				{/*<div className={styles.statWrapper}>*/}
				{/*	<StatItem title={'MRR'} toDate={toDate} fromDate={fromDate} />*/}
				{/*</div>*/}
			</div>
			<div className={styles.statisticsFooter}>
				{/*<div className={styles.statWrapper}>*/}
				{/*	<StatItem title={'HOODPAY FEES'} withoutGraph={true} toDate={null} fromDate={null} />*/}
				{/*</div>*/}
				{/*<div className={styles.statWrapper}>*/}
				{/*	<StatItem*/}
				{/*		title={'HIGH RISK PAYMENTS'}*/}
				{/*		withoutGraph={true}*/}
				{/*		toDate={null}*/}
				{/*		fromDate={null}*/}
				{/*	/>*/}
				{/*</div>*/}
			</div>
		</div>
	)
}

export default AnalyticsStatistics
