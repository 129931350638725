import React, { FC } from 'react'
import styles from './PaymentMethod.module.scss'
import { Cryptocurrency } from '../../types/Cryptocurrencies'
import { getPaymentMethodIconId } from '../../utils/getPaymentMethodIconId'
import { getPaymentMethodName } from '../../utils/getPaymentMethodName'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { PaymentMethods } from '../../types/PaymentMethods'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'

interface PaymentMethodProps {
	paymentMethod?: Cryptocurrency | PaymentMethods
}

const PaymentMethod: FC<PaymentMethodProps> = ({ paymentMethod }) => {
	if (!paymentMethod) return <p>-</p>

	return (
		<div className={styles.paymentMethod}>
			<SpriteIcon iconId={getPaymentMethodIconId(paymentMethod) || ''} width={17} height={17} />
			{getPaymentMethodName(paymentMethod)}
		</div>
	)
}

export default PaymentMethod
