import React, { FC } from 'react'
import styles from './SettingsButton.module.scss'

interface SettingsButtonProps {
	onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const SettingsButton: FC<SettingsButtonProps> = ({ onClick }) => {
	return (
		<div
			className={styles.settingsButton}
			onClick={event => {
				event.stopPropagation()
				onClick && onClick(event)
			}}
		>
			<span />
		</div>
	)
}

export default SettingsButton
