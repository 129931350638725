import React, { FC, useEffect, useState } from 'react'
import styles from './AuthForms.module.scss'
import Input from '../UI/FormComponents/Input'
import Select from '../UI/FormComponents/Select'
import Textarea from '../UI/FormComponents/Textarea'
import FileInput from '../UI/FormComponents/FileInput'
import Button, { ButtonStyles } from '../UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { User } from '../../types/User'
import { UserService } from '../../API/UserService'
import { BusinessSettingsGeneralService } from '../../API/BusinessSettingsGeneralService'
import { BusinessesService } from '../../API/BusinessesService'

interface BusinessDetailsFormProps {
	setStep?: (step: number) => void
	createNewBusiness?: () => void
	isCreateNewBusinessLoading?: boolean
	businessName: string
	setBusinessName: (value: string) => void
	businessDetails: string
	setBusinessDetails: (value: string) => void
	businessSiteUrl: string
	setBusinessSiteUrl: (value: string) => void
	monthlyRecurringRevenue: string
	setMonthlyRecurringRevenue: (value: string) => void
	businessLogo: File | null
	setBusinessLogo: (value: File | null) => void
}

const BusinessDetailsForm: FC<BusinessDetailsFormProps> = ({
	setStep,
	setBusinessName,
	setBusinessDetails,
	setBusinessLogo,
	businessDetails,
	businessName,
	businessLogo,
	setBusinessSiteUrl,
	businessSiteUrl,
	setMonthlyRecurringRevenue,
	monthlyRecurringRevenue,
	createNewBusiness,
	isCreateNewBusinessLoading,
}) => {
	const navigate = useNavigate()
	const [errors, setErrors] = useState({
		businessDetails: false,
		businessName: false,
		businessSiteUrl: false,
	})

	const createBusinessValidator = () => {
		setErrors({
			...errors,
			businessName: !businessName,
			businessDetails: !businessDetails,
		})

		return !!businessName || !!businessDetails
	}

	const onButtonClickHandler = () => {
		if (createNewBusiness) {
			createNewBusiness()
		} else if (createBusinessValidator()) return setStep && setStep(2)
	}

	return (
		<div className={styles.authForm}>
			<h1>Add your business details </h1>
			<p className={styles.description}>
				This will help speed up the setup of your payments account and customize your business
				dashboard.
			</p>
			<label className={styles.fieldWrapper}>
				<p>Business Name</p>
				<Input
					value={businessName}
					setValue={setBusinessName}
					placeholder={'e.g Melt, Inc'}
					isError={errors.businessName}
				/>
			</label>
			<label className={styles.fieldWrapper}>
				<p>Business Website</p>
				<Input
					value={businessSiteUrl}
					setValue={setBusinessSiteUrl}
					placeholder={'meltincorporated.com'}
				/>
			</label>
			{/*<label className={styles.fieldWrapper}>*/}
			{/*	<p>Business type</p>*/}
			{/*	<Select value={businessSiteUrl} setValue={setBusinessSiteUrl}>*/}
			{/*		<option value='0'>Select category...</option>*/}
			{/*		<option value='0'>Select category...</option>*/}
			{/*	</Select>*/}
			{/*</label>*/}
			<label className={styles.fieldWrapper}>
				<p>Business Details</p>
				<Textarea
					value={businessDetails}
					setValue={setBusinessDetails}
					placeholder={
						"Example: Hoodpay is a crypto payments processor built to power and scale commerce. Businesses can set up a payment account, accept crypto payments and receive payments directly to their wallet. We're cutting out the middleman."
					}
					isError={errors.businessDetails}
				/>
			</label>
			<label className={styles.fieldWrapper}>
				<p>Monthly Payment Volume</p>
				<Select value={monthlyRecurringRevenue} setValue={setMonthlyRecurringRevenue}>
					<option value=''>Select</option>
					<option value='GETTING_STARTED'>I’m just getting started</option>
					<option value='UPTO1000'> Up to $1,000</option>
					<option value='UPTO5000'>$1,000 - $5,000</option>
					<option value='UPTO10000'>$5,000 - $10,000</option>
					<option value='UPTO25000'>$10,000 - $25,000</option>
					<option value='UPTO50000'>$25,000 - $50,000</option>
					<option value='FROM50000'>$50,000+</option>
				</Select>
			</label>

			<label className={styles.fieldWrapper}>
				<p>Business Logo</p>
				<FileInput
					text={'Upload Image (JPG, GIF or PNG. 1MB max)'}
					file={businessLogo}
					setFile={setBusinessLogo}
				/>
			</label>

			<Button
				style={ButtonStyles.SECONDARY}
				onClick={onButtonClickHandler}
				isLoading={isCreateNewBusinessLoading}
			>
				{createNewBusiness ? 'Create' : 'Continue →'}
			</Button>
		</div>
	)
}

export default BusinessDetailsForm
