import React, { FC, useState } from 'react'
import styles from './Modals.module.scss'
import Textarea from '../UI/FormComponents/Textarea'
import Button, { ButtonStyles } from '../UI/Button/Button'

interface NoteSideModalProps {
	isEdit: boolean
	isLoading: boolean
	handleNote: (value: string | null) => void
	note?: string
}
const NoteModal: FC<NoteSideModalProps> = ({ isEdit, handleNote, note, isLoading }) => {
	const [noteValue, setNoteValue] = useState(note || '')

	return (
		<div className={styles.modalInner}>
			<h1 className={styles.h1}>{isEdit ? 'Edit' : 'Create'} Note</h1>
			<div className={styles.fieldWrapper}>
				<h3>Note text (max 120 chars)</h3>
				<Textarea
					value={noteValue}
					setValue={setNoteValue}
					placeholder={'This is a private note only you can view on this payment page'}
				/>
			</div>
			<div className={styles.buttons}>
				<Button style={ButtonStyles.TRANSPARENT}>Cancel</Button>
				<Button onClick={() => handleNote(noteValue)} isLoading={isLoading}>
					Save
				</Button>
			</div>
		</div>
	)
}

export default NoteModal
