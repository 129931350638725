import React, { FC, useEffect, useState } from 'react'
import styles from './PaymentMethodModals.module.scss'
import { Cryptocurrencies, Cryptocurrency } from '../../../types/Cryptocurrencies'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { BusinessSettingsPayments } from '../../../API/BusinessSettingsPayments'
import { useAppContext } from '../../../hooks/useAppContext'
import Slider from '../../UI/Slider/Slider'
import { getPaymentMethodIconId } from '../../../utils/getPaymentMethodIconId'
import { getPaymentMethodName } from '../../../utils/getPaymentMethodName'
import Switch from '../../UI/Switch/Switch'
import WarningLink from '../../UI/WarningLink/WarningLink'
import { Settings } from '../../../types/Settings'
import Radio from '../../UI/Radio/Radio'
import { ScriptPubKeyType } from '../../../types/ScriptPubKeyType'
import tronIcon from '../../../images/tronIcon.svg'
import ethereumIcon from '../../../images/Ethereum.svg'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'

interface CryptocurrencySideModalProps {
	cryptocurrency: {
		cryptocurrency: Cryptocurrency
		networks?: {
			ethereum: Cryptocurrency
			tron: Cryptocurrency
		}
	}
	getSettings: (businessId: number | undefined) => void
	settings?: Settings
}

const CryptocurrencyModal: FC<CryptocurrencySideModalProps> = ({
	cryptocurrency,
	getSettings,
	settings,
}) => {
	const [address, setAddress] = useState('')
	const [confirmations, setConfirmations] = useState<{
		[networkTab: number]: number
	}>({
		0: 1,
		1: 1,
	})
	const [underpaymentThreshold, setUnderpaymentThreshold] = useState(0)
	const { business } = useAppContext()
	const [key, setKey] = useState('')
	const [enablePayments, setEnablePayments] = useState<{
		[networkTab: number]: boolean
	}>({
		0: false,
		1: false,
	})
	const [enableUnderpaymentThreshold, setEnableUnderpaymentThreshold] = useState(false)
	const [blockVPNS, setBlockVPNS] = useState<{
		[networkTab: number]: boolean
	}>({
		0: false,
		1: false,
	})
	const [currentMethodTab, setCurrentMethodTab] = useState(0)
	const [currentNetworkTab, setCurrentNetworkTab] = useState(0)
	const [keyType, setKeyType] = useState(ScriptPubKeyType.Segwit as String)
	const isXPubAvailable =
		cryptocurrency.cryptocurrency === Cryptocurrencies.LITECOIN ||
		cryptocurrency.cryptocurrency === Cryptocurrencies.BITCOIN

	const [isUpdateDirectCryptoLoading, , updateDirectCrypto] = useAPI(
		() =>
			BusinessSettingsPayments.updateDirectCrypto(
				business?.id,
				getCurrentNetwork() as Cryptocurrency,
				address,
				+confirmations[0]
			),
		false,
		'Cryptocurrency successfully configured',
		{ onSuccess: () => getSettings(business?.id) }
	)

	const getCurrentNetwork = () => {
		if (cryptocurrency.networks) {
			if (currentNetworkTab === 1) {
				return cryptocurrency.networks.ethereum
			}
			if (currentNetworkTab === 0) {
				return cryptocurrency.networks.tron
			}
		} else {
			return cryptocurrency.cryptocurrency
		}
	}

	const [isUpdateXPubCryptoLoading, , updateXPubCrypto] = useAPI(
		() =>
			BusinessSettingsPayments.updateXPubCrypto(
				business?.id,
				getCurrentNetwork() as Cryptocurrency,
				key,
				+confirmations[1],
				enableUnderpaymentThreshold ? underpaymentThreshold : null,
				keyType as ScriptPubKeyType
			),
		false,
		'Cryptocurrency successfully configured',
		{ onSuccess: () => getSettings(business?.id) }
	)

	const onSaveHandler = () => {
		if (currentMethodTab === 0) {
			updateDirectCrypto()
		} else if (currentMethodTab === 1) {
			updateXPubCrypto()
		}
	}

	useEffect(() => {
		const currentDirectCryptoSetting = settings?.directCryptoSettings?.cryptos.find(
			cryptoSetting => cryptoSetting.crypto === getCurrentNetwork()
		)
		const currentXPubCryptoSetting = settings?.xPubCryptoSettings?.cryptos.find(
			cryptoSetting => cryptoSetting.cryptoCoin === cryptocurrency.cryptocurrency
		)

		if (currentXPubCryptoSetting) {
			setCurrentMethodTab(1)
		}

		setKey(currentXPubCryptoSetting?.xPub || '')
		setAddress(currentDirectCryptoSetting?.address.address || '')
		setConfirmations({
			0: currentDirectCryptoSetting?.address.requiredConfirmations || 0,
			1: currentXPubCryptoSetting?.requiredConfirmations || 0,
		})
		setEnablePayments({
			0: currentDirectCryptoSetting?.isActive || false,
			1: currentXPubCryptoSetting?.isActive || false,
		})
		setEnableUnderpaymentThreshold(
			currentXPubCryptoSetting?.percentagesUnderpaymentThreshold !== undefined
		)
		setUnderpaymentThreshold(currentXPubCryptoSetting?.percentagesUnderpaymentThreshold || 0)
		setKeyType(currentXPubCryptoSetting?.scriptPubKeyType || ScriptPubKeyType.Segwit)
	}, [settings, currentNetworkTab])

	// @ts-ignore
	// @ts-ignore
	// @ts-ignore
	return (
		<div className={styles.paymentMethodModal}>
			<header className={styles.header}>
				<SpriteIcon
					iconId={getPaymentMethodIconId(cryptocurrency.cryptocurrency) || ''}
					width={24}
					height={24}
				/>
				<h1 className={styles.h1}>{getPaymentMethodName(cryptocurrency.cryptocurrency)}</h1>
			</header>

			<p className={styles.description}>Fee: 1% (+$0.10 for payments over $9.99)</p>
			<p className={styles.description}>
				When using direct to address, you only need to connect your crypto address to get paid.
				You’ll get paid directly to your wallet.
			</p>
			{isXPubAvailable && (
				<>
					<p className={styles.description}>
						When using an Xpub key, you will provide us with your key and we will generate multiple
						addresses for customers.
					</p>
					<p className={styles.description}>
						With Xpub, payments will no longer fail and will support over/underpaid transactions.
					</p>
				</>
			)}

			{isXPubAvailable && (
				<WarningLink
					href={'https://support.hoodpay.io/en/collections/4027601-payment-integrations'}
					text={'View detailed help article for setup'}
				/>
			)}
			{isXPubAvailable && (
				<div className={styles.tabs}>
					<div
						className={`${styles.tab} ${currentMethodTab === 0 && styles.active}`}
						onClick={() => setCurrentMethodTab(0)}
					>
						Direct to address
					</div>
					<div
						className={`${styles.tab} ${currentMethodTab === 1 && styles.active}`}
						onClick={() => setCurrentMethodTab(1)}
					>
						Xpub key (recommended)
					</div>
				</div>
			)}

			{cryptocurrency.networks && (
				<div className={styles.tabs}>
					<div
						className={`${styles.tab} ${currentNetworkTab === 0 && styles.active}`}
						onClick={() => setCurrentNetworkTab(0)}
					>
						TRC-20 <img src={tronIcon} alt='tron' />
					</div>
					<div
						className={`${styles.tab} ${currentNetworkTab === 1 && styles.active}`}
						onClick={() => setCurrentNetworkTab(1)}
					>
						ERC-20 <img src={ethereumIcon} alt='ethereum' />
					</div>
				</div>
			)}

			{currentMethodTab === 1 && (
				<div className={styles.radios}>
					<label className={styles.radioWrapper}>
						<Radio
							id={ScriptPubKeyType.SegwitP2SH}
							value={ScriptPubKeyType.SegwitP2SH}
							setValue={setKeyType}
							checked={keyType === ScriptPubKeyType.SegwitP2SH}
						/>
						<span>Segwit</span>
					</label>
					<label className={styles.radioWrapper}>
						<Radio
							id={ScriptPubKeyType.Segwit}
							value={ScriptPubKeyType.Segwit}
							setValue={setKeyType}
							checked={keyType === ScriptPubKeyType.Segwit}
						/>
						<span>Native Segwit</span>
					</label>
					<label className={styles.radioWrapper}>
						<Radio
							id={ScriptPubKeyType.Legacy}
							value={ScriptPubKeyType.Legacy}
							setValue={setKeyType}
							checked={keyType === ScriptPubKeyType.Legacy}
						/>
						<span>Legacy</span>
					</label>
				</div>
			)}

			{currentMethodTab === 0 && (
				<div className={styles.fieldWrapper}>
					<h2>{getPaymentMethodName(cryptocurrency.cryptocurrency)} ADDRESS</h2>
					<Input
						value={address}
						setValue={setAddress}
						placeholder={`Enter your ${getPaymentMethodName(cryptocurrency.cryptocurrency)}${
							cryptocurrency.networks ? (currentNetworkTab === 1 ? ':ERC-20' : ':TRC-20') : ''
						} address`}
					/>
				</div>
			)}

			{currentMethodTab === 1 && (
				<div className={styles.fieldWrapper}>
					<h2>{getPaymentMethodName(cryptocurrency.cryptocurrency)} EXTENDED PUBLIC KEY</h2>
					<Input value={key} setValue={setKey} placeholder={'xpub6-*********************'} />
				</div>
			)}

			<div className={styles.fieldWrapper}>
				<header>
					<div>
						<h2>ENABLE PAYMENTS</h2>
						<p className={styles.p}>Once you enable this, we will add this to checkout.</p>
					</div>
					<Switch checked={true} setChecked={() => {}} />
				</header>
			</div>
			{/*<div className={styles.fieldWrapper}>*/}
			{/*	<header>*/}
			{/*		<div>*/}
			{/*			<h2>BLOCK VPNS</h2>*/}
			{/*			<p className={styles.p}>Restrict payments from users using a VPN/Proxy.</p>*/}
			{/*		</div>*/}
			{/*		<Switch*/}
			{/*			checked={blockVPNS[currentMethodTab]}*/}
			{/*			setChecked={checked =>*/}
			{/*				setBlockVPNS({*/}
			{/*					...blockVPNS,*/}
			{/*					[currentMethodTab]: checked,*/}
			{/*				})*/}
			{/*			}*/}
			{/*		/>*/}
			{/*	</header>*/}
			{/*</div>*/}

			<div className={styles.fieldWrapper}>
				<header>
					<div>
						<h2>CONFIRMATIONS REQUIRED</h2>
						<p className={styles.p}>Set the confirmations to process the payment.</p>
					</div>
				</header>
				{confirmations[currentMethodTab] === 0 && (
					<div className={styles.warning}>
						<h3>Note</h3>
						<p>We suggest a minimum of 1 confirmation to avoid double-spending vulnerabilities</p>
					</div>
				)}
				<div className={styles.sliderWrapper}>
					<Slider
						value={confirmations[currentMethodTab]}
						setValue={value =>
							setConfirmations({
								...confirmations,
								[currentMethodTab]: value,
							})
						}
						max={isXPubAvailable ? 6 : 12}
					/>
					<div className={styles.sliderDots}>
						<span>0</span>
						<span>6</span>
						{!isXPubAvailable && <span>12</span>}
					</div>
				</div>
			</div>
			{isXPubAvailable && currentMethodTab === 1 && (
				<div className={styles.fieldWrapper}>
					<header>
						<div>
							<h2>Underpayment Threshold</h2>
							<p className={styles.p}>Set 5% if you are willing to accept a $95 for a $100 item.</p>
						</div>
						<Switch
							checked={enableUnderpaymentThreshold}
							setChecked={setEnableUnderpaymentThreshold}
						/>
					</header>
					{enableUnderpaymentThreshold && currentMethodTab === 1 && (
						<div className={styles.sliderWrapper}>
							<Slider
								value={underpaymentThreshold}
								setValue={setUnderpaymentThreshold}
								max={15}
								valueSymbol={'%'}
							/>
							<div className={styles.sliderDots}>
								<span>0%</span>
								<span>7%</span>
								<span>15%</span>
							</div>
						</div>
					)}
				</div>
			)}

			<footer className={styles.footer}>
				<Button
					style={ButtonStyles.SECONDARY}
					isLoading={isUpdateDirectCryptoLoading || isUpdateXPubCryptoLoading}
					onClick={onSaveHandler}
				>
					Save
				</Button>
				{/*<p className={styles.description}>*/}
				{/*	Hoodpay will not reimburse payments that are (a) disputed or (b) refunded by PayPal.*/}
				{/*</p>*/}
			</footer>
		</div>
	)
}

export default CryptocurrencyModal
