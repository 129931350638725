import React, { useState } from 'react'
import styles from './Tables.module.scss'
import bitcoinIcon from '../../images/BitcoinSvg.svg'
import ETHBadge from '../UI/ETHBadge/ETHBadge'
import Input from '../UI/FormComponents/Input'
import TrashCanSVG from '../../images/TrashCanSVG'
import Button, { ButtonStyles } from '../UI/Button/Button'
import litecoinIcon from '../../images/LitecoinSvg.svg'
import moneroIcon from '../../images/Monero.svg'
import DesktopSVG from '../../images/DesktopSVG'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import ActionMenu from '../UI/ActionMenu/ActionMenu'

const TableBody = () => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	return (
		<div className={styles.tableBody}>
			<div className={`${styles.tableBodyItem}`}>
				<div className={styles.nameWrapper}>
					<DesktopSVG />
					<h3>
						Chrome, <span>Windows</span>
					</h3>
				</div>
			</div>
			<div className={`${styles.tableBodyItem} ${styles.full}`}>
				<p className={styles.small}>Los Angeles, California</p>
			</div>
			<div className={`${styles.tableBodyItem}`}>
				<div className={styles.dateWrapper}>Mar 16, 2023</div>
			</div>
			<div className={`${styles.tableBodyItem}`}>
				<div className={styles.dateWrapper}>Password</div>
			</div>
			<div className={`${styles.tableBodyItem} ${styles.settings}`}>
				<div className={styles.actionMenu}>
					<SettingsButton onClick={() => setIsActionMenuActive(!isActionMenuActive)} />
					<ActionMenu
						actionButtons={[
							{
								title: 'Delete',
								onClick: () => {},
								icon: <TrashCanSVG />,
							},
						]}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</div>
			</div>
		</div>
	)
}

const DevicesTable = () => {
	return (
		<div className={styles.table}>
			<div className={styles.tableHeader}>
				<h3>DEVICE</h3>
				<h3 className={styles.full}>LOCATION</h3>
				<h3>LAST LOGIN</h3>
				<h3>METHOD</h3>
				<h3 className={styles.settings}></h3>
			</div>
			<TableBody />
			<TableBody />
		</div>
	)
}

export default DevicesTable
