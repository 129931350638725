import React from 'react'

const GavelSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.43567 8.40283L15.8864 15.8535M5.35263 4.29211L8.43567 7.37515M6.38031 10.7151L2.01267 6.34747L3.29727 5.06287L3.81111 5.57671L6.38031 3.00751L5.86647 2.49367L7.40799 0.952148L11.7756 5.31979L10.491 6.60439L9.97719 6.09055L7.15107 8.91667L7.66491 9.43051L6.38031 10.7151ZM0.984985 15.3397V15.8535H9.72027V15.3397C9.72027 14.2045 8.80008 13.2843 7.66491 13.2843H3.04035C1.9052 13.2843 0.984985 14.2045 0.984985 15.3397Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default GavelSvg
