import React, { FC, useState } from 'react'
import styles from './Note.module.scss'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import PenIcon from '../../images/PenIcon'
import TrashCanSVG from '../../images/TrashCanSVG'

interface NoteProps {
	deleteNote: () => void
	editNote: () => void
	note: string
	secondStyle?: boolean
}
const Note: FC<NoteProps> = ({ deleteNote, editNote, note, secondStyle }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	return (
		<div className={`${styles.note} ${secondStyle ? styles.secondStyle : ''}`}>
			<div>
				<h3>{note}</h3>
			</div>
			<div className={styles.actionMenuWrapper}>
				<SettingsButton
					onClick={event => {
						event.stopPropagation()
						setIsActionMenuActive(!isActionMenuActive)
					}}
				/>
				<ActionMenu
					actionButtons={[
						{
							icon: <PenIcon />,
							onClick: editNote,
							title: 'Edit note',
						},
						{
							icon: <TrashCanSVG />,
							onClick: deleteNote,
							title: 'Delete note',
						},
					]}
					isActive={isActionMenuActive}
					setIsActive={setIsActionMenuActive}
				/>
			</div>
		</div>
	)
}

export default Note
