import React, { FC, useEffect, useState } from 'react'
import SuccessStatusIcon from '../../../images/SuccessStatusIcon'
import styles from './StatusBadge.module.scss'
import { Status, Statuses } from '../../../types/Status'
import CircleInfoIcon from '../../../images/CircleInfoIcon'
import ConfirmingStatusIcon from '../../../images/ConfirmingStatusIcon'
import WarningIcon from '../../../images/WarningIcon'
import {
	WebhookMessageEventStatus,
	WebhookMessageEventStatusEnum,
} from '../../../types/WebhookMessageEventStatus'
import XSVG from '../../../images/XSVG'
import CheckedSvg from '../../../images/CheckedSVG'
import Checked2SVG from '../../../images/Checked2SVG'

interface StatusBadgeProps {
	statusType: Status | WebhookMessageEventStatus
}

const StatusBadge: FC<StatusBadgeProps> = ({ statusType }) => {
	const statuses = {
		[Statuses.NEW]: {
			icon: <CircleInfoIcon />,
			label: 'New',
			class: 'gray',
		},
		[Statuses.SUCCESS]: {
			icon: <CheckedSvg />,
			label: 'Success',
			class: 'green',
		},
		[Statuses.CONFIRMING]: {
			icon: <ConfirmingStatusIcon />,
			label: 'Confirming',
			class: 'blue',
		},
		[Statuses.EXPIRED]: {
			icon: <XSVG />,
			label: 'Expired',
			class: 'red',
		},
		[Statuses.PENDING]: {
			icon: <XSVG />,
			label: 'Pending',
			class: 'gray',
		},
		[Statuses.FAILED]: {
			icon: <XSVG />,
			label: 'Failed',
			class: 'red',
		},
		[Statuses.CANCELLED]: {
			icon: <XSVG />,
			label: 'Cancelled',
			class: 'red',
		},
		[Statuses.AWAITING_PAYMENT]: {
			icon: <ConfirmingStatusIcon />,
			label: 'Awaiting',
			class: 'blue',
		},
		[Statuses.UNDERPAID]: {
			icon: <XSVG />,
			label: 'Underpaid',
			class: 'orange',
		},
		[WebhookMessageEventStatusEnum.SENDING]: {
			icon: <CircleInfoIcon />,
			label: 'Sending',
			class: 'blue',
		},
		[WebhookMessageEventStatusEnum.PENDING]: {
			icon: <CircleInfoIcon />,
			label: 'Pending',
			class: 'blue',
		},
		[WebhookMessageEventStatusEnum.SUCCESS]: {
			icon: <Checked2SVG />,
			label: 'Success',
			class: 'green',
		},
		[WebhookMessageEventStatusEnum.FAIL]: {
			icon: <XSVG />,
			label: 'Fail',
			class: 'red',
		},
	}

	return (
		<div className={`${styles.statusBadge} ${styles[statuses[statusType].class]}`}>
			<span>{statuses[statusType].label}</span>
			{statuses[statusType].icon}
		</div>
	)
}

export default StatusBadge
