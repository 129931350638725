import React, { useEffect, useState } from 'react'
import Container from '../UI/Container/Container'
import styles from './Header.module.scss'
import SearchInput from '../UI/SearchInput/SearchInput'
import Profile from '../Profile/Profile'
import ContactMessageIcon from '../../images/ContactMessageIcon'
import NotificationIcon from '../../images/NotificationIcon'
import ProfileIcon from '../../images/ProfileIcon'
import ThemeChanger from '../UI/ThemeChanger/ThemeChanger'
import GlobalSearch from '../GlobalSearch/GlobalSearch'

const Header = () => {
	const [searchValue, setSearchValue] = useState('')
	const [isProfileActive, setIsProfileActive] = useState(false)

	return (
		<header className={styles.header}>
			<Container>
				<div className={styles.headerInner}>
					<div className={styles.headerLeftSide}>
						<GlobalSearch />
					</div>
					<div className={styles.headerRightSide}>
						<div className={styles.iconWrapper}>
							<ThemeChanger />
						</div>
						<div
							className={styles.iconWrapper}
							onClick={() =>
								//@ts-ignore
								window.Intercom('show')
							}
						>
							<ContactMessageIcon />
						</div>
						{/*<NotificationIcon />*/}
						<div className={styles.profileWrapper}>
							<div className={styles.iconWrapper} onClick={() => setIsProfileActive(true)}>
								<ProfileIcon />
							</div>
							<Profile isActive={isProfileActive} setIsActive={setIsProfileActive} />
						</div>
					</div>
				</div>
			</Container>
		</header>
	)
}

export default Header
