import React from 'react'

const HoodpayLogo = () => {
	return (
		<svg width='17' height='27' viewBox='0 0 17 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.34149 5.28344L9.59368 22.1345L1.91797 21.6809L0.665771 4.82983L8.34149 5.28344Z'
				fill='#1F1F1F'
			/>
			<path
				d='M12.8059 9.49001L14.0581 26.341L6.38242 25.8874L5.13022 9.0364L12.8059 9.49001Z'
				fill='#1F1F1F'
			/>
			<path
				d='M15.2104 1.29126L16.4626 18.1423L8.78689 17.6887L7.53469 0.837646L15.2104 1.29126Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default HoodpayLogo
