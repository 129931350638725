import React from 'react'

const HoodpayFeeWarningIcon = () => {
	return (
		<svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.88159 0.347229C4.93456 0.347229 0.90332 4.37847 0.90332 9.3255C0.90332 14.2725 4.93456 18.3038 9.88159 18.3038C14.8286 18.3038 18.8599 14.2725 18.8599 9.3255C18.8599 4.37847 14.8286 0.347229 9.88159 0.347229ZM9.20822 5.73419C9.20822 5.36608 9.51348 5.06082 9.88159 5.06082C10.2497 5.06082 10.555 5.36608 10.555 5.73419V10.2233C10.555 10.5914 10.2497 10.8967 9.88159 10.8967C9.51348 10.8967 9.20822 10.5914 9.20822 10.2233V5.73419ZM10.7076 13.258C10.6627 13.3747 10.5999 13.4645 10.519 13.5543C10.4293 13.6351 10.3305 13.6979 10.2228 13.7428C10.115 13.7877 9.99831 13.8146 9.88159 13.8146C9.76487 13.8146 9.64816 13.7877 9.54042 13.7428C9.43268 13.6979 9.33392 13.6351 9.24413 13.5543C9.16333 13.4645 9.10048 13.3747 9.05559 13.258C9.00947 13.1501 8.98507 13.0341 8.98376 12.9168C8.98376 12.8001 9.0107 12.6834 9.05559 12.5756C9.10048 12.4679 9.16333 12.3691 9.24413 12.2794C9.33392 12.1985 9.43268 12.1357 9.54042 12.0908C9.759 12.001 10.0042 12.001 10.2228 12.0908C10.3305 12.1357 10.4293 12.1985 10.519 12.2794C10.5999 12.3691 10.6627 12.4679 10.7076 12.5756C10.7525 12.6834 10.7794 12.8001 10.7794 12.9168C10.7794 13.0335 10.7525 13.1502 10.7076 13.258Z'
				fill='#FFE600'
			/>
		</svg>
	)
}

export default HoodpayFeeWarningIcon
