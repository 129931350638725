import React, { FC, useEffect, useState } from 'react'
import styles from './CustomersTable.module.scss'
import SearchInput, { SearchInputStyles } from '../UI/SearchInput/SearchInput'
import CustomersTableBody from './CustomersTableBody'
import { useAPI } from '../../hooks/useAPI'
import Spinner from '../UI/Spinner/Spinner'
import Pagination from '../UI/Pagination/Pagination'
import StatisticsSVG from '../../images/StatisticsSVG'
import { BusinessesCustomersService } from '../../API/BusinessesCustomersService'
import { Customer } from '../../types/Customer'
import DollarSvg from '../../images/DollarSVG'
import CrownSvg from '../../images/CrownSVG'
import RefreshIcon from '../../images/RefreshIcon'
import GavelSVG from '../../images/GavelSVG'
import PeopleIcon from '../../images/PeopleIcon'

interface PaymentsTableProps {
	businessId?: number
	setWasCustomerCreated: (value: boolean) => void
	wasCustomerCreated: boolean
	setIsCustomerViewModalActive: (value: boolean) => void
	setSearchParams: (value: URLSearchParams) => void
	searchParams: URLSearchParams
}

const CustomersTable: FC<PaymentsTableProps> = ({
	businessId,
	wasCustomerCreated,
	setWasCustomerCreated,
	setIsCustomerViewModalActive,
	setSearchParams,
	searchParams,
}) => {
	const [customersFilter, setCustomersFilter] = useState<string | null>(null)
	const [searchValue, setSearchValue] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [totalItems, setTotalItems] = useState(0)
	const [pageSize, setPageSize] = useState(10)

	const [isCustomersLoading, customers, getCustomers] = useAPI<Customer[]>(
		() =>
			BusinessesCustomersService.getCustomers(
				businessId,
				currentPage + 1,
				pageSize,
				customersFilter,
				searchValue
			),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	useEffect(() => {
		if (businessId) {
			getCustomers()
		}
	}, [businessId, currentPage, pageSize, searchValue, customersFilter])

	useEffect(() => {
		if (wasCustomerCreated) {
			getCustomers()
			setWasCustomerCreated(false)
		}
	}, [wasCustomerCreated])

	if (!customers?.length && !isCustomersLoading) {
		return (
			<div className={styles.noCustomers}>
				<PeopleIcon />
				<h2>No customers</h2>
				<p>You have no customers that processed payments with Hoodpay.</p>
			</div>
		)
	}

	return (
		<>
			<div className={styles.CustomersTable}>
				<div className={styles.tableFilters}>
					<button
						className={customersFilter === null ? styles.active : ''}
						onClick={() => setCustomersFilter(null)}
					>
						<DollarSvg /> All customers
					</button>
					<button
						className={customersFilter === 'TopCustomers' ? styles.active : ''}
						onClick={() => setCustomersFilter('TopCustomers')}
					>
						<CrownSvg />
						Top customers
					</button>
					<button
						className={customersFilter === 'RepeatCustomers' ? styles.active : ''}
						onClick={() => setCustomersFilter('RepeatCustomers')}
					>
						<RefreshIcon />
						Repeat customers
					</button>
					<button
						className={customersFilter === 'FirstTimeCustomers' ? styles.active : ''}
						onClick={() => setCustomersFilter('FirstTimeCustomers')}
					>
						<GavelSVG />
						First-time customers
					</button>
					<button
						className={customersFilter === 'RecentCustomers' ? styles.active : ''}
						onClick={() => setCustomersFilter('RecentCustomers')}
					>
						<GavelSVG />
						Recent customers
					</button>
					<SearchInput
						style={SearchInputStyles.secondary}
						value={searchValue}
						setValue={setSearchValue}
						customClass={styles.searchInput}
						placeholder={'Search for a payment'}
					/>
				</div>

				{isCustomersLoading && <Spinner />}
				{customers?.length && !isCustomersLoading ? (
					<div className={styles.table}>
						<div className={styles.tableHeader}>
							<h3 className={styles.medium}>Customer</h3>
							<h3 className={styles.medium}>Total Spend</h3>
							<h3 className={styles.medium}>Total Payments</h3>
							<h3 className={styles.medium}>First Seen</h3>
							<h3 className={styles.settings} />
						</div>
						{customers.map(customer => (
							<CustomersTableBody
								searchParams={searchParams}
								setSearchParams={setSearchParams}
								customer={customer}
								key={customer.id}
							/>
						))}
					</div>
				) : null}

				{customers?.length && !isCustomersLoading ? (
					<Pagination
						totalItems={totalItems}
						setCurrentPage={setCurrentPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						currentPage={currentPage}
					/>
				) : null}
			</div>
		</>
	)
}

export default CustomersTable
