import React from 'react'

const BanknoteSvg = () => {
	return (
		<svg width='34' height='25' viewBox='0 0 34 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.02377 17.2175C5.03961 17.2175 7.48444 19.6623 7.48444 22.6781M31.5114 17.2175C28.4956 17.2175 26.0507 19.6623 26.0507 22.6781M2.02377 7.38825C5.03961 7.38825 7.48444 4.94342 7.48444 1.92758M31.5114 7.38825C28.4956 7.38825 26.0507 4.94342 26.0507 1.92758M28.235 0.835449H5.30018C2.8875 0.835449 0.931641 2.79131 0.931641 5.20398V19.4017C0.931641 21.8145 2.8875 23.7703 5.30018 23.7703H28.235C30.6477 23.7703 32.6035 21.8145 32.6035 19.4017V5.20398C32.6035 2.79131 30.6477 0.835449 28.235 0.835449ZM21.6822 12.3029C21.6822 16.2234 19.4817 19.4017 16.7676 19.4017C14.0534 19.4017 11.853 16.2234 11.853 12.3029C11.853 8.38231 14.0534 5.20398 16.7676 5.20398C19.4817 5.20398 21.6822 8.38231 21.6822 12.3029Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default BanknoteSvg
