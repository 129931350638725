import React from 'react'

const CryptocurrencySvg = () => {
	return (
		<svg width='13' height='17' viewBox='0 0 13 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.98517 3.26758V8.51758M2.98517 3.26758H8.60067C11.4541 3.26758 12.5278 7.00208 10.1102 8.51758M2.98517 3.26758H0.985168M2.98517 8.51758V13.7676M2.98517 8.51758H10.1102M10.1102 8.51758C12.5278 10.0331 11.4541 13.7676 8.60067 13.7676H2.98517M2.98517 13.7676H0.985168M4.98517 3.01758V1.26758M8.48517 3.01758V1.26758M8.48517 15.7676V14.2676M4.98517 15.7676V14.2676'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CryptocurrencySvg
