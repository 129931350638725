import React from 'react'

const PeopleIcon = () => {
	return (
		<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.4093 16.3103C22.926 16.3103 24.3663 19.8655 24.9477 22.4279C25.2617 23.8116 24.1178 25 22.669 25H21.101M17.7175 10.1034C20.2869 10.1034 21.9468 8.06557 21.9468 5.55172C21.9468 3.03788 20.2869 1 17.7175 1M2.54575 25H15.1264C16.0813 25 16.8328 24.2249 16.6425 23.3092C16.1172 20.7813 14.4013 16.3103 8.83607 16.3103C3.27086 16.3103 1.55503 20.7813 1.02965 23.3092C0.839352 24.2249 1.59078 25 2.54575 25ZM13.4883 5.55172C13.4883 8.06557 11.4054 10.1034 8.83607 10.1034C6.26673 10.1034 4.18386 8.06557 4.18386 5.55172C4.18386 3.03788 6.26673 1 8.83607 1C11.4054 1 13.4883 3.03788 13.4883 5.55172Z'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PeopleIcon
