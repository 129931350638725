import React, { FC, useEffect, useState } from 'react'
import styles from './Modals.module.scss'
import Button, { ButtonStyles } from '../UI/Button/Button'

import { useAPI } from '../../hooks/useAPI'
import { UserSecurityService } from '../../API/UserSecurityService'
import InputMask from 'react-input-mask'
import formComponentsStyles from '../UI/FormComponents/FormComponents.module.scss'
import Spinner from '../UI/Spinner/Spinner'
import Input from '../UI/FormComponents/Input'
import DownloadSVG from '../../images/DownloadSVG'

interface GoogleTfaSideModalProps {
	closeModal: () => void
	isEnabled: boolean
	getUser: () => void
}

const GoogleTFAModal: FC<GoogleTfaSideModalProps> = ({ closeModal, isEnabled, getUser }) => {
	const [step, setStep] = useState(0)
	const [code, setCode] = useState('')
	const [isGoogleTFAStartLoading, TFAData, googleTFAStart] = useAPI<{
		entryCode: string
		key: string
		qrImage: string
	}>(() => UserSecurityService.googleTFAStart(), false)
	const [isGoogleTFACompleteLoading, , googleTFAComplete] = useAPI(
		() => UserSecurityService.googleTFAComplete(TFAData?.key, code.replace('-', '')),
		false,
		'TFA successfully enabled',
		{
			onSuccess: () => {
				getUser()
				closeModal()
			},
		}
	)
	const [isGoogleTFADisableLoading, , googleTFADisable] = useAPI(
		() => UserSecurityService.googleTFADisable(code.replace('-', '')),
		false,
		'TFA successfully disabled',
		{
			onSuccess: () => {
				getUser()
				closeModal()
			},
		}
	)

	const nextButtonHandler = () => {
		if (step === 0) {
			setStep(1)
		} else if (!isEnabled) {
			googleTFAComplete()
		} else {
			googleTFADisable()
		}
	}

	useEffect(() => {
		if (isEnabled) {
			setStep(1)
		} else {
			googleTFAStart()
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			{isGoogleTFAStartLoading && <Spinner />}
			<h1 className={styles.h1}>{isEnabled ? 'Disable' : 'Enable'} Two Factor Authentication</h1>

			{step === 0 && (
				<>
					<p className={styles.description}>
						Scan the QR code below with the Google Authenticator to secure your payments account.
					</p>
					<img className={styles.tfaQr} src={TFAData?.qrImage} alt='TFA QR' />
				</>
			)}
			{step === 1 && (
				<>
					{!isEnabled && (
						<div className={styles.fieldWrapper}>
							<h3>Recovery Code</h3>
							<div className={styles.disabledImportantInput}>
								<Input value={TFAData?.key || ''} setValue={() => {}} disabled={true} />
								<DownloadSVG />
							</div>
							<p className={styles.smallText}>
								We recommend downloading this code. Hoodpay won’t be able to assist you if you lose
								your two-factor authenticator.
							</p>
						</div>
					)}

					<div className={styles.fieldWrapper}>
						<h3>6-DIGIT CODE</h3>
						<InputMask
							className={formComponentsStyles.input}
							value={code}
							onChange={event => setCode(event.target.value)}
							placeholder={'000-000'}
							mask={'999-999'}
						/>
						<p className={styles.smallText}>
							To {isEnabled ? 'remove' : 'finalize'} two-factor authentication, please add the
							6-digit code from the Google Authenticator app above.
						</p>
					</div>
				</>
			)}

			<div className={styles.button}>
				<Button
					style={ButtonStyles.SECONDARY}
					onClick={nextButtonHandler}
					isLoading={isGoogleTFACompleteLoading || isGoogleTFADisableLoading}
				>
					{step === 0 ? 'Next' : isEnabled ? 'Disable' : 'Enable'}
				</Button>
			</div>
		</div>
	)
}

export default GoogleTFAModal
