import { privateAPI } from './API'

export const UserSettingsService = {
	updateEmail: async (email?: string) => {
		return await privateAPI.post(`/v1/dash/user/settings/email`, {
			email,
		})
	},
	connectTelegram: async () => {
		return await privateAPI.post(`/v1/dash/user/settings/telegram`)
	},
}
