import React, { ChangeEvent, FC } from 'react'
import styles from './FormComponents.module.scss'

interface InputProps {
	value: string | number
	setValue: (value: string) => void
	placeholder?: string
	type?: string
	required?: boolean
	pattern?: string
	isError?: boolean
	errorMessage?: string
	autoComplete?: string
	disabled?: boolean
}

const Input: FC<InputProps> = ({
	value,
	setValue,
	placeholder,
	type,
	pattern,
	required,
	errorMessage,
	isError,
	autoComplete,
	disabled,
}) => {
	return (
		<div className={styles.inputWrapper}>
			<input
				className={`${styles.input} ${isError ? styles.error : ''}`}
				placeholder={placeholder}
				value={value}
				type={type}
				onChange={event => setValue(event.target.value)}
				pattern={pattern}
				required={required}
				autoComplete={autoComplete}
				disabled={disabled}
			/>
			{isError && <p className={styles.errorMessage}>{errorMessage || 'Required'}</p>}
		</div>
	)
}

export default Input
