import React, { useEffect, useState } from 'react'
import Container from '../../components/UI/Container/Container'
import styles from './PaymentDetailPage.module.scss'
import WebhookTable from '../../components/Tables/WebhookTable'
import PaymentMainDetails from '../../components/PaymentMainDetails/PaymentMainDetails'
import PaymentMainTable from '../../components/PaymentMainTable/PaymentMainTable'
import PaymentTimelineSection from '../../components/PaymentTimelineSection/PaymentTimelineSection'
import PaymentNotes from '../../components/PaymentNotes/PaymentNotes'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { PaymentsService } from '../../API/PaymentsService'
import Spinner from '../../components/UI/Spinner/Spinner'
import { PaymentDetailed } from '../../types/PaymentDetailed'
import MetaDataIcon from '../../images/MetaDataIcon'
import { Webhook } from '../../types/Webhook'
import { PaymentWebhooksService } from '../../API/PaymentWebhooksService'
import WebhooksIcon from '../../images/WebhooksIcon'
import CopyIcon from '../../images/CopyIcon'
import { useCopy } from '../../hooks/useCopy'
import WarningMessage from '../../components/UI/WarningMessage/WarningMessage'
import { Warnings } from '../../types/Warning'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import getSymbolFromCurrency from 'currency-symbol-map'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { getCryptoMethodName } from '../../utils/getCryptoMethodName'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { Cryptocurrencies } from '../../types/Cryptocurrencies'

const PaymentDetailPage = () => {
	const params = useParams()
	const paymentId = params.id
	const { business } = useAppContext()
	const copyToClipboard = useCopy()
	const [isPaymentLoading, payment, getPayment] = useAPI<PaymentDetailed>(
		() => PaymentsService.get(business?.id, paymentId),
		false
	)
	const [isWebhooksLoading, webhooks, getWebhooks] = useAPI<Webhook[]>(
		() => PaymentWebhooksService.getWebhookLogs(business?.id, paymentId),
		false
	)
	const cryptoCharge =
		payment?.xPubCryptoCharge || payment?.directCryptoCharge || payment?.onRampCryptoCharge
	const currencySymbol = getSymbolFromCurrency(payment?.currency || 'USD')

	const openTransactionLinkHandler = () => {
		let link: string = ``
		const transactionId = cryptoCharge?.transaction?.id

		if (cryptoCharge?.coinName.includes('ETH')) {
			link = `https://etherscan.io/tx/${transactionId}`
		} else if (cryptoCharge?.coinName === Cryptocurrencies.BITCOIN) {
			link = `https://mempool.space/tx/${transactionId}`
		} else if (cryptoCharge?.coinName === Cryptocurrencies.LITECOIN) {
			link = `https://live.blockcypher.com/ltc/tx/${transactionId}`
		}
		window.open(link, '_blank', 'noopener')
	}

	useEffect(() => {
		if (business?.id) {
			getPayment()
			getWebhooks()
		}
	}, [business])

	if (isPaymentLoading) return <Spinner />

	return (
		<PageWrapper>
			<Container>
				<div className={styles.paymentDetailPage}>
					{payment?.onBehalfOfBusinessId === 1 && (
						<WarningMessage warningType={Warnings.HOODPAY_FEE} />
					)}

					<PaymentMainDetails payment={payment} />
					<PaymentMainTable payment={payment} />

					<div className={styles.paymentDetailsGrid}>
						<PaymentTimelineSection
							timeline={payment?.timeline}
							getPayment={getPayment}
							cryptoCharge={payment?.directCryptoCharge || payment?.xPubCryptoCharge}
							openTransactionLinkHandler={openTransactionLinkHandler}
						/>
						<PaymentNotes
							paymentId={paymentId}
							businessId={business?.id}
							note={payment?.note}
							getPayment={getPayment}
						/>
					</div>

					<div className={styles.paymentDetailsGrid}>
						<div className={styles.paymentDetailsGridColumn}>
							<div className={styles.paymentDetailsSection}>
								<header>
									<h2>Payment details</h2>
								</header>
								<table className={styles.detailsTable}>
									<tbody>
										<tr>
											<td>
												<h3>Amount</h3>
											</td>
											<td>
												<p>
													{currencySymbol}
													{payment?.endAmount.toFixed(2)}
												</p>
											</td>
										</tr>
										<tr>
											<td>
												<h3>Fee</h3>
											</td>
											<td>
												<p>
													{currencySymbol}
													{payment?.hoodpayFee?.toFixed(2)}
												</p>
											</td>
										</tr>
										<tr>
											<td>
												<h3>Net</h3>
											</td>
											<td>
												<p>
													{currencySymbol}
													{/*@ts-ignore*/}
													{(payment?.endAmount - payment?.hoodpayFee).toFixed(2)}
												</p>
											</td>
										</tr>
										<tr>
											<td>
												<h3>Description</h3>
											</td>
											<td>
												<p>{payment?.description || '-'}</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							{payment?.metadata && (
								<div className={styles.paymentDetailsSection}>
									<header>
										<h2>Metadata</h2>
									</header>
									<table className={styles.detailsTable}>
										<tbody>
											{Object.keys(payment?.metadata).map(key => (
												<tr>
													<td>
														<h3>{key}</h3>
													</td>
													<td>
														<p>{payment?.metadata && payment?.metadata[key]}</p>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>
						<div className={styles.paymentDetailsGridColumn}>
							{cryptoCharge && (
								<div className={styles.paymentDetailsSection}>
									<header>
										<h2>Payment method: {getCryptoMethodName(payment?.selectedPaymentMethod)}</h2>
									</header>
									<table className={styles.detailsTable}>
										<tbody>
											<tr>
												<td>
													<h3>Amount Requested</h3>
												</td>
												<td>
													<p>
														{cryptoCharge.amount} ({currencySymbol}
														{payment?.prePaymentAmount?.toFixed(2)})
													</p>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Amount Received</h3>
												</td>
												<td>
													<p>
														{cryptoCharge.transaction?.amount
															? `${
																	cryptoCharge.transaction?.amount
															  } (${currencySymbol}${payment?.endAmount?.toFixed(2)})`
															: '-'}
													</p>
												</td>
											</tr>
											<tr>
												<td>
													<h3>TxId / Transaction Hash</h3>
												</td>
												<td>
													<p className={styles.link} onClick={openTransactionLinkHandler}>
														{cryptoCharge.transaction?.id || '-'}
													</p>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Exchange Rate</h3>
												</td>
												<td>
													<p>
														$
														{cryptoCharge &&
															payment &&
															(payment?.endAmount / cryptoCharge.amount).toFixed(2)}{' '}
														per {getCryptoShortName(cryptoCharge?.coinName)}
													</p>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Network</h3>
												</td>
												<td>
													<p>{cryptoCharge.coinName || '-'}</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}

							{/*	<div className={styles.paymentDetailsSection}>*/}
							{/*		<header>*/}
							{/*			<h2>Checkout details</h2>*/}
							{/*		</header>*/}
							{/*		<div className={styles.detailsWrapper}>*/}
							{/*			<div className={styles.detailTitles}>*/}
							{/*				<p>IP address</p>*/}
							{/*				<p>ISP</p>*/}
							{/*				<p>Location</p>*/}
							{/*				<p>Operating system</p>*/}
							{/*				<p>Browser</p>*/}
							{/*			</div>*/}
							{/*			<div className={styles.detailValues}>*/}
							{/*				<p>73.18.235.218</p>*/}
							{/*				<p>Comcast Cable</p>*/}
							{/*				<p>Chicago, United States</p>*/}
							{/*				<p>Windows</p>*/}
							{/*				<p>Chrome</p>*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*	<MapContainer*/}
							{/*		className={styles.mapContainer}*/}
							{/*		center={[51.505, -0.09]}*/}
							{/*		zoom={13}*/}
							{/*		scrollWheelZoom={false}*/}
							{/*	>*/}
							{/*		<TileLayer*/}
							{/*			attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'*/}
							{/*			url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'*/}
							{/*		/>*/}
							{/*		<Marker position={[51.505, -0.09]}>*/}
							{/*			<Popup>*/}
							{/*				A pretty CSS3 popup. <br /> Easily customizable.*/}
							{/*			</Popup>*/}
							{/*		</Marker>*/}
							{/*	</MapContainer>*/}
							{/*	/!*<div className={styles.paymentDetailsSection}>*!/*/}
							{/*	/!*	<header>*!/*/}
							{/*	/!*		<h2>Payment method</h2>*!/*/}
							{/*	/!*	</header>*!/*/}
							{/*	/!*	<div className={styles.detailsGrid}>*!/*/}
							{/*	/!*		<div className={styles.detailsWrapper}>*!/*/}
							{/*	/!*			<div className={styles.detailTitles}>*!/*/}
							{/*	/!*				<p>ID</p>*!/*/}
							{/*	/!*				<p>Number</p>*!/*/}
							{/*	/!*				<p>Expires</p>*!/*/}
							{/*	/!*				<p>Type</p>*!/*/}
							{/*	/!*				<p>Issuer</p>*!/*/}
							{/*	/!*			</div>*!/*/}
							{/*	/!*			<div className={styles.detailValues}>*!/*/}
							{/*	/!*				<p>card_1KUxOuKJsUCc6FehTAaWd</p>*!/*/}
							{/*	/!*				<p>card_1KUxOuKJsUCc6FehTAaWd</p>*!/*/}
							{/*	/!*				<p>card_1KUxOuKJsUCc6FehTAaWd</p>*!/*/}
							{/*	/!*				<p>card_1KUxOuKJsUCc6FehTAaWd</p>*!/*/}
							{/*	/!*				<p>card_1KUxOuKJsUCc6FehTAaWd</p>*!/*/}
							{/*	/!*			</div>*!/*/}
							{/*	/!*		</div>*!/*/}
							{/*	/!*		<div className={styles.detailsWrapper}>*!/*/}
							{/*	/!*			<div className={styles.detailTitles}>*!/*/}
							{/*	/!*				<p>Postal code</p>*!/*/}
							{/*	/!*				<p>Origin</p>*!/*/}
							{/*	/!*				<p>CVC check</p>*!/*/}
							{/*	/!*				<p>ZIP check</p>*!/*/}
							{/*	/!*			</div>*!/*/}
							{/*	/!*			<div className={styles.detailValues}>*!/*/}
							{/*	/!*				<p>United States</p>*!/*/}
							{/*	/!*				<p>United States</p>*!/*/}
							{/*	/!*				<p>United States</p>*!/*/}
							{/*	/!*				<p>United States</p>*!/*/}
							{/*	/!*			</div>*!/*/}
							{/*	/!*		</div>*!/*/}
							{/*	/!*	</div>*!/*/}
							{/*	/!*</div>*!/*/}
						</div>
					</div>
					{webhooks?.length ? (
						<div className={styles.paymentDetailsSection}>
							<header>
								<h2>Webhook logs</h2>
							</header>
							<WebhookTable webhooks={webhooks} />
						</div>
					) : null}
				</div>
			</Container>
		</PageWrapper>
	)
}

export default PaymentDetailPage
