import React, { FC, useState } from 'react'
import styles from './PaymentNotes.module.scss'
import Note from '../Note/Note'
import Button from '../UI/Button/Button'
import RefreshIcon from '../../images/RefreshIcon'
import PlusIcon from '../../images/PlusIcon'
import SideModal from '../SideModals/SideModal'
import ConfirmSideModal from '../SideModals/ConfirmSideModal'
import { closeModal } from '../../utils/CloseModal'
import Modal from '../Modals/Modal'
import NoteModal from '../Modals/NoteModal'
import { useAPI } from '../../hooks/useAPI'
import { PaymentsService } from '../../API/PaymentsService'
import NotesIcon from '../../images/NotesIcon'

interface PaymentNotesProps {
	businessId?: number
	paymentId?: string
	note?: string
	getPayment: () => void
}

const PaymentNotes: FC<PaymentNotesProps> = ({ businessId, paymentId, note, getPayment }) => {
	const [isDeleteNoteVisible, setIsDeleteNoteVisible] = useState(false)
	const [isDeleteNoteActive, setIsDeleteNoteActive] = useState(false)
	const [isCreateNoteActive, setIsCreateNoteActive] = useState(false)
	const [isCreateNoteVisible, setIsCreateNoteVisible] = useState(false)
	const [isEditNote, setIsEditNote] = useState(false)
	const [isLoading, , handleNote] = useAPI(
		(value: null | string) => PaymentsService.handleNote(businessId, paymentId, value),
		false,
		`Note successfully ${isEditNote ? 'edited' : 'created'}`,
		{
			onSuccess: () => {
				setIsCreateNoteActive(false)
				setIsDeleteNoteActive(false)
				getPayment()
			},
		}
	)

	return (
		<div className={styles.paymentNotes}>
			{isDeleteNoteActive && (
				<Modal
					closeModal={() => closeModal(setIsDeleteNoteActive, setIsDeleteNoteVisible)}
					visible={isDeleteNoteVisible}
					setVisible={setIsDeleteNoteVisible}
				>
					<ConfirmSideModal
						onConfirm={() => handleNote(null)}
						isLoading={isLoading}
						closeModal={() => closeModal(setIsDeleteNoteActive, setIsDeleteNoteVisible)}
						confirmText={
							<p>
								Are you sure you want to <strong>delete</strong> your note?
							</p>
						}
						title={'Delete note'}
					/>
				</Modal>
			)}
			{isCreateNoteActive && (
				<Modal
					closeModal={() => closeModal(setIsCreateNoteActive, setIsCreateNoteVisible)}
					visible={isCreateNoteVisible}
					setVisible={setIsCreateNoteVisible}
				>
					<NoteModal
						note={note}
						isEdit={isEditNote}
						handleNote={handleNote}
						isLoading={isLoading}
					/>
				</Modal>
			)}

			<Button onClick={() => setIsCreateNoteActive(true)}>
				<div className={styles.buttonInner}>
					<PlusIcon />
					Create Note
				</div>
			</Button>

			<div className={styles.body}>
				{note && (
					<Note
						note={note}
						deleteNote={() => setIsDeleteNoteActive(true)}
						editNote={() => {
							setIsEditNote(true)
							setIsCreateNoteActive(true)
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default PaymentNotes
