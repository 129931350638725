import React, { FC, SVGProps } from 'react'

const GoogleIconMonotone: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='15'
			height='16'
			viewBox='0 0 15 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M14.9856 7.97049C14.9856 7.45347 14.9437 6.93367 14.8543 6.42504H7.68034V9.35385H11.7885C11.618 10.2985 11.0703 11.1341 10.2682 11.665V13.5654H12.7191C14.1584 12.2408 14.9856 10.2845 14.9856 7.97049Z'
				fill='#191919'
			/>
			<path
				d='M7.67977 15.4015C9.73106 15.4015 11.461 14.728 12.7214 13.5654L10.2704 11.665C9.58853 12.1289 8.70821 12.3916 7.68256 12.3916C5.69835 12.3916 4.01595 11.053 3.4123 9.25323H0.883121V11.2123C2.17426 13.7806 4.80405 15.4015 7.67977 15.4015Z'
				fill='#191919'
			/>
			<path
				d='M3.40982 9.25321C3.09123 8.30861 3.09123 7.28576 3.40982 6.34116V4.38209H0.883433C-0.195311 6.53119 -0.195624 9.06319 0.883121 11.2123L3.40982 9.25321Z'
				fill='#191919'
			/>
			<path
				d='M7.67977 3.19995C8.7641 3.18318 9.81211 3.59121 10.5974 4.34018L12.7689 2.16872C11.3939 0.877578 9.56897 0.16773 7.67977 0.190088C4.80405 0.190088 2.17457 1.81099 0.883433 4.38209L3.40982 6.34116C4.01067 4.53859 5.69555 3.19995 7.67977 3.19995Z'
				fill='#191919'
			/>
		</svg>
	)
}

export default GoogleIconMonotone
