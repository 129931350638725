import React, { FC, useState } from 'react'
import styles from './Modals.module.scss'
import CloseButton from '../UI/CloseButton/CloseButton'
import Input from '../UI/FormComponents/Input'
import Textarea from '../UI/FormComponents/Textarea'
import Button, { ButtonStyles } from '../UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { PaymentsService } from '../../API/PaymentsService'
import { useAppContext } from '../../hooks/useAppContext'
import { is } from 'date-fns/locale'
import PriceField from '../UI/FormComponents/PriceField'

interface CreatePaymentModalProps {
	close: () => void
	getPayments: () => void
}

const CreatePaymentModal: FC<CreatePaymentModalProps> = ({ close, getPayments }) => {
	const { business } = useAppContext()
	const [name, setName] = useState('')
	const [customerEmail, setCustomerEmail] = useState('')
	const [price, setPrice] = useState('')
	const [description, setDescription] = useState('')
	const [redirectUrl, setRedirectUrl] = useState('')
	const [webhookUrl, setWebhookUrl] = useState('')
	const [currency, setCurrency] = useState(business?.defaultCurrency || 'USD')

	const [isLoading, , createPayment] = useAPI(
		() =>
			PaymentsService.create(business?.id, {
				name: name || null,
				description: description || null,
				customerEmail: customerEmail || null,
				redirectUrl: redirectUrl || null,
				amount: +price,
				currency: currency,
			}),
		false,
		'Payment successfully created',
		{
			onSuccess: () => {
				close()
				getPayments()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<h1 className={styles.h1}>Create new payment link</h1>

			<div className={styles.fieldWrapper}>
				<h3>Payment name</h3>
				<Input value={name} setValue={setName} placeholder={'Payment name'} />
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Customer Email (optional)</h3>
				<Input value={customerEmail} setValue={setCustomerEmail} placeholder={'demo@gmail.com'} />
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Price</h3>
				<PriceField
					currency={currency}
					setCurrency={setCurrency}
					setPrice={setPrice}
					price={price}
				/>
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Description</h3>
				<Textarea
					value={description}
					setValue={setDescription}
					placeholder={'This is a private description only you can view'}
				/>
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Redirect URL</h3>
				<Input
					value={redirectUrl}
					setValue={setRedirectUrl}
					placeholder={'https://yourdomain.com'}
				/>
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Webhook URL</h3>
				<Input
					value={webhookUrl}
					setValue={setWebhookUrl}
					placeholder={'https://webhook.hoodpay.com'}
				/>
			</div>

			<div className={styles.buttons}>
				<Button style={ButtonStyles.TRANSPARENT} onClick={close}>
					Cancel
				</Button>
				<Button onClick={createPayment} isLoading={isLoading}>
					Create payment link
				</Button>
			</div>
		</div>
	)
}

export default CreatePaymentModal
