import React from 'react'

const SignOutSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 5L15.5 8.25L12 11.5M15.25 8.25H7M11.5 1H3C1.89543 1 1 1.89543 1 3V13.5C1 14.6046 1.89543 15.5 3 15.5H11.5'
				stroke='#C11B40'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default SignOutSvg
