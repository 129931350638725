import { CryptoMethods } from '../types/CryptoMethods'

export const getCryptoMethodName = (cryptoMethod?: CryptoMethods) => {
	switch (cryptoMethod) {
		case CryptoMethods.DIRECT_CRYPTO:
			return 'Direct Crypto'
		case CryptoMethods.XPUB_CRYPTO:
			return 'XPub Crypto'
		case CryptoMethods.ONRAMP_CRYPTO:
			return 'On Ramp Crypto'
		default:
			return '-'
	}
}
