import React, { FC, useEffect } from 'react'
import styles from './Tables.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import RefreshIcon from '../../images/RefreshIcon'
import { useAPI } from '../../hooks/useAPI'
import { PaymentWebhooksService } from '../../API/PaymentWebhooksService'
import { Webhook } from '../../types/Webhook'
import StatusCodeBadge from '../UI/StatusCodeBadge/StatusCodeBadge'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

interface WebhookTableProps {
	webhooks?: Webhook[]
}
const WebhookTable: FC<WebhookTableProps> = ({ webhooks }) => {
	return (
		<div className={styles.table}>
			<div className={styles.tableHeader}>
				<h3>URL</h3>
				<h3 className={styles.large}>Event</h3>
				<h3>Status</h3>
				<h3>Date</h3>
			</div>
			{webhooks?.map(webhook => (
				<div className={styles.tableBodyWrapper} id={webhook.url}>
					<Link
						to={`/webhook-events/${webhook.externalMessageId}?externalEndpointId=${webhook.externalEndpointId}`}
						className={styles.tableBody}
					>
						<div className={`${styles.tableBodyItem}`}>
							<p>{webhook.url}</p>
						</div>
						<div className={`${styles.tableBodyItem} ${styles.large}`}>
							<p>{webhook.event}</p>
						</div>
						<div className={`${styles.tableBodyItem}`}>
							<StatusBadge statusType={webhook.status} />
						</div>
						{/*<div className={`${styles.tableBodyItem}`}>*/}
						{/*	<p>*/}
						{/*		1/4 <RefreshIcon />*/}
						{/*	</p>*/}
						{/*</div>*/}
						<div className={styles.tableBodyItem}>
							<p>{format(new Date(webhook.createdAt), 'MMM dd, yyyy')}</p>
							<span>{format(new Date(webhook.createdAt), 'HH:mm aa zzzz')}</span>
						</div>
					</Link>
				</div>
			))}
		</div>
	)
}

export default WebhookTable
