import React from 'react'

const HoodpaySignatureLogo = () => {
	return (
		<svg
			width='119'
			height='14'
			viewBox='0 0 119 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0.556885 12.7114H5.02026V7.83763H11.7752V12.7114H16.2385V0.467077H11.7752V4.81074H5.02026V0.467077H0.556885V12.7114Z'
				fill='#1F1F1F'
			/>
			<path
				d='M26.6121 13.139C31.9989 13.139 35.5901 10.5225 35.5901 6.58925C35.5901 2.65601 31.9989 0.0395508 26.6121 0.0395508C21.2423 0.0395508 17.6511 2.65601 17.6511 6.58925C17.6511 10.5225 21.2423 13.139 26.6121 13.139ZM26.6121 9.99236C23.9272 9.99236 22.1145 8.62428 22.1145 6.58925C22.1145 4.55423 23.9272 3.18614 26.6121 3.18614C29.2969 3.18614 31.1267 4.55423 31.1267 6.58925C31.1267 8.62428 29.2969 9.99236 26.6121 9.99236Z'
				fill='#1F1F1F'
			/>
			<path
				d='M45.667 13.139C51.0539 13.139 54.6451 10.5225 54.6451 6.58925C54.6451 2.65601 51.0539 0.0395508 45.667 0.0395508C40.2973 0.0395508 36.7061 2.65601 36.7061 6.58925C36.7061 10.5225 40.2973 13.139 45.667 13.139ZM45.667 9.99236C42.9822 9.99236 41.1695 8.62428 41.1695 6.58925C41.1695 4.55423 42.9822 3.18614 45.667 3.18614C48.3519 3.18614 50.1817 4.55423 50.1817 6.58925C50.1817 8.62428 48.3519 9.99236 45.667 9.99236Z'
				fill='#1F1F1F'
			/>
			<path
				d='M56.0518 12.7114H64.7562C69.5103 12.7114 72.161 10.5225 72.161 6.58925C72.161 2.65601 69.5103 0.467077 64.7562 0.467077H56.0518V12.7114ZM60.3954 9.68454V3.47686H64.1406C66.5005 3.47686 67.8344 4.60553 67.8344 6.58925C67.8344 8.57297 66.5005 9.68454 64.1406 9.68454H60.3954Z'
				fill='#1F1F1F'
			/>
			<path
				d='M73.587 12.7114H78.0504V8.9663H83.4201C86.9087 8.9663 88.8412 7.47851 88.8583 4.77654C88.8583 2.00617 86.9258 0.467077 83.4543 0.467077H73.587V12.7114ZM78.0504 6.45244V2.98093H81.9665C83.5227 2.98093 84.3778 3.59657 84.3778 4.70814C84.3778 5.83681 83.5227 6.45244 81.9665 6.45244H78.0504Z'
				fill='#1F1F1F'
			/>
			<path
				d='M87.3785 12.7114H91.9958L92.9363 10.5738H99.0414L99.9991 12.7114H104.633L98.6652 0.467077H93.4836L87.3785 12.7114ZM93.8085 8.05994L95.9974 3.22035H96.0316L98.1863 8.05994H93.8085Z'
				fill='#1F1F1F'
			/>
			<path
				d='M108.326 12.7114H112.789V8.53877L118.655 0.467077H113.901L110.583 5.63159H110.566L107.078 0.467077H102.375L108.326 8.53877V12.7114Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default HoodpaySignatureLogo
