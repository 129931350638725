import React, { FC, useState } from 'react'
import styles from './SettingsDevicesPage.module.scss'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { SettingsPageProps } from '../../../types/SettingsPageProps'
import settingsStyles from '../Settings.module.scss'
import DevicesTable from '../../../components/Tables/DevicesTable'
import Pagination from '../../../components/UI/Pagination/Pagination'

const SettingsDevicesPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	const [totalItems, setTotalItems] = useState(10)
	const [pageSize, setPageSize] = useState(10)
	const [currentPage, setCurrentPage] = useState(10)

	return (
		<div className={styles.settingsDevicesPage}>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<div className={settingsStyles.sectionHeaderHead}>
							<h1>Device history</h1>
							<Button style={ButtonStyles.DANGER}>Log out of all known devices</Button>
						</div>
						<p>
							For security purposes, we will always notify you of new login locations and suspicious
							activity with your account. In some cases, we may{' '}
							<a href='#'>temporarily pause payments</a> if we believe your account was taken over
							by bad actors.
						</p>
						<br />
						<p>
							If you don’t recognize a login device or location, we highly recommend (a) signing out
							of all devices and (b) resetting your password.
						</p>
					</div>
				</header>

				<DevicesTable />
				<div className={styles.paginationWrapper}>
					<Pagination
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
					/>
				</div>
			</section>
		</div>
	)
}

export default SettingsDevicesPage
