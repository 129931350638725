import React from 'react'
import styles from './BalancesPage.module.scss'
import PageNavigation from '../../components/PageNavigation/PageNavigation'
import PocketSVG from '../../images/PocketSVG'
import Container from '../../components/UI/Container/Container'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import WalletSvg from '../../images/WalletSVG'
import MetricsSVG from '../../images/MetricsSVG'
import WarningMessage from '../../components/UI/WarningMessage/WarningMessage'
import { Warnings } from '../../types/Warning'
import { Route, Routes } from 'react-router-dom'
import BalancesOverviewPage from './BalancesOverviewPage'
import BalancesPayoutsPage from './BalancesPayoutsPage'
import BalancesSettingsPage from './BalancesSettingsPage'

const BalancesPage = () => {
	const links = [
		{
			title: 'All',
			icon: <PocketSVG />,
			path: '/balances',
		},
		{
			title: 'History',
			icon: <WalletSvg />,
			path: '/balances/payouts',
		},
		{
			title: 'Partner',
			icon: <MetricsSVG />,
			path: '/balances/settings',
		},
	]
	return (
		<PageWrapper>
			<div className={styles.balancesPage}>
				<Container>
					<header className={styles.pageHeader}>
						<h1>Balances</h1>
					</header>
					<div className={styles.pageBody}>
						<PageNavigation links={links} />
						<Routes>
							<Route path={'/'} element={<BalancesOverviewPage />} />
							<Route path={'/payouts'} element={<BalancesPayoutsPage />} />
							<Route path={'/payouts'} element={<BalancesSettingsPage />} />
						</Routes>
					</div>
				</Container>
			</div>
		</PageWrapper>
	)
}

export default BalancesPage
