import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './FormComponents.module.scss'
import { ColorResult, SketchPicker } from 'react-color'

interface ColorInputProps {
	colorValue: string
	setColorValue: (value: string) => void
}

const ColorInput: FC<ColorInputProps> = ({ setColorValue, colorValue }) => {
	const [isColorPickerActive, setColorPickerActive] = useState(false)
	const colorPickerRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: any) => {
		if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
			setColorPickerActive(false)
		}
	}

	useEffect(() => {
		if (colorPickerRef.current) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isColorPickerActive])

	return (
		<div className={styles.colorInput}>
			<div className={styles.colorPickerWrapper}>
				<div
					className={styles.color}
					style={{ backgroundColor: colorValue }}
					onClick={() => setColorPickerActive(true)}
				/>
				{isColorPickerActive && (
					<div className={styles.colorPicker} ref={colorPickerRef}>
						<SketchPicker onChange={color => setColorValue(color.hex)} color={colorValue} />
					</div>
				)}
			</div>
			<span className={styles.colorHash}>{colorValue}</span>
		</div>
	)
}

export default ColorInput
