import React from 'react'

const KeySvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.8164 10.1357C14.1636 10.1357 16.0664 8.23294 16.0664 5.88574C16.0664 3.53853 14.1636 1.63574 11.8164 1.63574C9.46921 1.63574 7.56641 3.53853 7.56641 5.88574C7.56641 6.19586 7.59961 6.49822 7.66271 6.78946L1.56641 12.8857V16.1357H4.81641L5.56641 15.3857V13.6357H7.31641L8.56641 12.3857V10.6357H10.3164L10.9127 10.0394C11.2039 10.1025 11.5063 10.1357 11.8164 10.1357Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.3164 4.88574C13.3164 5.16188 13.0925 5.38574 12.8164 5.38574C12.5403 5.38574 12.3164 5.16188 12.3164 4.88574C12.3164 4.6096 12.5403 4.38574 12.8164 4.38574C13.0925 4.38574 13.3164 4.6096 13.3164 4.88574Z'
				stroke='#1F1F1F'
			/>
		</svg>
	)
}

export default KeySvg
