import React, { FC, useState } from 'react'
import styles from './CustomersTable.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import CopyIcon from '../../images/CopyIcon'
import DollarSVG from '../../images/DollarSVG'
import { Payment } from '../../types/Payment'
import getSymbolFromCurrency from 'currency-symbol-map'
import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { useCopy } from '../../hooks/useCopy'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { Customer } from '../../types/Customer'
import { cleanEmail } from '../../utils/cleanEmail'
import { SearchCustomer } from '../../types/SearchCustomer'

interface CustomersSearchTableBodyProps {
	customer: SearchCustomer
}

const CustomersSearchTableBody: FC<CustomersSearchTableBodyProps> = ({ customer }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const navigate = useNavigate()
	const copyToClipboard = useCopy()
	const cleanedEmail = cleanEmail(customer.email)

	return (
		<div className={`${styles.tableBodyWrapper} ${styles.search}`}>
			<div
				className={styles.tableBody}
				onClick={() => navigate(`/customers?customerId=${customer.id}`)}
			>
				<div className={`${styles.tableBodyItem} ${styles.full}`}>
					<div className={styles.customer}>
						<div className={styles.customerAva}>
							{cleanedEmail[0]}
							{cleanedEmail[cleanedEmail.length - 1]}
						</div>
						<div>
							<h4>{customer.name}</h4>
							<span className={styles.span}>{customer.email}</span>
						</div>
					</div>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{format(new Date(customer.createdAt), 'MMM dd, HH:mm aa')}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.settings}`}>
					<div className={styles.actionMenuWrapper}>
						<SettingsButton
							onClick={event => {
								event.stopPropagation()
								event.preventDefault()
								setIsActionMenuActive(!isActionMenuActive)
							}}
						/>
						<ActionMenu
							actionButtons={[
								{
									icon: <CopyIcon />,
									onClick: () => copyToClipboard(customer.id.toString()),
									title: 'Copy customer ID',
								},
								{
									icon: <DollarSVG />,
									onClick: () => navigate(`/customer?customerId=${customer.id}`),
									title: 'View more details',
								},
							]}
							isActive={isActionMenuActive}
							setIsActive={setIsActionMenuActive}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CustomersSearchTableBody
