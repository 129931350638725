import React, { FC, useEffect, useState } from 'react'
import styles from './Tables.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import RefreshIcon from '../../images/RefreshIcon'
import { useAPI } from '../../hooks/useAPI'
import { PaymentWebhooksService } from '../../API/PaymentWebhooksService'
import { Webhook } from '../../types/Webhook'
import StatusCodeBadge from '../UI/StatusCodeBadge/StatusCodeBadge'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { ApiKey } from '../../types/ApiKey'
import { UserSettingsDeveloperService } from '../../API/UserSettingsDeveloperService'
import { useCopy } from '../../hooks/useCopy'
import SecretKeyWrapper from '../UI/SecretKeyWrapper/SecretKeyWrapper'
import { BusinessSettingsDeveloperService } from '../../API/BusinessSettingsDeveloperService'
import { WebhookDeveloper } from '../../types/WebhookDeveloper'
import Settings from '../../pages/Settings/Settings'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import CopyIcon from '../../images/CopyIcon'
import TrashCanSVG from '../../images/TrashCanSVG'
import Spinner from '../UI/Spinner/Spinner'
import NetworkSVG from '../../images/NetworkSVG'

interface WebhookDeveloperTableProps {
	webhooksData?: {
		webhookSecret?: string
		webhooks: WebhookDeveloper[]
	}
	deleteWebhook: (webhookId: string) => void
	isLoading: boolean
}

interface TableBodyProps {
	webhook: WebhookDeveloper
	webhookSecret?: string
	deleteWebhook: (webhookId: string) => void
}

const TableBody: FC<TableBodyProps> = ({ webhook, webhookSecret, deleteWebhook }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	return (
		<div className={styles.tableBodyWrapper} key={webhook.id}>
			<div className={styles.tableBody}>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{webhook.url}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.large}`}>
					<SecretKeyWrapper secretKey={webhookSecret || ''} icon={<NetworkSVG />} />
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{webhook.events.length > 1 ? `${webhook.events.length} events` : webhook.events}</p>
					{/*<p>{format(new Date(apiKey.expiresAt), 'MMM dd, yyyy')}</p>*/}
				</div>
				<div className={styles.tableBodyItem}>
					<p>{format(new Date(webhook.createdAt), 'MMM dd, hh:mm aa')}</p>
					{/*<p>{format(new Date(apiKey.expiresAt), 'MMM dd, yyyy')}</p>*/}
				</div>
				<div className={`${styles.tableBodyItem} ${styles.settings}`}>
					<div className={styles.actionMenu}>
						<SettingsButton onClick={() => setIsActionMenuActive(!isActionMenuActive)} />
						<ActionMenu
							actionButtons={[
								{
									title: 'Delete',
									onClick: () => deleteWebhook(webhook.id),
									icon: <TrashCanSVG />,
								},
							]}
							isActive={isActionMenuActive}
							setIsActive={setIsActionMenuActive}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const WebhookDeveloperTable: FC<WebhookDeveloperTableProps> = ({
	webhooksData,
	deleteWebhook,
	isLoading,
}) => {
	if (isLoading) return <Spinner />

	return (
		<div className={styles.table}>
			<div className={styles.tableHeader}>
				<h3 className={styles.medium}>URL</h3>
				<h3 className={styles.large}>Webhook Secret</h3>
				<h3 className={styles.medium}>Events</h3>
				<h3>Created</h3>
				<h3 className={styles.settings} />
			</div>
			{webhooksData?.webhooks?.map(webhook => (
				<TableBody
					webhookSecret={webhooksData?.webhookSecret}
					webhook={webhook}
					deleteWebhook={deleteWebhook}
					key={webhook.id}
				/>
			))}
		</div>
	)
}

export default WebhookDeveloperTable
