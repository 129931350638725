import React from 'react'
import styles from './CustomerTicket.module.scss'

const CustomerTicket = () => {
	return (
		<div className={styles.customerTicket}>
			<header>
				<h2>Help</h2>
				<time>3 days ago</time>
			</header>
			<p className={styles.email}>help@gmail.com</p>
			<p className={styles.message}>
				Hi I never got my product. GIVE ME MY PRO...GIVE ME MY PRO...
			</p>
		</div>
	)
}

export default CustomerTicket
