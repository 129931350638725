import React from 'react'

const BrushSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.02391 5.48808L12.4323 2.07983C13.1518 1.36036 14.289 1.33112 14.9725 2.01454C15.6559 2.69796 15.6266 3.83523 14.9072 4.55471L11.4725 7.98938M5.23511 6.75198L0.985107 9.50198L7.48511 16.002L10.2351 11.752M3.23511 11.752L4.48511 10.502M11.9016 8.41848L8.56861 5.08545C7.81981 4.33666 6.61711 4.30132 5.82564 5.00485L4.98511 5.75198L11.2351 12.002L11.9822 11.1615C12.6858 10.37 12.6504 9.16728 11.9016 8.41848Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default BrushSvg
