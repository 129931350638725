import React, { FC, MutableRefObject, useState } from 'react'
import styles from './AuthPage.module.scss'
import Button, { ButtonStyles } from '../../components/UI/Button/Button'
import Input from '../../components/UI/FormComponents/Input'
import { useAPI } from '../../hooks/useAPI'
import { AuthService } from '../../API/AuthService'

interface AuthResetPasswordProps {
	recaptchaRef: MutableRefObject<any>
}

const AuthResetPassword: FC<AuthResetPasswordProps> = ({ recaptchaRef }) => {
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState('')

	const validateReset = () => {
		const isEmailMatchesToRegexp = email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
		setEmailError(!email ? 'Required' : !isEmailMatchesToRegexp ? 'Invalid email' : '')

		return !!email && !!isEmailMatchesToRegexp
	}

	const [isResetLoading, , reset] = useAPI(
		async () => {
			const recaptcha = await recaptchaRef.current.executeAsync()
			recaptchaRef.current.reset()

			return AuthService.reset(email, recaptcha)
		},
		false,
		'Password reset email has been sent',
		{
			validator: validateReset,
		}
	)

	return (
		<div className={styles.authForm}>
			<h1>Reset your password</h1>
			<p className={styles.description}>We’ll send a password reset link to your email address.</p>
			<label className={styles.fieldWrapper}>
				<p>Email address</p>
				<Input
					value={email}
					setValue={setEmail}
					placeholder={'you@example.com'}
					isError={!!emailError}
					errorMessage={emailError}
				/>
			</label>

			<Button style={ButtonStyles.SECONDARY} isLoading={isResetLoading} onClick={reset}>
				Send reset link
			</Button>
		</div>
	)
}

export default AuthResetPassword
