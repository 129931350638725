import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import styles from './AuthForms.module.scss'
import Button, { ButtonStyles } from '../UI/Button/Button'
import GoogleIconMonotone from '../../images/GoogleIconMonotone'
import Input from '../UI/FormComponents/Input'
import Checkbox from '../UI/Checkbox/Checkbox'
import { useNavigate } from 'react-router-dom'
import { useAPI } from '../../hooks/useAPI'
import { AuthService } from '../../API/AuthService'

interface CreateAccountFormProps {
	recaptchaRef: MutableRefObject<any>
	setStep: (value: number) => void
	setPassword: (value: string) => void
	password: string
	setEmail: (value: string) => void
	email: string
}

const CreateAccountForm: FC<CreateAccountFormProps> = ({
	recaptchaRef,
	setStep,
	email,
	password,
	setPassword,
	setEmail,
}) => {
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errors, setErrors] = useState({
		email: '',
		password: '',
		confirmPassword: '',
	})
	const [newWindow, setNewWindow] = useState<Window | null>(null)
	const [agreementValue, setAgreementValue] = useState(false)

	const signUpValidator = () => {
		const isEmailMatchesToRegexp = email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

		setErrors({
			...errors,
			email: !email ? 'Required' : !isEmailMatchesToRegexp ? 'Invalid email' : '',
			password: !password
				? 'Required'
				: password.length < 8
				? 'The length of the password must exceed 8 characters.'
				: '',
			confirmPassword: !confirmPassword
				? 'Required'
				: password !== confirmPassword
				? 'Passwords did not match'
				: '',
		})

		return !!email && !!isEmailMatchesToRegexp && !!password && password === confirmPassword
	}

	const onClickHandler = () => {
		if (signUpValidator()) return setStep(1)
	}

	useEffect(() => {
		if (newWindow) {
			const Interval = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(Interval)
					if (localStorage.getItem('token')) {
						window.location.pathname = '/'
					}
				}
			}, 500)
		}
	}, [newWindow])

	return (
		<div className={styles.authForm}>
			<h1>Let’s create your account</h1>
			<p className={styles.description}>
				It’s completely free to accept crypto, card, and wallet payments with Hoodpay.
			</p>
			<Button
				style={ButtonStyles.THIRD}
				onClick={() =>
					setNewWindow(
						window.open(
							'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https://dash.hoodpay.io/auth/google/callback&prompt=consent&response_type=code&client_id=1071387133162-97lpquk2g2laoodhqcd09om13emak091.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&flowName=GeneralOAuthFlow',
							'_blank',
							'width=480, height=720'
						)
					)
				}
			>
				<div className={styles.buttonInner}>
					<GoogleIconMonotone className={styles.googleIcon} />
					<span>Sign Up with Google</span>{' '}
				</div>
			</Button>
			<span className={styles.or}>OR</span>
			<label className={styles.fieldWrapper}>
				<p>Email Address</p>
				<Input
					value={email}
					setValue={setEmail}
					placeholder={'you@example.com'}
					isError={!!errors.email}
					errorMessage={errors.email}
					autoComplete={'off'}
				/>
			</label>
			<label className={styles.fieldWrapper}>
				<p>Password</p>
				<Input
					value={password}
					setValue={setPassword}
					placeholder={'*******************'}
					type={'password'}
					isError={!!errors.password}
					errorMessage={errors.password}
					autoComplete={'new-password'}
				/>
			</label>
			<label className={styles.fieldWrapper}>
				<p>Confirm Password</p>
				<Input
					value={confirmPassword}
					setValue={setConfirmPassword}
					placeholder={'*******************'}
					type={'password'}
					isError={!!errors.confirmPassword}
					errorMessage={errors.confirmPassword}
					autoComplete={'new-password'}
				/>
			</label>
			<div className={styles.tipText}>
				<Checkbox
					id={'agreement'}
					checked={agreementValue}
					setChecked={setAgreementValue}
					text={'I agree to the following documents:'}
				/>
				<ul>
					<li>
						<a href='https://docs.hoodpay.io/docs/platform-agreement'>Platform Agreement</a>
					</li>
					<li>
						<a href='https://docs.hoodpay.io/docs/privacy-policy'>Privacy Policy</a>
					</li>
				</ul>
			</div>
			<Button style={ButtonStyles.SECONDARY} onClick={onClickHandler} isDisabled={!agreementValue}>
				Continue
			</Button>
		</div>
	)
}

export default CreateAccountForm
