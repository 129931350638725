import React from 'react'
import styles from './BalancesPage.module.scss'
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable'
import { useAppContext } from '../../hooks/useAppContext'

const BalancesPayoutsPage = () => {
	const { business } = useAppContext()

	return (
		<div className={styles.pageInner}>
			<PaymentsTable isFee={true} businessId={business?.id} />
		</div>
	)
}

export default BalancesPayoutsPage
