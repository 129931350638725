import React from 'react'

const CrownSvg = () => {
	return (
		<svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.985107 11.2852V0.785156L5.23511 6.28516L8.23511 0.785156L11.2351 6.28516L15.4851 0.785156V11.2852C15.4851 11.2852 14.2351 13.2852 8.23511 13.2852C2.23511 13.2852 0.985107 11.2852 0.985107 11.2852Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CrownSvg
