import React from 'react'

const NetworkSvg = () => {
	return (
		<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.75 6.33203V7.08203C2.75 8.18663 3.64543 9.08203 4.75 9.08203H8M8 9.08203H11.25C12.3546 9.08203 13.25 8.18663 13.25 7.08203V6.33203M8 9.08203V11.332M5.25 3.83203C5.25 5.07467 4.24264 6.08203 3 6.08203C1.75736 6.08203 0.75 5.07467 0.75 3.83203C0.75 2.58939 1.75736 1.58203 3 1.58203C4.24264 1.58203 5.25 2.58939 5.25 3.83203ZM15.25 3.83203C15.25 5.07467 14.2426 6.08203 13 6.08203C11.7574 6.08203 10.75 5.07467 10.75 3.83203C10.75 2.58939 11.7574 1.58203 13 1.58203C14.2426 1.58203 15.25 2.58939 15.25 3.83203ZM10.25 13.832C10.25 15.0746 9.2426 16.082 8 16.082C6.7574 16.082 5.75 15.0746 5.75 13.832C5.75 12.5894 6.7574 11.582 8 11.582C9.2426 11.582 10.25 12.5894 10.25 13.832Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default NetworkSvg
