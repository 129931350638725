import React, { FC } from 'react'
import styles from './FormComponents.module.scss'

interface TextareaProps {
	value: string
	setValue: (value: string) => void
	placeholder?: string
	required?: boolean
	isError?: boolean
	errorMessage?: string
}

const Textarea: FC<TextareaProps> = ({
	value,
	setValue,
	placeholder,
	required,
	isError,
	errorMessage,
}) => {
	return (
		<div>
			<textarea
				className={`${styles.textarea} ${isError ? styles.error : ''}`}
				value={value}
				onChange={event => setValue(event.target.value)}
				placeholder={placeholder}
				required={required}
			/>
			{isError && <p className={styles.errorMessage}>{errorMessage || 'Required'}</p>}
		</div>
	)
}

export default Textarea
