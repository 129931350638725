import React from 'react'

const GearIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.69417 11.1385L2.79497 11.3109C3.60758 11.4381 4.18791 12.1824 4.12806 13.0209L4.04696 14.1574C4.02334 14.4885 4.19972 14.8002 4.49185 14.9444L5.30603 15.3455C5.59816 15.4897 5.94699 15.4357 6.1856 15.211L7.0037 14.4378C7.6069 13.8675 8.5376 13.8675 9.1415 14.4378L9.9596 15.211C10.1982 15.4366 10.5463 15.4897 10.8392 15.3455L11.6549 14.9436C11.9463 14.8002 12.1219 14.4893 12.0983 14.1591L12.0171 13.0209C11.9573 12.1824 12.5376 11.4381 13.3502 11.3109L14.451 11.1385C14.7715 11.0886 15.0298 10.8429 15.1022 10.5191L15.303 9.61858C15.3755 9.29478 15.2471 8.95888 14.9794 8.77198L14.0605 8.12838C13.3825 7.65318 13.1754 6.72528 13.5849 5.99629L14.14 5.00877C14.3014 4.72121 14.2747 4.36116 14.0723 4.1018L13.5093 3.37928C13.3069 3.11991 12.9699 3.01279 12.6597 3.11025L11.5951 3.44372C10.8085 3.69019 9.9699 3.27698 9.6675 2.49486L9.2596 1.43726C9.1399 1.12796 8.8478 0.924984 8.5226 0.925784L7.6202 0.928204C7.295 0.929004 7.0037 1.1336 6.8856 1.44371L6.4879 2.48922C6.1887 3.27618 5.34619 3.69261 4.5572 3.44452L3.44853 3.09656C3.1375 2.99829 2.79891 3.10622 2.59655 3.3672L2.03748 4.09052C1.83512 4.3523 1.81071 4.71316 1.97528 5.00071L2.543 5.99065C2.96112 6.72038 2.75639 7.65718 2.07449 8.13488L1.1666 8.77118C0.898876 8.95888 0.770536 9.29478 0.842976 9.61778L1.04377 10.5183C1.11542 10.8429 1.37369 11.0886 1.69417 11.1385Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.6641 6.58468C10.5426 7.46328 10.5426 8.88828 9.6641 9.76688C8.7855 10.6454 7.3605 10.6454 6.4819 9.76688C5.60336 8.88828 5.60336 7.46328 6.4819 6.58468C7.3605 5.70614 8.7855 5.70614 9.6641 6.58468Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default GearIcon
