import React from 'react'

const DownloadSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.20166 11.582V13.082C1.20166 14.7389 2.54481 16.082 4.20166 16.082H12.7017C14.3586 16.082 15.7017 14.7389 15.7017 13.082V11.582M8.45166 11.082V1.58203M8.45166 11.082L5.20166 7.58203M8.45166 11.082L11.7017 7.58203'
				stroke='#C11B40'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default DownloadSvg
