import React, { useEffect, useState } from 'react'
import styles from './Sidebar.module.scss'
import Logo from '../UI/Logo/Logo'
import Navigation from '../Navigation/Navigation'
import { useLocation } from 'react-router-dom'
import BusinessSwitcher from '../BusinessSwitcher/BusinessSwitcher'
import HoodpayLogo from '../../images/HoodpayLogo'
const Sidebar = () => {
	return (
		<aside className={styles.sidebar}>
			<div className={styles.logoWrapper}>
				<Logo />
			</div>
			<div className={styles.navigationWrapper}>
				<Navigation isVertical={true} />
			</div>
			<div className={styles.businessSwitcherWrapper}>
				<BusinessSwitcher />
			</div>
		</aside>
	)
}

export default Sidebar
