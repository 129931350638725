import React from 'react'

const ChooseableSvg = () => {
	return (
		<svg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.0323497 9.13633C0.0689031 9.03123 0.132824 8.95135 0.210096 8.91421C0.287368 8.87707 0.371685 8.88569 0.444561 8.93819L3.99881 11.5033L7.55243 8.9373C7.58859 8.91063 7.62804 8.89456 7.66852 8.89001C7.70899 8.88546 7.74969 8.89253 7.78826 8.9108C7.82684 8.92908 7.86253 8.9582 7.89327 8.99649C7.92402 9.03478 7.94921 9.08148 7.9674 9.13389C7.98559 9.18631 7.99641 9.24341 7.99925 9.30189C8.00208 9.36038 7.99688 9.4191 7.98392 9.47467C7.97097 9.53023 7.95053 9.58154 7.92378 9.62565C7.89703 9.66975 7.8645 9.70577 7.82806 9.73163L4.13662 12.3972C4.09384 12.4281 4.04665 12.4442 3.99881 12.4442C3.95096 12.4442 3.90377 12.4281 3.86099 12.3972L0.169548 9.73163C0.0967728 9.67884 0.0414617 9.58653 0.0157428 9.47494C-0.00997614 9.36334 -0.00400403 9.24157 0.0323497 9.13633Z'
				fill='#1F1F1F'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.96765 3.30801C7.9311 3.41311 7.86718 3.49298 7.7899 3.53013C7.71263 3.56727 7.62831 3.55864 7.55544 3.50614L4.00119 0.941023L0.447565 3.50703C0.411411 3.5337 0.37196 3.54978 0.331484 3.55432C0.291009 3.55887 0.25031 3.55181 0.211735 3.53353C0.17316 3.51526 0.137471 3.48613 0.106726 3.44785C0.0759807 3.40956 0.0507884 3.36286 0.0325999 3.31044C0.014412 3.25802 0.00358775 3.20093 0.000752008 3.14244C-0.00208374 3.08395 0.00312476 3.02524 0.0160776 2.96967C0.0290304 2.9141 0.049471 2.86279 0.0762211 2.81869C0.102972 2.77459 0.135502 2.73857 0.171937 2.71271L3.86338 0.0471848C3.90616 0.0162278 3.95335 0.000108842 4.00119 0.000108846C4.04904 0.00010885 4.09623 0.0162279 4.13901 0.0471848L7.83045 2.71271C7.90323 2.7655 7.95854 2.85781 7.98426 2.9694C8.00998 3.08099 8.004 3.20276 7.96765 3.30801Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default ChooseableSvg
