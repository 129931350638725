import React from 'react'

const WarningBellSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.3869 15.5458H15.6203V10.7543C15.6203 8.92434 14.8933 7.16934 13.5994 5.87537C12.3054 4.58141 10.5504 3.85446 8.72046 3.85446C6.89051 3.85446 5.13552 4.58141 3.84155 5.87537C2.54758 7.16934 1.82064 8.92434 1.82064 10.7543V15.5458H1.05399C0.739664 15.5458 0.479004 15.8065 0.479004 16.1208C0.479004 16.4351 0.739664 16.6958 1.05399 16.6958H16.3869C16.7013 16.6958 16.9619 16.4351 16.9619 16.1208C16.9619 15.8065 16.7013 15.5458 16.3869 15.5458ZM8.72046 2.12951C8.40613 2.12951 8.14547 1.86885 8.14547 1.55452V0.787876C8.14547 0.473551 8.40613 0.212891 8.72046 0.212891C9.03478 0.212891 9.29544 0.473551 9.29544 0.787876V1.55452C9.29544 1.86885 9.03478 2.12951 8.72046 2.12951ZM3.3547 3.66357C3.20903 3.66357 3.06337 3.6099 2.94837 3.49491L2.18173 2.72826C2.07479 2.62006 2.01483 2.47406 2.01483 2.32194C2.01483 2.16981 2.07479 2.02382 2.18173 1.91561C2.40405 1.69329 2.77205 1.69329 2.99437 1.91561L3.76102 2.68226C3.98335 2.90459 3.98335 3.27258 3.76102 3.49491C3.64602 3.6099 3.50036 3.66357 3.3547 3.66357ZM14.0878 3.66357C13.9421 3.66357 13.7964 3.6099 13.6814 3.49491C13.5745 3.3867 13.5145 3.24071 13.5145 3.08858C13.5145 2.93646 13.5745 2.79046 13.6814 2.68226L14.4481 1.91561C14.6704 1.69329 15.0384 1.69329 15.2607 1.91561C15.4831 2.13794 15.4831 2.50593 15.2607 2.72826L14.4941 3.49491C14.3791 3.6099 14.2334 3.66357 14.0878 3.66357Z'
				fill='#F83939'
			/>
		</svg>
	)
}

export default WarningBellSvg
