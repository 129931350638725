import React from 'react'

const ErrorIcon = () => {
	return (
		<svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.0511 10.722L11.2462 2.07319C10.6005 0.90951 9.70713 0.271362 8.72363 0.271362C7.74014 0.271362 6.84673 0.90951 6.20107 2.07319L1.3962 10.722C0.788082 11.8256 0.720514 12.8842 1.20851 13.7175C1.6965 14.5508 2.65748 15.0088 3.91876 15.0088H13.5285C14.7898 15.0088 15.7508 14.5508 16.2388 13.7175C16.7268 12.8842 16.6592 11.8181 16.0511 10.722ZM8.16056 5.52669C8.16056 5.21888 8.41582 4.96362 8.72363 4.96362C9.03145 4.96362 9.2867 5.21888 9.2867 5.52669V9.2805C9.2867 9.58831 9.03145 9.84357 8.72363 9.84357C8.41582 9.84357 8.16056 9.58831 8.16056 9.2805V5.52669ZM9.25667 12.0658L9.14406 12.1559C9.09901 12.1859 9.05397 12.2085 9.00892 12.2235C8.96388 12.246 8.91883 12.261 8.86628 12.2685C8.82123 12.276 8.76868 12.2835 8.72363 12.2835C8.67859 12.2835 8.62603 12.276 8.57348 12.2685C8.52644 12.2604 8.48088 12.2452 8.43834 12.2235C8.39049 12.2071 8.34504 12.1844 8.30321 12.1559L8.19059 12.0658C8.05546 11.9232 7.97287 11.728 7.97287 11.5328C7.97287 11.3376 8.05546 11.1424 8.19059 10.9997L8.30321 10.9097C8.34825 10.8796 8.3933 10.8571 8.43834 10.8421C8.48339 10.8196 8.52844 10.8045 8.57348 10.797C8.67108 10.7745 8.77619 10.7745 8.86628 10.797C8.91883 10.8045 8.96388 10.8196 9.00892 10.8421C9.05397 10.8571 9.09901 10.8796 9.14406 10.9097L9.25667 10.9997C9.39181 11.1424 9.47439 11.3376 9.47439 11.5328C9.47439 11.728 9.39181 11.9232 9.25667 12.0658Z'
				fill='#F83939'
			/>
		</svg>
	)
}

export default ErrorIcon
