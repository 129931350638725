import React from 'react'

const PocketSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.4851 4.37598H1.98511M14.4851 4.37598V13.376L15.4851 15.876H0.985107L1.98511 13.376V4.37598M14.4851 4.37598L12.4851 1.37598H3.98511L1.98511 4.37598M5.98511 7.37598V8.37598C5.98511 9.61858 6.99251 10.626 8.23511 10.626C9.47771 10.626 10.4851 9.61858 10.4851 8.37598V7.37598'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PocketSvg
