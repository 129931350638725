import axios from 'axios'

const baseUrl =
	localStorage.getItem('dv') === '1' ? 'https://localhost:5001' : 'https://api.hoodpay.io/'

export const publicAPI = axios.create({
	baseURL: baseUrl,
})
export const privateAPI = axios.create({
	baseURL: baseUrl,
	headers: {
		Authorization: `bearer ${localStorage.getItem('token')}`,
	},
})

privateAPI.interceptors.response.use(null, error => {
	if (
		error.response.status === 401 &&
		error.response.request.responseURL !== `${baseUrl}/v1/dash/user`
	) {
		window.location.pathname = '/'
		localStorage.removeItem('token')
		localStorage.removeItem('businessId')
	}

	return Promise.reject(error)
})
