import { privateAPI } from './API'
import { BusinessPost } from '../types/BusinessPost'

export const BusinessesService = {
	create: async (data: BusinessPost, token: string) => {
		return await privateAPI.post(`v1/dash/businesses`, data, {
			headers: {
				Authorization: `bearer ${token || localStorage.getItem('token')}`,
			},
		})
	},
}
