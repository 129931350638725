import React from 'react'

const SuccessStatusIcon = () => {
	return (
		<svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.42346 5.63477L3.67346 8.13477L9.92346 1.63477'
				stroke='#578643'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default SuccessStatusIcon
