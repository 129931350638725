import React, { FC, ReactNode, useEffect, useRef } from 'react'
import styles from './SideModal.module.scss'
import CloseButton from '../UI/CloseButton/CloseButton'

interface SideModalProps {
	children: ReactNode
	closeModal: () => void
	visible: boolean
	setVisible: (value: boolean) => void
}

const SideModal: FC<SideModalProps> = ({ children, closeModal, visible, setVisible }) => {
	const sideModalRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: any) => {
		if (!sideModalRef.current?.contains(event.target as Node)) {
			closeModal()
		}
	}

	useEffect(() => {
		setVisible(true)

		// document.addEventListener('click', handleClickOutside)
		//
		// return () => {
		// 	document.removeEventListener('click', handleClickOutside)
		// }
	}, [])

	return (
		<>
			<div className={`${styles.overlay}  ${visible ? styles.visible : ''}`} onClick={closeModal} />
			<div className={`${styles.sideModal} ${visible ? styles.visible : ''}`} ref={sideModalRef}>
				{/*<CloseButton*/}
				{/*	onClick={closeModal}*/}
				{/*	style={{*/}
				{/*		marginBottom: 20,*/}
				{/*	}}*/}
				{/*/>*/}
				<div>{children}</div>
			</div>
		</>
	)
}

export default SideModal
