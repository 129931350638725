export enum Cryptocurrencies {
	BITCOIN = 'BITCOIN',
	ETHEREUM = 'ETHEREUM',
	LITECOIN = 'LITECOIN',
	BITCOIN_CASH = 'BITCOIN_CASH',
	ETH_USD_COIN = 'ETH_USD_COIN',
	ETH_TETHER = 'ETH_TETHER',
	MONERO = 'MONERO',
	ETH_BNB = 'ETH_BNB',
	ETH_BUSD = 'ETH_BUSD',
	ETH_MATIC = 'ETH_MATIC',
	ETH_SHIBA_INU = 'ETH_SHIBA_INU',
	ETH_APE_COIN = 'ETH_APE_COIN',
	ETH_CRONOS = 'ETH_CRONOS',
	ETH_DAI = 'ETH_DAI',
	ETH_UNISWAP = 'ETH_UNISWAP',
	TRON = 'TRON',
	TRX_TETHER = 'TRX_TETHER',
	TRX_USD_C = 'TRX_USD_C',
}

export type Cryptocurrency =
	| Cryptocurrencies.BITCOIN
	| Cryptocurrencies.BITCOIN_CASH
	| Cryptocurrencies.ETH_DAI
	| Cryptocurrencies.ETH_BNB
	| Cryptocurrencies.ETHEREUM
	| Cryptocurrencies.ETH_CRONOS
	| Cryptocurrencies.ETH_BUSD
	| Cryptocurrencies.ETH_UNISWAP
	| Cryptocurrencies.ETH_TETHER
	| Cryptocurrencies.ETH_MATIC
	| Cryptocurrencies.ETH_USD_COIN
	| Cryptocurrencies.ETH_SHIBA_INU
	| Cryptocurrencies.ETH_APE_COIN
	| Cryptocurrencies.MONERO
	| Cryptocurrencies.LITECOIN
	| Cryptocurrencies.TRON
	| Cryptocurrencies.TRX_TETHER
	| Cryptocurrencies.TRX_USD_C
