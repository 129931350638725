import React from 'react'

const ClockSvg = () => {
	return (
		<svg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M20.9963 41.5952C32.1781 41.5952 41.2427 32.5306 41.2427 21.3489C41.2427 10.1671 32.1781 1.10254 20.9963 1.10254C9.81459 1.10254 0.75 10.1671 0.75 21.3489C0.75 32.5306 9.81459 41.5952 20.9963 41.5952Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
			/>
			<path d='M20.9961 10.1787V21.3491L26.5813 26.9343' stroke='#1F1F1F' strokeWidth='1.5' />
		</svg>
	)
}

export default ClockSvg
