import React, { useEffect, useState } from 'react'
import Container from '../../components/UI/Container/Container'
import WarningMessage from '../../components/UI/WarningMessage/WarningMessage'
import HomePaymentsSection from '../../components/HomePaymentsSection/HomePaymentsSection'
import { Warnings } from '../../types/Warning'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { useAPI } from '../../hooks/useAPI'
import { PaymentsService } from '../../API/PaymentsService'
import { useAppContext } from '../../hooks/useAppContext'
import { Settings } from '../../types/Settings'
import { BusinessSettingsService } from '../../API/BusinessSettingsService'
import { Payment } from '../../types/Payment'
import { Link, useNavigate } from 'react-router-dom'
import Spinner from '../../components/UI/Spinner/Spinner'
import styles from './HomePage.module.scss'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../utils/CloseModal'
import TopGatewaysModal from '../../components/Modals/TopGatewaysModal'
import Select, { SelectStyles } from '../../components/UI/FormComponents/Select'
import DateRangePicker from '../../components/UI/DateRangePicker/DateRangePicker'
import StatItem from '../../components/StatItem/StatItem'
import ArrowHeadSVG from '../../images/ArrowHeadSVG'
import { getPaymentMethodName } from '../../utils/getPaymentMethodName'
import { MainGraphStats } from '../../types/MainGraphStats'
import { DashboardService } from '../../API/DashboardService'
import { MainTextStats } from '../../types/MainTextStats'

enum SelectDates {
	lastDay = 'lastDay',
	lastWeek = 'lastWeek',
	lastMonth = 'lastMonth',
	lastYear = 'lastYear',
}

const HomePage = () => {
	const { business, user } = useAppContext()
	const [isLoading, settings, getSettings] = useAPI<Settings>(
		(businessId: number) => BusinessSettingsService.get(businessId),
		false
	)
	const [isPaymentsLoading, payments, getPayments] = useAPI<Payment[]>(
		() => PaymentsService.getAll(business?.id, 1, 1),
		false
	)
	const navigate = useNavigate()
	const [toDate, setToDate] = useState<Date | null>(new Date())
	const [fromDate, setFromDate] = useState<Date | null>(() => {
		const today = new Date()
		const yesterday = new Date(today)
		yesterday.setDate(today.getDate() - 1)
		return yesterday
	})
	const [dateSelectValue, setDateSelectValue] = useState(SelectDates.lastDay)
	const durationMs = toDate && fromDate ? toDate.getTime() - fromDate.getTime() : 0
	const prevFromDate = fromDate ? new Date(fromDate.getTime() - durationMs) : null
	const [isTopGatewaysModalActive, setIsTopGatewaysModalActive] = useState(false)
	const [isTopGatewaysModalVisible, setIsTopGatewaysModalVisible] = useState(false)

	const [isMainGraphStatsLoading, mainGraphStatsData, getMainGraphStats] = useAPI<MainGraphStats[]>(
		() => DashboardService.getMainGraphStats(business?.id, fromDate, toDate),
		false
	)
	const [isMainTextStatsLoading, mainTextStatsData, getMainTextStats] = useAPI<MainTextStats>(
		() => DashboardService.getMainTextStats(business?.id, fromDate, toDate),
		false
	)
	const [isPrevMainGraphStatsLoading, prevMainGraphStats, getPrevMainGraphStats] = useAPI<
		MainGraphStats[]
	>(() => DashboardService.getMainGraphStats(business?.id, prevFromDate, fromDate), false)

	const handleDateSelect = (value: SelectDates) => {
		setDateSelectValue(value)
		let startDate = new Date()
		let endDate = new Date()

		if (value === SelectDates.lastDay) {
			endDate = new Date(startDate)
			endDate.setDate(startDate.getDate() - 1)
		}
		if (value === SelectDates.lastWeek) {
			endDate = new Date(startDate)
			endDate.setDate(startDate.getDate() - 7)
		}
		if (value === SelectDates.lastMonth) {
			endDate = new Date(startDate)
			endDate.setDate(startDate.getDate() - 30)
		}
		if (value === SelectDates.lastYear) {
			endDate = new Date(startDate)
			endDate.setDate(startDate.getDate() - 365)
		}

		setFromDate(endDate)
		setToDate(startDate)
	}

	useEffect(() => {
		if (business?.id) {
			getMainGraphStats()
			getPrevMainGraphStats()
			getMainTextStats()
		}
	}, [business, fromDate, toDate])

	useEffect(() => {
		if (business?.id && !isLoading) {
			getSettings(business.id)
			getPayments()
		}
	}, [business])

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			navigate('/auth/login')
			localStorage.removeItem('businessId')
		}
	}, [])

	if (
		isLoading ||
		isPaymentsLoading ||
		isMainGraphStatsLoading ||
		isMainTextStatsLoading ||
		isPrevMainGraphStatsLoading
	)
		return <Spinner />

	return (
		<Container>
			<PageWrapper>
				{/*<WarningMessage warningType={Warnings.PAYMENT_RESTRICTED} />*/}
				{/*<WarningMessage warningType={Warnings.ACTION_REQUIRED} />*/}
				{/*{!isLoading && !settings?.directCryptoSettings?.isActive && (*/}
				{/*	<WarningMessage warningType={Warnings.CONNECT_WALLET} />*/}
				{/*)}*/}
				{/*{!isPaymentsLoading && payments && payments?.length < 1 && (*/}
				{/*	<WarningMessage warningType={Warnings.CREATE_PAYMENT} />*/}
				{/*)}*/}
				{/*<WarningMessage warningType={Warnings.INVITE_TEAM} />*/}
				<div className={styles.homeIntro}>
					{isTopGatewaysModalActive && (
						<Modal
							closeModal={() =>
								closeModal(setIsTopGatewaysModalActive, setIsTopGatewaysModalVisible)
							}
							visible={isTopGatewaysModalVisible}
							setVisible={setIsTopGatewaysModalVisible}
						>
							<TopGatewaysModal
								close={() => closeModal(setIsTopGatewaysModalActive, setIsTopGatewaysModalVisible)}
								topGateways={mainTextStatsData?.topGateways}
							/>
						</Modal>
					)}
					<header className={styles.header}>
						<div>
							<h2>Welcome back, {business?.name}</h2>
							<p>Here’s a quick overview of what’s been happening with your payments account</p>
						</div>
						<div className={styles.dates}>
							<Select
								value={dateSelectValue}
								setValue={value => handleDateSelect(value as SelectDates)}
								style={SelectStyles.secondary}
							>
								<option value={SelectDates.lastDay}>Last 24 hours</option>
								<option value={SelectDates.lastWeek}>Last week</option>
								<option value={SelectDates.lastMonth}>Last month</option>
								<option value={SelectDates.lastYear}>Last year</option>
							</Select>
							<DateRangePicker
								fromDate={fromDate}
								toDate={toDate}
								setToDate={setToDate}
								setFromDate={setFromDate}
							/>
						</div>
					</header>

					<div className={styles.body}>
						{/*<div className={styles.miniStatsRow}>*/}
						{/*	<div className={styles.miniStat}>*/}
						{/*		<div>*/}
						{/*			<span>USD balance</span>*/}
						{/*			<h2>$0.00</h2>*/}
						{/*			<span>Estimated future payouts</span>*/}
						{/*		</div>*/}
						{/*		<p>View</p>*/}
						{/*	</div>*/}
						{/*	<div className={styles.vertLine} />*/}
						{/*	<div className={styles.miniStat}>*/}
						{/*		<div>*/}
						{/*			<span>Payouts</span>*/}
						{/*			<h2>$220.00</h2>*/}
						{/*			<span>Deposited Feb 20, 2023</span>*/}
						{/*		</div>*/}
						{/*		<p>View</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
						<div className={styles.statsRow}>
							<StatItem
								title={'Gross volume'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: graphStat.grossRevenue || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								isMoney={true}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: graphStat.grossRevenue || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
							<div className={styles.vertLine} />
							<StatItem
								title={'Net volume'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: graphStat.netRevenue || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								isMoney={true}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: graphStat.netRevenue || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
							<div className={styles.vertLine} />
							<div className={styles.linkStats}>
								<Link to={'/payments'} className={styles.linkStat}>
									<h3>Total payments</h3>

									<span>
										{mainTextStatsData?.topGateways
											.map(topGateway => topGateway.paymentsCount)
											.reduce((a, b) => a + b, 0)}
									</span>
									<ArrowHeadSVG />
								</Link>
								<a className={styles.linkStat} onClick={() => setIsTopGatewaysModalActive(true)}>
									<h3>Top payment method</h3>

									<span>
										{mainTextStatsData?.topGateways.length
											? getPaymentMethodName(mainTextStatsData?.topGateways[0].paymentMethod)
											: '-'}
									</span>
									<ArrowHeadSVG />
								</a>
								{mainTextStatsData?.topCustomer && (
									<Link
										to={`/customers?customerId=${mainTextStatsData.topCustomer.id}`}
										className={styles.linkStat}
									>
										<h3>Top customer</h3>

										<span>{mainTextStatsData?.topCustomer.email}</span>
										<ArrowHeadSVG />
									</Link>
								)}

								<a className={styles.linkStat}>
									<h3>High-risk payments</h3>

									<span>0</span>
									{/*<ArrowHeadSVG />*/}
								</a>
								<Link to={'/customers'} className={styles.linkStat}>
									<h3>Total customers</h3>

									<span>{mainTextStatsData?.totalCustomers}</span>
									<ArrowHeadSVG />
								</Link>
							</div>
						</div>
						<div className={styles.statsRow}>
							<StatItem
								title={'New customers'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: graphStat.customersCount || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: graphStat?.customersCount || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
							<div className={styles.vertLine} />
							<StatItem
								title={'Spend per customer'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: graphStat.spendPerCustomer || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								isAverage={true}
								isMoney={true}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: graphStat.spendPerCustomer || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
							<div className={styles.vertLine} />
							<StatItem
								title={'Successful payments'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: graphStat.paymentsCount || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: graphStat.paymentsCount || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
						</div>
						<div className={styles.statsRow}>
							<StatItem
								title={'Dispute activity'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
							<div className={styles.vertLine} />
							<StatItem
								title={'Failed payments'}
								data={mainGraphStatsData?.map(graphStat => ({
									count: graphStat.failedPayments || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								fromDate={fromDate}
								toDate={toDate}
								prevData={prevMainGraphStats?.map(graphStat => ({
									count: graphStat.failedPayments || 0,
									dateOrHour: graphStat.dateOrHour,
								}))}
								durationMs={durationMs}
							/>
						</div>
					</div>

					{/*{isMainStatLoading ? (*/}
					{/*	<Spinner />*/}
					{/*) : (*/}
					{/*	<div className={styles.body}>*/}
					{/*		<div className={styles.statWrapper}>*/}
					{/*			<StatItem*/}
					{/*				title={'GROSS REVENUE'}*/}
					{/*				data={mainData?.revenueChartDate.map(revenue => ({*/}
					{/*					count: revenue.totalAmount,*/}
					{/*					dateOrHour: revenue.dateOrHour,*/}
					{/*				}))}*/}
					{/*				isMoney={true}*/}
					{/*				fromDate={fromDate}*/}
					{/*				toDate={toDate}*/}
					{/*				prevData={prevMainStat?.revenueChartDate.map(revenue => ({*/}
					{/*					count: revenue.totalAmount,*/}
					{/*					dateOrHour: revenue.dateOrHour,*/}
					{/*				}))}*/}
					{/*				durationMs={durationMs}*/}
					{/*			/>*/}
					{/*		</div>*/}
					{/*		<div className={styles.paymentMethods}>*/}
					{/*			<h3>TOP PAYMENT METHODS</h3>*/}
					{/*			{mainData?.topGateways.map(gateway => (*/}
					{/*				<div className={styles.paymentMethodsItem} key={gateway.paymentMethod}>*/}
					{/*					<div className={styles.payment}>*/}
					{/*						<img*/}
					{/*							src={getPaymentMethodIcon(gateway.paymentMethod)}*/}
					{/*							alt={getPaymentMethodName(gateway.paymentMethod)}*/}
					{/*						/>*/}
					{/*						<span>{getPaymentMethodName(gateway.paymentMethod)}</span>*/}
					{/*					</div>*/}
					{/*					<p>{gateway.paymentsCount} payments</p>*/}
					{/*					<p>${gateway.revenue.toFixed(2)}</p>*/}
					{/*				</div>*/}
					{/*			))}*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*)}*/}
				</div>
			</PageWrapper>
		</Container>
	)
}

export default HomePage
