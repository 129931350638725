import React, { FC, SVGProps } from 'react'

const CheckedSvg: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='17'
			height='14'
			viewBox='0 0 17 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M0.985107 8.5019L4.82974 12.7737L15.5093 1.66699'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CheckedSvg
