import { privateAPI } from './API'
import { Cryptocurrency } from '../types/Cryptocurrencies'
import { ScriptPubKeyType } from '../types/ScriptPubKeyType'

export const BusinessSettingsPayments = {
	updateDirectCrypto: async (
		businessId: number | undefined,
		cryptoName: Cryptocurrency,
		address: string,
		requiredConfirmations: number
	) => {
		return await privateAPI.put(
			`v1/dash/businesses/${businessId}/settings/payments/direct-crypto/${cryptoName}`,
			{
				address,
				requiredConfirmations,
			}
		)
	},
	updateXPubCrypto: async (
		businessId: number | undefined,
		cryptoName: Cryptocurrency,
		xPub: string,
		requiredConfirmations: number,
		percentagesUnderpaymentThreshold: number | null,
		scriptType: ScriptPubKeyType
	) => {
		return await privateAPI.put(
			`v1/dash/businesses/${businessId}/settings/payments/xpub-crypto/${cryptoName}`,
			{
				xPub,
				requiredConfirmations,
				percentagesUnderpaymentThreshold,
				scriptType,
			}
		)
	},
	updateOnRampCrypto: async (
		businessId: number | undefined,
		cryptos: {
			cryptoCoinName: Cryptocurrency
			xPub: string
			scriptPubKeyType: ScriptPubKeyType
		}[]
	) => {
		return await privateAPI.put(
			`v1/dash/businesses/${businessId}/settings/payments/onramp-crypto`,
			{
				cryptos,
			}
		)
	},
}
