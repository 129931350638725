import React, { FC } from 'react'
import styles from './Radio.module.scss'

interface RadioProps {
	id: string
	value: string
	setValue: (value: string) => void
	checked: boolean
}

const Radio: FC<RadioProps> = ({ checked, value, setValue, id }) => {
	return (
		<div className={styles.customRadio}>
			<input
				type='radio'
				id={id}
				value={value}
				onChange={event => setValue(event.target.value)}
				checked={checked}
			/>
			<label htmlFor={id}></label>
		</div>
	)
}

export default Radio
