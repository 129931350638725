import React from 'react'

const ClockIcon = () => {
	return (
		<svg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.42346 0.955078H9.92346M3.63346 4.52508H7.71346M1.42346 10.8151H9.92346M2.10346 0.955078H9.24346V1.80508C9.24346 3.77673 7.64512 5.37508 5.67346 5.37508C3.70181 5.37508 2.10346 3.77674 2.10346 1.80508V0.955078ZM2.10346 10.8151H9.24346V9.62508C9.24346 7.65342 7.64512 6.05508 5.67346 6.05508C3.70181 6.05508 2.10346 7.65342 2.10346 9.62508V10.8151Z'
				stroke='#6B7384'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default ClockIcon
