import React from 'react'
import styles from './Checkbox.module.scss'

interface CheckboxProps {
	id: string
	checked: boolean
	setChecked: (value: boolean) => void
	text: string
}

const Checkbox: React.FC<CheckboxProps> = ({ id, setChecked, checked, text }) => {
	return (
		<div className={styles.customCheckbox}>
			<input
				type='checkbox'
				id={id}
				checked={checked}
				onChange={event => setChecked(event.target.checked)}
			/>
			<label htmlFor={id}>
				<span>{text}</span>
			</label>
		</div>
	)
}

export default Checkbox
