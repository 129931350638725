import React from 'react'

const ConfirmingStatusIcon = () => {
	return (
		<svg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.54346 0.807983C2.84657 0.807983 0.648926 3.00563 0.648926 5.70251C0.648926 8.3994 2.84657 10.597 5.54346 10.597C8.24034 10.597 10.438 8.3994 10.438 5.70251C10.438 3.00563 8.24034 0.807983 5.54346 0.807983ZM7.67258 7.44986C7.64807 7.49157 7.61552 7.528 7.57682 7.55702C7.53812 7.58605 7.49403 7.60709 7.44713 7.61893C7.40022 7.63077 7.35143 7.63317 7.30359 7.62599C7.25575 7.61882 7.20981 7.6022 7.16844 7.57712L5.65114 6.67163C5.27426 6.44648 4.99527 5.95214 4.99527 5.51652V3.50976C4.99527 3.30909 5.16168 3.14267 5.36236 3.14267C5.56304 3.14267 5.72945 3.30909 5.72945 3.50976V5.51652C5.72945 5.69273 5.87629 5.95214 6.02802 6.04024L7.54532 6.94573C7.72152 7.04851 7.78026 7.27366 7.67258 7.44986Z'
				fill='#2D55B9'
			/>
		</svg>
	)
}

export default ConfirmingStatusIcon
