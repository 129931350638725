import React, { FC } from 'react'

interface ProfileIconProps {
	onClick?: () => void
}

const ProfileIcon: FC<ProfileIconProps> = ({ onClick }) => {
	return (
		<svg
			width='16'
			height='17'
			viewBox='0 0 16 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			onClick={onClick}
		>
			<path
				d='M7.62131 7.83936C9.41623 7.83936 10.8713 6.38428 10.8713 4.58936C10.8713 2.79443 9.41623 1.33936 7.62131 1.33936C5.82638 1.33936 4.37131 2.79443 4.37131 4.58936C4.37131 6.38428 5.82638 7.83936 7.62131 7.83936Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2.46881 15.8394H12.7738C13.9157 15.8394 14.7953 14.8575 14.2621 13.8478C13.4776 12.3625 11.6893 10.5894 7.62131 10.5894C3.55332 10.5894 1.76498 12.3625 0.980549 13.8478C0.447279 14.8575 1.32689 15.8394 2.46881 15.8394Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default ProfileIcon
