import React from 'react'

const PhoneSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.25 1.27936C12.6812 1.97562 14.5896 3.90944 15.25 6.3556M5.14286 1H2.31818C1.59017 1 1 1.59017 1 2.31818C1 9.5983 6.9017 15.5 14.1818 15.5C14.9098 15.5 15.5 14.9098 15.5 14.1818V11.3571L12.3929 9.2857L10.7817 10.8968C10.5019 11.1767 10.0837 11.2637 9.7321 11.0821C9.1358 10.774 8.1681 10.1952 7.2143 9.2857C6.23768 8.3545 5.66548 7.3511 5.37829 6.744C5.21734 6.4037 5.31052 6.01091 5.57669 5.74474L7.2143 4.10714L5.14286 1Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PhoneSvg
