export enum WebhookMessageEventStatusEnum {
	SUCCESS = 'SUCCESS',
	PENDING = 'PENDING',
	FAIL = 'FAIL',
	SENDING = 'SENDING',
}

export type WebhookMessageEventStatus =
	| WebhookMessageEventStatusEnum.FAIL
	| WebhookMessageEventStatusEnum.PENDING
	| WebhookMessageEventStatusEnum.SENDING
	| WebhookMessageEventStatusEnum.SUCCESS
