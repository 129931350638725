import React, { FC } from 'react'
import styles from './TopGateways.module.scss'
import { TopGateway } from '../../types/TopGateway'
import { getPaymentMethodIconId } from '../../utils/getPaymentMethodIconId'
import { getPaymentMethodName } from '../../utils/getPaymentMethodName'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'

interface TopGatewaysProps {
	gateways?: TopGateway[]
}

const TopGateways: FC<TopGatewaysProps> = ({ gateways }) => {
	return (
		<div className={styles.topGateways}>
			{gateways?.map(gateway => (
				<div className={styles.gatewayItem}>
					<div className={styles.gateway}>
						<SpriteIcon
							iconId={getPaymentMethodIconId(gateway.paymentMethod) || ''}
							width={16}
							height={16}
						/>
						<span>{getPaymentMethodName(gateway.paymentMethod)}</span>
					</div>
					<p>
						{gateway.paymentsCount} invoice{gateway.paymentsCount > 1 ? 's' : ''}
					</p>
					<p>{gateway.revenue.toFixed(2)}</p>
				</div>
			))}
		</div>
	)
}

export default TopGateways
