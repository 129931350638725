import React, { FC, useState } from 'react'
import styles from './FormComponents.module.scss'
import CloseButton from '../CloseButton/CloseButton'
import UploadSVG from '../../../images/UploadSVG'

interface FileInputProps {
	text: string
	file: File | null
	setFile: (file: File | null) => void
}

const FileInput: FC<FileInputProps> = ({ setFile, text, file }) => {
	return (
		<label className={styles.fileInput}>
			<div className={styles.icons}>
				<UploadSVG />
				{file && (
					<CloseButton
						style={{ marginLeft: 10 }}
						onClick={event => {
							event.preventDefault()
							setFile(null)
						}}
					/>
				)}
			</div>
			<span>{file ? file.name : text}</span>

			<input
				type='file'
				onChange={event => {
					setFile(event.target.files ? event.target.files[0] : null)
				}}
			/>
		</label>
	)
}

export default FileInput
