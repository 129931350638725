import React, { FC } from 'react'
import styles from './RequestMethodBadge.module.scss'

interface RequestMethodBadgeProps {
	method: string
}

const RequestMethodBadge: FC<RequestMethodBadgeProps> = ({ method }) => {
	return <div className={styles.requestMethodBadge}>{method}</div>
}

export default RequestMethodBadge
