import React, { FC } from 'react'
import styles from './Logo.module.scss'
import HoodpayLogo from '../../../images/HoodpayLogo'
import HoodpaySignatureLogo from '../../../images/HoodpaySignatureLogo'
import { useNavigate } from 'react-router-dom'

interface LogoProps {
	isSmall?: boolean
}

const Logo: FC<LogoProps> = ({ isSmall }) => {
	const navigate = useNavigate()

	return (
		<div className={`${styles.logo} ${isSmall ? styles.small : ''}`} onClick={() => navigate('/')}>
			<HoodpayLogo />
			<HoodpaySignatureLogo />
		</div>
	)
}

export default Logo
