import React from 'react'

const PlusIcon = () => {
	return (
		<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.73242 1.03027V13.5303M13.9824 7.28027H1.48242'
				stroke='#305AD6'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PlusIcon
