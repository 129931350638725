import React, { useEffect, useState } from 'react'
import styles from './GlobalSearch.module.scss'
import SearchInput from '../UI/SearchInput/SearchInput'
import QuestionMarkSVG from '../../images/QuestionMarkSVG'
import { useAPI } from '../../hooks/useAPI'
import { BusinessesCustomersService } from '../../API/DashboardSearchService'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../UI/Spinner/Spinner'
import debounce from 'lodash.debounce'
import PaymentsTableBody from '../PaymentsTable/PaymentsTableBody'
import { Statuses } from '../../types/Status'
import CustomersTableBody from '../CustomersTable/CustomersTableBody'
import { Payment } from '../../types/Payment'
import { Customer } from '../../types/Customer'
import { useSearchParams } from 'react-router-dom'
import { SearchCustomer } from '../../types/SearchCustomer'
import CustomersSearchTableBody from '../CustomersTable/CustomersSearchTableBody'
import XSVG from '../../images/XSVG'

const GlobalSearch = () => {
	const { business } = useAppContext()
	const [searchValue, setSearchValue] = useState('')
	const [isVisible, setIsVisible] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()

	const [isSearchLoading, searchResult, search] = useAPI<{
		payments: Payment[]
		customers: SearchCustomer[]
	}>(() => BusinessesCustomersService.search(business?.id, searchValue), false)

	const debouncedSearch = debounce(search, 400)

	const closeGlobalSearch = () => {
		setIsVisible(false)
		setTimeout(() => {
			setIsActive(false)
		}, 200)
	}

	useEffect(() => {
		if (isActive) {
			setTimeout(() => {
				setIsVisible(true)
			}, 10)
		}
	}, [isActive])

	useEffect(() => {
		if (isActive) {
			debouncedSearch()
		}
	}, [searchValue])

	return (
		<>
			<div
				className={`${styles.background} ${isActive && styles.active} ${
					isVisible && styles.visible
				}`}
				onClick={closeGlobalSearch}
			/>
			<div
				className={`${styles.globalSearch} ${isActive && styles.active} ${
					isVisible && styles.visible
				}`}
			>
				<div className={styles.front} onClick={() => setIsActive(true)}>
					<SearchInput
						value={searchValue}
						setValue={setSearchValue}
						placeholder={'Search for payments, payouts, and more'}
					/>
					<XSVG className={styles.xSvg} onClick={closeGlobalSearch} />
				</div>

				<div className={styles.searchResult}>
					{isSearchLoading && <Spinner />}
					{searchResult?.payments.length ? (
						<section className={styles.resultSection}>
							<h2>PAYMENTS</h2>
							<div className={styles.results}>
								{searchResult.payments.map(payment => (
									<PaymentsTableBody key={payment.id} payment={payment} search={true} />
								))}
							</div>
						</section>
					) : null}

					{searchResult?.customers.length ? (
						<section className={styles.resultSection}>
							<h2>CUSTOMERS</h2>
							<div className={styles.results}>
								{searchResult.customers.map(customer => (
									<CustomersSearchTableBody customer={customer} />
								))}
							</div>
						</section>
					) : null}
					{!searchResult?.payments.length && !searchResult?.customers.length && (
						<div className={styles.noResults}>
							<QuestionMarkSVG />
							<h1>No search results</h1>
							<p>We couldn’t find any results for what you were searching for.</p>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default GlobalSearch
