import React, { FC, HTMLProps } from 'react'
import styles from './CloseButton.module.scss'

interface CloseButtonProps {
	color?: string
}

const CloseButton: FC<CloseButtonProps | HTMLProps<HTMLDivElement>> = ({ color, ...props }) => {
	return <div {...props} className={`${styles.close} ${color && styles[color]}`} />
}

export default CloseButton
