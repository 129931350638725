import React, { FC, useEffect, useState } from 'react'
import styles from './SettingsCryptocurrencyPage.module.scss'
import CryptocurrencyConfig from '../../../components/CryptocurrencyConfig/CryptocurrencyConfig'
import { Cryptocurrencies, Cryptocurrency } from '../../../types/Cryptocurrencies'
import SideModal from '../../../components/SideModals/SideModal'
import { closeModal } from '../../../utils/CloseModal'
import { SettingsPageProps } from '../../../types/SettingsPageProps'
import settingsStyles from '../Settings.module.scss'
import Modal from '../../../components/Modals/Modal'
import CryptocurrencyModal from '../../../components/Modals/PaymentMethodSideModals/CryptocurrencyModal'

const SettingsCryptocurrencyPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	const [currentCryptocurrency, setCurrentCryptocurrency] = useState<{
		cryptocurrency: Cryptocurrency
		networks?: {
			ethereum: Cryptocurrency
			tron: Cryptocurrency
		}
	}>({
		cryptocurrency: Cryptocurrencies.BITCOIN,
	})
	const [isConfigCryptoModalActive, setIsConfigCryptoModalActive] = useState(false)
	const [isConfigCryptoModalVisible, setIsConfigCryptoModalVisible] = useState(false)

	return (
		<div className={styles.settingsBillingPage}>
			{isConfigCryptoModalActive && (
				<Modal
					closeModal={() => closeModal(setIsConfigCryptoModalActive, setIsConfigCryptoModalVisible)}
					visible={isConfigCryptoModalVisible}
					setVisible={setIsConfigCryptoModalVisible}
				>
					<CryptocurrencyModal
						settings={settings}
						cryptocurrency={currentCryptocurrency}
						getSettings={getSettings}
					/>
				</Modal>
			)}
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Cryptocurrency</h1>
						<p>
							To accept cryptocurrency payments, connect your addresses below. You will receive
							payments directly to your crypto wallet with zero network fees. Hoodpay does not
							custody your crypto.
						</p>
					</div>
				</header>
				<div className={styles.cryptocurrencies}>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.BITCOIN}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETHEREUM}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.LITECOIN}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					{/*<CryptocurrencyConfig*/}
					{/*	cryptosSettings={settings?.directCryptoSettings?.cryptos}*/}
					{/*	cryptocurrency={Cryptocurrencies.BITCOIN_CASH}*/}
					{/*	setCurrentCryptocurrency={setCurrentCryptocurrency}*/}
					{/*	setIsCryptoModalActive={setIsConfigCryptoModalActive}*/}
					{/*/>*/}
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_USD_COIN}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
						networks={{
							ethereum: Cryptocurrencies.ETH_USD_COIN,
							tron: Cryptocurrencies.TRX_USD_C,
						}}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_TETHER}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
						networks={{
							ethereum: Cryptocurrencies.ETH_TETHER,
							tron: Cryptocurrencies.TRX_TETHER,
						}}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_BNB}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_MATIC}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_CRONOS}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_SHIBA_INU}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_APE_COIN}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_DAI}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.ETH_UNISWAP}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					<CryptocurrencyConfig
						settings={settings}
						cryptocurrency={Cryptocurrencies.TRON}
						setCurrentCryptocurrency={setCurrentCryptocurrency}
						setIsCryptoModalActive={setIsConfigCryptoModalActive}
					/>
					{/*<CryptocurrencyConfig*/}
					{/*	cryptosSettings={settings?.directCryptoSettings?.cryptos}*/}
					{/*	cryptocurrency={Cryptocurrencies.MONERO}*/}
					{/*	setCurrentCryptocurrency={setCurrentCryptocurrency}*/}
					{/*	setIsCryptoModalActive={setIsConfigCryptoModalActive}*/}
					{/*/>*/}
				</div>
			</section>

			{/*<div className={styles.fieldBox}>*/}
			{/*	<header>*/}
			{/*		<div>*/}
			{/*			<h2>Underpayment Threshold</h2>*/}
			{/*			<p>*/}
			{/*				Choose the minimum payment you’ll accept based on the percent (%) or absolute ($)*/}
			{/*				amount*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*		<div className={styles.supportsCrypto}>*/}
			{/*			<p>Supports:</p>*/}
			{/*			<div className={styles.cryptos}>*/}
			{/*				<img src={bitcoinIcon} alt='bitcoin' />*/}
			{/*				<img src={litecoinIcon} alt='litecoin' />*/}
			{/*				<img src={bitcoinCashIcon} alt='bitcoin cash' />*/}
			{/*				<img src={moneroIcon} alt='monero' />*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</header>*/}
			{/*	<div className={styles.fieldBoxBody}>*/}
			{/*		<div className={styles.thresholdFields}>*/}
			{/*			<div className={styles.thresholdField}>*/}
			{/*				<h3>Absolute</h3>*/}
			{/*				<p>Set $0.10 if you’d accept a $9.90 payment for a $10.00 charge</p>*/}
			{/*				<Input*/}
			{/*					value={underpaymentThresholdValues.absolute}*/}
			{/*					setValue={value =>*/}
			{/*						setUnderpaymentThresholdValues({*/}
			{/*							...underpaymentThresholdValues,*/}
			{/*							absolute: value,*/}
			{/*						})*/}
			{/*					}*/}
			{/*					placeholder={'$0.10'}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*			<div className={styles.thresholdField}>*/}
			{/*				<h3>Relative</h3>*/}
			{/*				<p>Set 0.01 (1%) if you’d accept a $99 payment for a $100 charge</p>*/}
			{/*				<Input*/}
			{/*					value={underpaymentThresholdValues.relative}*/}
			{/*					setValue={value =>*/}
			{/*						setUnderpaymentThresholdValues({*/}
			{/*							...underpaymentThresholdValues,*/}
			{/*							relative: value,*/}
			{/*						})*/}
			{/*					}*/}
			{/*					placeholder={'0.01'}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div*/}
			{/*		className={`${styles.buttonWrapper} ${*/}
			{/*			fieldsUnchangedValues.underpaymentThresholdValues.absolute !==*/}
			{/*				underpaymentThresholdValues.absolute ||*/}
			{/*			fieldsUnchangedValues.underpaymentThresholdValues.relative !==*/}
			{/*				underpaymentThresholdValues.relative*/}
			{/*				? styles.active*/}
			{/*				: ''*/}
			{/*		}`}*/}
			{/*	>*/}
			{/*		<Button style={ButtonStyles.SECONDARY}>Save</Button>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<section className={styles.section}>*/}
			{/*	<header className={styles.sectionHeader}>*/}
			{/*		<div className={styles.sectionTitle}>*/}
			{/*			<h1>xPub Crypto</h1>*/}
			{/*			/!*<div className={styles.recommendedBadge}>Recommended</div>*!/*/}
			{/*		</div>*/}
			{/*		<p>*/}
			{/*			Manually link your xpub/public key from your wallet. Hoodpay will automatically generate*/}
			{/*			a crypto address on your behalf for you to receive customer funds. This payment method*/}
			{/*			supports threshold management and is strongly recommended for use with Bitcoin, Bitcoin*/}
			{/*			Cash, Litecoin, and Monero. <a href='#'>Learn more about xpub crypto.</a>*/}
			{/*		</p>*/}
			{/*	</header>*/}
			{/*	<div className={styles.cryptocurrencies}>*/}
			{/*		<CryptocurrencyConfig*/}
			{/*			cryptocurrency={Cryptocurrencies.BITCOIN}*/}
			{/*			setCurrentCryptocurrency={setCurrentCryptocurrency}*/}
			{/*			setIsCryptoModalActive={setIsConfigCryptoModalActive}*/}
			{/*			xPub={true}*/}
			{/*		/>*/}

			{/*		<CryptocurrencyConfig*/}
			{/*			cryptocurrency={Cryptocurrencies.LITECOIN}*/}
			{/*			setCurrentCryptocurrency={setCurrentCryptocurrency}*/}
			{/*			setIsCryptoModalActive={setIsConfigCryptoModalActive}*/}
			{/*			xPub={true}*/}
			{/*		/>*/}
			{/*		<CryptocurrencyConfig*/}
			{/*			cryptocurrency={Cryptocurrencies.MONERO}*/}
			{/*			setCurrentCryptocurrency={setCurrentCryptocurrency}*/}
			{/*			setIsCryptoModalActive={setIsConfigCryptoModalActive}*/}
			{/*			xPub={true}*/}
			{/*		/>*/}
			{/*		<CryptocurrencyConfig*/}
			{/*			cryptocurrency={Cryptocurrencies.BITCOIN_CASH}*/}
			{/*			setCurrentCryptocurrency={setCurrentCryptocurrency}*/}
			{/*			setIsCryptoModalActive={setIsConfigCryptoModalActive}*/}
			{/*			xPub={true}*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*</section>*/}

			{/*<div className={styles.fieldBox}>*/}
			{/*	<header>*/}
			{/*		<div>*/}
			{/*			<h2>Digital Currencies</h2>*/}
			{/*			<p>*/}
			{/*				Accept payments via debit/credit cards and get paid out directly in crypto (only*/}
			{/*				available for Verified businesses)*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*		<ComingSoonBadge />*/}
			{/*	</header>*/}
			{/*</div>*/}
		</div>
	)
}

export default SettingsCryptocurrencyPage
