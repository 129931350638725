import { privateAPI } from './API'

export const BusinessSettingsCheckoutService = {
	updateCoverProcessingFee: async (businessId: number | undefined, coverProcessingFee: boolean) => {
		return await privateAPI.put(
			`v1/dash/businesses/${businessId}/settings/checkout/cover-processing-fee`,
			{ coverProcessingFee }
		)
	},
	updateTransactionalEmails: async (
		businessId: number | undefined,
		transactionalEmails: boolean
	) => {
		return await privateAPI.put(
			`v1/dash/businesses/${businessId}/settings/checkout/transactional-emails`,
			{ transactionalEmails }
		)
	},
	updateRedirectUrl: async (businessId: number | undefined, redirectUrl: string | null) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/checkout/redirect-url`, {
			redirectUrl,
		})
	},
}
