import { privateAPI } from './API'

export const UserService = {
	get: async () => {
		return await privateAPI.get(`/v1/dash/user`)
	},
	logout: async () => {
		return await privateAPI.post(`/v1/dash/user/logout`)
	},
}
