import React from 'react'

const WarningWalletSvg = () => {
	return (
		<svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18.0953 3.3877C17.6653 1.2177 16.0553 0.2677 13.8153 0.2677H5.03525C2.39525 0.2677 0.635254 1.5877 0.635254 4.6677V9.8177C0.635254 12.0377 1.54525 13.3377 3.04525 13.8977C3.26525 13.9777 3.50525 14.0477 3.75525 14.0877C4.15525 14.1777 4.58525 14.2177 5.03525 14.2177H13.8253C16.4653 14.2177 18.2253 12.8977 18.2253 9.8177V4.6677C18.2253 4.1977 18.1853 3.7777 18.0953 3.3877ZM4.45525 8.7477C4.45525 9.1577 4.11525 9.4977 3.70525 9.4977C3.29525 9.4977 2.95525 9.1577 2.95525 8.7477V5.7477C2.95525 5.3377 3.29525 4.9977 3.70525 4.9977C4.11525 4.9977 4.45525 5.3377 4.45525 5.7477V8.7477ZM9.42525 9.8877C7.96525 9.8877 6.78525 8.7077 6.78525 7.2477C6.78525 5.7877 7.96525 4.6077 9.42525 4.6077C10.8853 4.6077 12.0653 5.7877 12.0653 7.2477C12.0653 8.7077 10.8853 9.8877 9.42525 9.8877ZM15.8853 8.7477C15.8853 9.1577 15.5453 9.4977 15.1353 9.4977C14.7253 9.4977 14.3853 9.1577 14.3853 8.7477V5.7477C14.3853 5.3377 14.7253 4.9977 15.1353 4.9977C15.5453 4.9977 15.8853 5.3377 15.8853 5.7477V8.7477Z'
				fill='#A7E23D'
			/>
			<path
				d='M21.2273 7.6657V12.8157C21.2273 15.8957 19.4673 17.2257 16.8173 17.2257H8.03725C7.28725 17.2257 6.61725 17.1157 6.03725 16.8957C5.56725 16.7257 5.15725 16.4757 4.82725 16.1557C4.64725 15.9857 4.78725 15.7157 5.03725 15.7157H13.8173C17.5173 15.7157 19.7173 13.5157 19.7173 9.8257V4.6657C19.7173 4.4257 19.9873 4.2757 20.1573 4.4557C20.8373 5.1757 21.2273 6.2257 21.2273 7.6657Z'
				fill='#A7E23D'
			/>
		</svg>
	)
}

export default WarningWalletSvg
