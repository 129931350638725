import React, { Component, ErrorInfo, ReactNode } from 'react'
import styles from './ErrorBoundary.module.scss'

interface Props {
	children?: ReactNode
}

interface State {
	hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	}

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo)
	}

	public render() {
		if (this.state.hasError) {
			return (
				<div className={styles.errorBoundary}>
					<h1>Unhandled Error</h1>
					<p>Sorry... there was an error, we'll fix this as soon as possible</p>
				</div>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
