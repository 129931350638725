import React, { FC } from 'react'
import styles from './Modals.module.scss'
import Button, { ButtonStyles } from '../UI/Button/Button'

interface DeactivateAccountSideModalProps {
	closeModal: () => void
}

const DeactivateAccountModal: FC<DeactivateAccountSideModalProps> = ({ closeModal }) => {
	return (
		<div className={styles.modalInner}>
			<h1 className={styles.h1}>Deactivate account</h1>
			<p className={styles.description}>Are you sure you want to deactivate your account?</p>
			<p className={styles.description}>
				Once you deactivate your account, you will no longer get access to payments, fraud
				prevention, compliance, or customer support.
			</p>
			<p className={styles.description}>
				You will be required to create a new account to begin accepting payments with Hoodpay.
			</p>

			<div className={styles.warning}>
				NOTICE: Access to the payments dashboard will also be revoked instantly.
			</div>

			<div className={styles.buttons}>
				<Button style={ButtonStyles.DANGER} width={'70%'}>
					No, don’t deactivate
				</Button>
				<Button width={'28%'}>Yes, continue</Button>
			</div>
		</div>
	)
}

export default DeactivateAccountModal
