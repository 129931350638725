import React, { FC, useState } from 'react'
import styles from './CustomersTable.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import CopyIcon from '../../images/CopyIcon'
import DollarSVG from '../../images/DollarSVG'
import { Payment } from '../../types/Payment'
import getSymbolFromCurrency from 'currency-symbol-map'
import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { useCopy } from '../../hooks/useCopy'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { Customer } from '../../types/Customer'
import { cleanEmail } from '../../utils/cleanEmail'

interface PaymentsTableBodyProps {
	customer: Customer
	setSearchParams: (value: URLSearchParams) => void
	searchParams: URLSearchParams
	search?: boolean
}

const CustomersTableBody: FC<PaymentsTableBodyProps> = ({
	customer,
	setSearchParams,
	searchParams,
	search,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const copyToClipboard = useCopy()
	const cleanedEmail = cleanEmail(customer.email)

	const customerViewModalHandler = () => {
		searchParams.set('customerId', customer.id.toString())
		setSearchParams(searchParams)
	}

	return (
		<div className={`${styles.tableBodyWrapper} ${search && styles.search}`}>
			<div className={styles.tableBody} onClick={customerViewModalHandler}>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<div className={styles.customer}>
						<div className={styles.customerAva}>
							{cleanedEmail[0]}
							{cleanedEmail[cleanedEmail.length - 1]}
						</div>
						<div>
							<h4>{customer.name}</h4>
							<span className={styles.span}>{customer.email}</span>
						</div>
					</div>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>${customer.totalSpend.toFixed(2)}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{customer.totalPayments}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{format(new Date(customer.firstSeen), 'MMM dd, yyyy')}</p>
					<span className={styles.span}>{format(new Date(customer.firstSeen), 'HH:mm aa z')}</span>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.settings}`}>
					<div className={styles.actionMenuWrapper}>
						<SettingsButton
							onClick={event => {
								event.stopPropagation()
								event.preventDefault()
								setIsActionMenuActive(!isActionMenuActive)
							}}
						/>
						<ActionMenu
							actionButtons={[
								{
									icon: <CopyIcon />,
									onClick: () => copyToClipboard(customer.id.toString()),
									title: 'Copy customer ID',
								},
								{
									icon: <DollarSVG />,
									onClick: customerViewModalHandler,
									title: 'View more details',
								},
							]}
							isActive={isActionMenuActive}
							setIsActive={setIsActionMenuActive}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CustomersTableBody
