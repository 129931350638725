import React, { useEffect } from 'react'
import styles from './SettingsBillingPage.module.scss'
import PaymentsTable from '../../../components/PaymentsTable/PaymentsTable'
import { useAppContext } from '../../../hooks/useAppContext'
import BillingBalance from '../../../components/BillingBalance/BillingBalance'
import { useAPI } from '../../../hooks/useAPI'
import { BusinessSettingsBillingService } from '../../../API/BusinessSettingsBillingService'
import Spinner from '../../../components/UI/Spinner/Spinner'
import settingsStyles from '../Settings.module.scss'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import OpenLinkSVG from '../../../images/OpenLinkSVG'
import Input from '../../../components/UI/FormComponents/Input'

const SettingsBillingPage = () => {
	const { business } = useAppContext()
	const [isBalanceLoading, balance, getBalance] = useAPI<{ amountUsd: number }>(
		() => BusinessSettingsBillingService.getBalance(business?.id),
		false
	)
	const isBalanceAmountNegative = balance?.amountUsd && balance.amountUsd < 0

	useEffect(() => {
		if (business?.id) {
			getBalance()
		}
	}, [business])

	if (isBalanceLoading) return <Spinner />

	return (
		<div className={styles.settingsBillingPage}>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Account balance</h1>
						<p>
							This is where you can view the available balance you have to cover and pay for Hoodpay
							services.
						</p>
					</div>
				</header>
				<div className={settingsStyles.fieldWrapper}>
					<h2>BALANCE</h2>
					<Input
						value={`${isBalanceAmountNegative ? '-' : ''}$${Math.abs(balance?.amountUsd || 0)}`}
						setValue={() => {}}
						disabled={true}
					/>
				</div>
			</section>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Fee payment history</h1>
						<p>
							If you owe us fees in crypto, we automatically send payments to cover that fee. You
							can view the history here.
						</p>
					</div>
				</header>
				<PaymentsTable businessId={business?.id} isFee={true} />
			</section>
		</div>
	)
}

export default SettingsBillingPage
