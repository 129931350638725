import React, { FC, useState } from 'react'
import styles from './PaymentMainDetails.module.scss'
import EtheriumIcon from '../../images/EtheriumIcon'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import { Statuses } from '../../types/Status'
import { PaymentDetailed } from '../../types/PaymentDetailed'
import getSymbolFromCurrency from 'currency-symbol-map'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import PenIcon from '../../images/PenIcon'
import { useCopy } from '../../hooks/useCopy'
import { getPaymentMethodIconId } from '../../utils/getPaymentMethodIconId'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import Button from '../UI/Button/Button'
import BanknoteSVG from '../../images/BanknoteSVG'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'

interface PaymentMainDetailsProps {
	payment?: PaymentDetailed
}

const PaymentMainDetails: FC<PaymentMainDetailsProps> = ({ payment }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const cryptoCharge =
		payment?.directCryptoCharge || payment?.xPubCryptoCharge || payment?.onRampCryptoCharge
	const copyToClipboard = useCopy()

	return (
		<div className={styles.paymentMainDetails}>
			<div className={styles.paymentMainDetailsRightSide}>
				<div className={styles.priceDetails}>
					<p>
						<BanknoteSVG /> PAYMENT
					</p>
					<h2>
						{getSymbolFromCurrency(payment?.currency || 'USD')}
						{payment?.endAmount.toFixed(2)}
					</h2>
					<span>
						{cryptoCharge?.amount} {getCryptoShortName(cryptoCharge?.coinName)}{' '}
						{cryptoCharge && (
							<SpriteIcon
								iconId={getPaymentMethodIconId(cryptoCharge?.coinName) || ''}
								width={15}
								height={15}
							/>
						)}
					</span>
				</div>
				<StatusBadge statusType={payment?.status || Statuses.AWAITING_PAYMENT} />
			</div>
			<div className={styles.paymentMainDetailsLeftSide}>
				{/*<Button>Issue refund</Button>*/}
				<div className={styles.actionMenu}>
					<SettingsButton onClick={() => setIsActionMenuActive(true)} />
					<ActionMenu
						actionButtons={[
							{
								icon: <PenIcon />,
								onClick: () => copyToClipboard(payment?.id || ''),
								title: 'Copy ID',
							},
						]}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</div>
			</div>
		</div>
	)
}

export default PaymentMainDetails
