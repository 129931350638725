import React, { FC } from 'react'
import styles from './TicketMessage.module.scss'

interface TicketMessageProps {
	isUsers: boolean
}

const TicketMessage: FC<TicketMessageProps> = ({ isUsers }) => {
	return (
		<div className={`${styles.ticketMessage} ${isUsers && styles.isUsers}`}>
			<div className={styles.messageWrapper}>
				<p>
					{'Hi I never got my product.\n' +
						'\n' +
						'GIVE ME MY PRODUCT\n' +
						'NOW\n' +
						'\n' +
						'OR ELSE'}
				</p>
				<time>3 days ago</time>
			</div>
			{isUsers ? (
				<div className={styles.user}>
					<span>UserName</span>
					<div className={styles.ava}></div>
				</div>
			) : (
				<div className={styles.customer}>H</div>
			)}
		</div>
	)
}

export default TicketMessage
