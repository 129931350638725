import React, { FC } from 'react'
import styles from './PaymentTimelineSection.module.scss'
import Button from '../UI/Button/Button'
import Timeline from '../Timeline/Timeline'
import RefreshIcon from '../../images/RefreshIcon'
import { CryptoCharge, PaymentTimeline } from '../../types/PaymentDetailed'
import { splitArrayByLength } from '../../utils/splitArrayByLength'

interface PaymentTimelineSectionProps {
	timeline?: PaymentTimeline[]
	getPayment: () => void
	cryptoCharge?: CryptoCharge
	openTransactionLinkHandler: () => void
}

const PaymentTimelineSection: FC<PaymentTimelineSectionProps> = ({
	timeline,
	getPayment,
	cryptoCharge,
	openTransactionLinkHandler,
}) => {
	const splitTimelines = timeline ? splitArrayByLength<PaymentTimeline>(timeline, 3) : []

	return (
		<>
			<div className={styles.paymentTimelineSection}>
				<header>
					<h2>Timeline</h2>
				</header>
				<div className={styles.paymentDetailTimelines}>
					{splitTimelines.map(
						timeline =>
							timeline && (
								<div className={styles.timelineWrapper}>
									<Timeline
										timeline={timeline}
										openTransactionLinkHandler={openTransactionLinkHandler}
									/>
								</div>
							)
					)}
				</div>
			</div>
		</>
	)
}

export default PaymentTimelineSection
