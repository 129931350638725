import React from 'react'

const CircleInfoIcon = () => {
	return (
		<svg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.93555 10.6991C7.33622 10.6991 8.67953 10.1427 9.66996 9.15226C10.6604 8.16183 11.2168 6.81852 11.2168 5.41785C11.2168 4.01717 10.6604 2.67387 9.66996 1.68344C8.67953 0.693013 7.33622 0.136597 5.93555 0.136597C4.53487 0.136597 3.19157 0.693013 2.20114 1.68344C1.21071 2.67387 0.654297 4.01717 0.654297 5.41785C0.654297 6.81852 1.21071 8.16183 2.20114 9.15226C3.19157 10.1427 4.53487 10.6991 5.93555 10.6991ZM6.54949 4.48571L5.03773 4.67517L4.9836 4.92603L5.28067 4.98082C5.47476 5.02703 5.51305 5.09701 5.4708 5.29044L4.9836 7.57986C4.85553 8.17202 5.05292 8.4506 5.51701 8.4506C5.87679 8.4506 6.29467 8.28425 6.48414 8.05583L6.54223 7.78121C6.4102 7.89739 6.21743 7.9436 6.08936 7.9436C5.90782 7.9436 5.8418 7.81619 5.88868 7.59174L6.54949 4.48571ZM5.93555 3.76746C6.11063 3.76746 6.27854 3.6979 6.40235 3.5741C6.52615 3.4503 6.5957 3.28238 6.5957 3.1073C6.5957 2.93222 6.52615 2.7643 6.40235 2.6405C6.27854 2.5167 6.11063 2.44714 5.93555 2.44714C5.76046 2.44714 5.59255 2.5167 5.46875 2.6405C5.34494 2.7643 5.27539 2.93222 5.27539 3.1073C5.27539 3.28238 5.34494 3.4503 5.46875 3.5741C5.59255 3.6979 5.76046 3.76746 5.93555 3.76746Z'
				fill='#6B7384'
			/>
		</svg>
	)
}

export default CircleInfoIcon
