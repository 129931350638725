import React, { useState } from 'react'
import BusinessDetailsForm from '../../components/AuthForms/BusinessDetailsForm'
import { useAPI } from '../../hooks/useAPI'
import { BusinessSettingsGeneralService } from '../../API/BusinessSettingsGeneralService'
import { BusinessesService } from '../../API/BusinessesService'

const AuthNewBusiness = () => {
	const [businessName, setBusinessName] = useState('')
	const [businessDetails, setBusinessDetails] = useState('')
	const [businessSiteUrl, setBusinessSiteUrl] = useState('')
	const [monthlyRecurringRevenue, setMonthlyRecurringRevenue] = useState('')
	const [businessLogo, setBusinessLogo] = useState<File | null>(null)

	const [isBusinessLogoLoading, , updateBusinessLogo] = useAPI(
		(businessId: number) => {
			const formData = new FormData()
			formData.append('file', businessLogo || 'null')
			return BusinessSettingsGeneralService.updateLogo(businessId, formData)
		},
		false,
		undefined,
		{
			onSuccess: () => {
				window.location.pathname = '/'
			},
		}
	)

	const [isCreateBusinessLoading, , createBusiness] = useAPI(
		(token: string) =>
			BusinessesService.create(
				{
					siteUrl: 'https://' + businessSiteUrl,
					name: businessName,
					details: businessDetails,
					mrr: monthlyRecurringRevenue || null,
				},
				token
			),
		false,
		'Successfully created',
		{
			onSuccess: response => {
				if (businessLogo) {
					updateBusinessLogo(response.data.data.id)
				} else {
					window.location.pathname = '/'
				}
			},
		}
	)

	return (
		<div>
			<BusinessDetailsForm
				createNewBusiness={createBusiness}
				isCreateNewBusinessLoading={isCreateBusinessLoading}
				businessDetails={businessDetails}
				businessName={businessName}
				businessLogo={businessLogo}
				monthlyRecurringRevenue={monthlyRecurringRevenue}
				setBusinessDetails={setBusinessDetails}
				businessSiteUrl={businessSiteUrl}
				setBusinessLogo={setBusinessLogo}
				setBusinessName={setBusinessName}
				setBusinessSiteUrl={setBusinessSiteUrl}
				setMonthlyRecurringRevenue={setMonthlyRecurringRevenue}
			/>
		</div>
	)
}

export default AuthNewBusiness
