import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import styles from './AuthPage.module.scss'
import Button, { ButtonStyles } from '../../components/UI/Button/Button'
import GoogleIconMonotone from '../../images/GoogleIconMonotone'
import Input from '../../components/UI/FormComponents/Input'
import { useAPI } from '../../hooks/useAPI'
import { AuthService } from '../../API/AuthService'
import { Link, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import formComponentsStyles from '../../components/UI/FormComponents/FormComponents.module.scss'

interface AuthSignUpProps {
	recaptchaRef: MutableRefObject<any>
}

const AuthSignUp: FC<AuthSignUpProps> = ({ recaptchaRef }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [newWindow, setNewWindow] = useState<Window | null>(null)
	const [isGoogleTFA, setIsGoogleTFA] = useState(false)
	const [googleTFACode, setGoogleTFACode] = useState('')
	const [isLoading, data, login] = useAPI<{ accessToken: string }>(
		async () => {
			const recaptcha = await recaptchaRef.current.executeAsync()
			recaptchaRef.current.reset()
			return AuthService.login({
				email: email,
				password: password,
				recaptcha,
				tfaCode: googleTFACode.replace('-', '') || null,
			})
		},
		false,
		'Successfully sign in',
		{
			onError: error => {
				if (error.response?.status === 403) setIsGoogleTFA(true)
			},
		}
	)

	useEffect(() => {
		if (data) {
			localStorage.setItem('token', data.accessToken)
			window.location.pathname = '/'
		}
	}, [data])

	useEffect(() => {
		if (newWindow) {
			const Interval = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(Interval)
					if (localStorage.getItem('token')) {
						window.location.pathname = '/'
					}
				}
			}, 500)
		}
	}, [newWindow])

	return (
		<div className={styles.authForm}>
			<h1>Sign in</h1>
			<p className={styles.description}>You’ll have to sign in to access your payments account.</p>
			<Button
				style={ButtonStyles.THIRD}
				onClick={() =>
					setNewWindow(
						window.open(
							'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https://dash.hoodpay.io/auth/google/callback&prompt=consent&response_type=code&client_id=1071387133162-97lpquk2g2laoodhqcd09om13emak091.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&flowName=GeneralOAuthFlow',
							'_blank',
							'width=480, height=720'
						)
					)
				}
			>
				<div className={styles.buttonInner}>
					<GoogleIconMonotone className={styles.googleIcon} />
					<span>Sign In with Google</span>{' '}
				</div>
			</Button>
			<span className={styles.or}>OR</span>
			{isGoogleTFA ? (
				<div className={styles.fieldWrapper}>
					<p>Two-Factor Authentication Code</p>
					<InputMask
						className={formComponentsStyles.input}
						value={googleTFACode}
						onChange={event => setGoogleTFACode(event.target.value)}
						placeholder={'000-000'}
						autoComplete={'off'}
						mask={'999-999'}
					/>
				</div>
			) : (
				<>
					<label className={styles.fieldWrapper}>
						<p>Email Address</p>
						<Input value={email} setValue={setEmail} placeholder={'you@example.com'} />
					</label>
					<label className={styles.fieldWrapper}>
						<p>Password</p>
						<Input
							value={password}
							setValue={setPassword}
							placeholder={'*******************'}
							type={'password'}
						/>
					</label>
				</>
			)}

			<Button style={ButtonStyles.SECONDARY} onClick={login} isLoading={isLoading}>
				Sign In
			</Button>
			<div className={styles.tipText}>
				<Link to='/auth/reset'>Forgot password?</Link>
			</div>
		</div>
	)
}

export default AuthSignUp
