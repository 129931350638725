import React from 'react'

const Banknote2Svg = () => {
	return (
		<svg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.48511 8.92188C2.86582 8.92188 3.98511 10.0412 3.98511 11.4219M14.9851 8.92188C13.6044 8.92188 12.4851 10.0412 12.4851 11.4219M1.48511 4.42188C2.86582 4.42188 3.98511 3.30259 3.98511 1.92188M14.9851 4.42188C13.6044 4.42188 12.4851 3.30259 12.4851 1.92188M13.4851 1.42188H2.98511C1.88054 1.42188 0.985107 2.3173 0.985107 3.42188V9.92188C0.985107 11.0265 1.88054 11.9219 2.98511 11.9219H13.4851C14.5897 11.9219 15.4851 11.0265 15.4851 9.92188V3.42188C15.4851 2.3173 14.5897 1.42188 13.4851 1.42188ZM10.4851 6.67188C10.4851 8.46677 9.47771 9.92188 8.23511 9.92188C6.99251 9.92188 5.98511 8.46677 5.98511 6.67188C5.98511 4.87698 6.99251 3.42188 8.23511 3.42188C9.47771 3.42188 10.4851 4.87698 10.4851 6.67188Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default Banknote2Svg
