export enum Warnings {
	PAYMENT_RESTRICTED,
	ACTION_REQUIRED,
	CONNECT_WALLET,
	CREATE_PAYMENT,
	INVITE_TEAM,
	HOODPAY_FEE,
}

export type Warning =
	| Warnings.ACTION_REQUIRED
	| Warnings.CONNECT_WALLET
	| Warnings.PAYMENT_RESTRICTED
	| Warnings.CREATE_PAYMENT
	| Warnings.INVITE_TEAM
	| Warnings.HOODPAY_FEE
