import React, { FC, useEffect, useState } from 'react'
import styles from './PaymentsTable.module.scss'
import SearchInput, { SearchInputStyles } from '../UI/SearchInput/SearchInput'
import PaymentsTableBody from './PaymentsTableBody'
import { Payment } from '../../types/Payment'
import { useAPI } from '../../hooks/useAPI'
import { PaymentsService } from '../../API/PaymentsService'
import Spinner from '../UI/Spinner/Spinner'
import { Status, Statuses } from '../../types/Status'
import { Cryptocurrency } from '../../types/Cryptocurrencies'
import Pagination from '../UI/Pagination/Pagination'
import CreatePaymentModal from '../Modals/CreatePaymentModal'
import DollarSvg from '../../images/DollarSVG'
import CheckedSvg from '../../images/CheckedSVG'
import RefreshIcon from '../../images/RefreshIcon'
import GavelSVG from '../../images/GavelSVG'
import BanknoteSVG from '../../images/BanknoteSVG'
import Modal from '../Modals/Modal'

interface PaymentsTableProps {
	businessId?: number
	setIsCreatePaymentModalVisible?: (value: boolean) => void
	setIsCreatePaymentModalActive?: (value: boolean) => void
	isCreatePaymentModalVisible?: boolean
	isCreatePaymentModalActive?: boolean
	isFee?: boolean
}

const PaymentsTable: FC<PaymentsTableProps> = ({
	businessId,
	setIsCreatePaymentModalActive,
	setIsCreatePaymentModalVisible,
	isCreatePaymentModalVisible,
	isCreatePaymentModalActive,
	isFee,
}) => {
	const [searchValue, setSearchValue] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [totalItems, setTotalItems] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [fromTime, setFromTime] = useState<Date | null>(null)
	const [toTime, setToTime] = useState<Date | null>(null)
	const [status, setStatus] = useState<Status | null>(null)
	const [paymentMethod, setPaymentMethod] = useState<Cryptocurrency | null>(null)
	const [fromAmount, setFromAmount] = useState<number | null>(null)
	const [toAmount, setToAmount] = useState<number | null>(null)
	const [isPaymentsLoading, payments, getPayments] = useAPI<Payment[]>(
		() =>
			PaymentsService.getAll(
				businessId,
				currentPage + 1,
				pageSize,
				fromTime,
				toTime,
				isFee ? Statuses.SUCCESS : status,
				paymentMethod,
				fromAmount,
				toAmount,
				searchValue,
				isFee ? 1 : null
			),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	useEffect(() => {
		if (businessId) {
			getPayments()
		}
	}, [
		businessId,
		currentPage,
		pageSize,
		fromTime,
		toTime,
		status,
		paymentMethod,
		fromAmount,
		toAmount,
		searchValue,
	])
	const closeModal = () => {
		setIsCreatePaymentModalVisible && setIsCreatePaymentModalVisible(false)
		setTimeout(() => {
			setIsCreatePaymentModalActive && setIsCreatePaymentModalActive(false)
		}, 400)
	}

	return (
		<>
			{isCreatePaymentModalActive && !isFee && (
				<Modal
					closeModal={closeModal}
					visible={isCreatePaymentModalVisible || false}
					setVisible={setIsCreatePaymentModalVisible || (() => {})}
				>
					<CreatePaymentModal close={closeModal} getPayments={getPayments} />
				</Modal>
			)}

			<div className={styles.PaymentsTable}>
				{!isFee ? (
					<header className={styles.header}>
						<div className={styles.filterTabs}>
							<div
								className={`${styles.filterWrapper} ${!status ? styles.active : ''}`}
								onClick={() => setStatus(null)}
							>
								<DollarSvg /> All payments
							</div>
							<div
								className={`${styles.filterWrapper} ${
									status === Statuses.SUCCESS ? styles.active : ''
								}`}
								onClick={() => setStatus(Statuses.SUCCESS)}
							>
								<CheckedSvg /> Succeeded
							</div>
							<div
								className={`${styles.filterWrapper} ${
									status === Statuses.PENDING ? styles.active : ''
								}`}
								onClick={() => setStatus(Statuses.PENDING)}
							>
								<RefreshIcon /> Confirming
							</div>
							<div
								className={`${styles.filterWrapper} ${
									status === Statuses.AWAITING_PAYMENT ? styles.active : ''
								}`}
								onClick={() => setStatus(Statuses.AWAITING_PAYMENT)}
							>
								<GavelSVG /> New
							</div>
							<SearchInput
								style={SearchInputStyles.secondary}
								value={searchValue}
								setValue={setSearchValue}
								customClass={styles.searchInput}
								placeholder={'Search for a payment'}
							/>
						</div>
					</header>
				) : null}

				{/*<div className={styles.filterButtons}>*/}
				{/*	<p> Sort by:</p>*/}
				{/*	<DateRangePicker*/}
				{/*		setFromDate={setFromTime}*/}
				{/*		setToDate={setToTime}*/}
				{/*		fromDate={fromTime}*/}
				{/*		toDate={toTime}*/}
				{/*		front={*/}
				{/*			<button>*/}
				{/*				<CalendarSVG />*/}
				{/*				Date*/}
				{/*			</button>*/}
				{/*		}*/}
				{/*		interfaceStyle={{*/}
				{/*			right: 'auto',*/}
				{/*			left: 0,*/}
				{/*			top: '30px',*/}
				{/*		}}*/}
				{/*	/>*/}

				{/*	/!*<button>*!/*/}
				{/*	/!*	<DollarSVG />*!/*/}
				{/*	/!*	Amount*!/*/}
				{/*	/!*</button>*!/*/}
				{/*	/!*<button>*!/*/}
				{/*	/!*	<WalletSVG />*!/*/}
				{/*	/!*	Payment method*!/*/}
				{/*	/!*</button>*!/*/}
				{/*</div>*/}

				{isPaymentsLoading && <Spinner />}
				{!payments?.length && !isPaymentsLoading ? (
					<div className={styles.noTransactions}>
						<BanknoteSVG />
						<h2>No recent payments</h2>
						<p>Once you start receiving payments, you’ll see a history of transactions here</p>
					</div>
				) : (
					<>
						<div className={styles.table}>
							<div className={styles.tableHeader}>
								<h3>Amount</h3>
								<h3 className={styles.medium}>Status</h3>
								<h3 className={styles.large}>Description</h3>
								<h3 className={styles.medium}>Method</h3>
								<h3 className={styles.medium}>Customer</h3>
								<h3 className={styles.medium}>Date</h3>
								<h3 className={styles.settings} />
							</div>
							{payments?.map(payment => (
								<PaymentsTableBody payment={payment} key={payment.id} />
							))}
						</div>
						<Pagination
							totalItems={totalItems}
							setCurrentPage={setCurrentPage}
							pageSize={pageSize}
							setPageSize={setPageSize}
							currentPage={currentPage}
						/>
					</>
				)}
			</div>
		</>
	)
}

export default PaymentsTable
