import React, { CSSProperties, FC } from 'react'
import styles from './Switch.module.scss'

interface SwitchProps {
	checked: boolean
	setChecked: (checked: boolean) => void
	style?: CSSProperties
}

const Switch: FC<SwitchProps> = ({ checked, setChecked, style }) => {
	return (
		<label className={styles.switch} style={style}>
			<input
				type='checkbox'
				checked={checked}
				onChange={event => setChecked(event.target.checked)}
			/>
		</label>
	)
}

export default Switch
