import React from 'react'
import styles from './BalancesPage.module.scss'
import WarningMessage from '../../components/UI/WarningMessage/WarningMessage'
import { Warnings } from '../../types/Warning'

const BalancesOverviewPage = () => {
	return (
		<div className={styles.pageInner}>
			<WarningMessage warningType={Warnings.HOODPAY_FEE} />
			<section className={styles.section}>
				<header className={styles.sectionHeader}>
					<h2>USD balance</h2>
				</header>
				<div className={styles.titleValueDisplay}>
					<p>Available to pay out to your bank account</p>
					<p>$0.00</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p>Currently on the way to your bank account</p>
					<p>$0.00</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p>Hoodpay fees</p>
					<p>$0.00</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p className={styles.strong}>Total</p>
					<p className={styles.strong}>$0.00</p>
				</div>
			</section>
			<section className={styles.section}>
				<header className={styles.sectionHeader}>
					<h2>Crypto balance</h2>
				</header>
				<div className={styles.titleValueDisplay}>
					<p>Available to pay out to your crypto wallet</p>
					<p>0.00 BTC ($0.00 USD)</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p>Hoodpay fees</p>
					<p>-0.00 BTC ($0.00 USD)</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p className={styles.strong}>Total</p>
					<p className={styles.strong}>-0.00 BTC ($0.00 USD)</p>
				</div>
			</section>
			<section className={styles.section}>
				<header className={styles.sectionHeader}>
					<h2>Outgoing USD balance</h2>
				</header>
				<div className={styles.titleValueDisplay}>
					<p>These funds should hit your bank account soon</p>
					<p>$0.00 USD</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p className={styles.strong}>Total</p>
					<p className={styles.strong}>$0.00 USD</p>
				</div>
			</section>
			<section className={styles.section}>
				<header className={styles.sectionHeader}>
					<h2>Outgoing crypto balance</h2>
				</header>
				<div className={styles.titleValueDisplay}>
					<p>These funds should hit your crypto wallet tomorrow</p>
					<p>0.00 BTC ($0.00 USD)</p>
				</div>
				<div className={styles.titleValueDisplay}>
					<p className={styles.strong}>Total</p>
					<p className={styles.strong}>0.00 BTC ($0.00 USD)</p>
				</div>
			</section>
		</div>
	)
}

export default BalancesOverviewPage
