import React, { useEffect } from 'react'
import styles from './AuthPage.module.scss'
import { useAPI } from '../../hooks/useAPI'
import { AuthService } from '../../API/AuthService'
import Spinner from '../../components/UI/Spinner/Spinner'

const AuthGoogle = () => {
	const locationHref = decodeURIComponent(window.location.href)
	const code = locationHref.split('?')[1].split('&')[0].slice(5)
	const [] = useAPI(
		() => AuthService.googleLogin(code, 'https://dash.hoodpay.io/auth/google/callback'),
		true,
		'Successfully sign in',
		{
			onSuccess: response => {
				localStorage.setItem('token', response.data.data.accessToken)
				window.close()
			},
		}
	)

	return (
		<div className={styles.authSideInner}>
			<Spinner />
		</div>
	)
}

export default AuthGoogle
