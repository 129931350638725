import sprite from '../../../images/sprite.svg'

interface SpriteIconProps {
	iconId: string
	width: number
	height: number
	className?: string
}

export const SpriteIcon = ({ iconId, width, height, className }: SpriteIconProps) => {
	return (
		<svg className={className} width={width} height={height}>
			<use href={`${sprite}#${iconId}`} />
		</svg>
	)
}
