import React, { useState } from 'react'
import styles from './AuthPage.module.scss'
import Input from '../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../components/UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { AuthService } from '../../API/AuthService'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

const AuthNewPassword = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const token = location.hash.split('#token=')[1]
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const validatePasswords = () => {
		setErrors({
			...errors,
			password: password.length < 8 ? 'Password length must be greater than 7 characters' : '',
			confirmPassword: confirmPassword !== password ? 'Passwords did not match' : '',
		})

		return password.length >= 8 && password === confirmPassword
	}

	const [isLoading, , completeReset] = useAPI(
		() => AuthService.completeReset(token, password),
		false,
		'Password successfully reset',
		{
			validator: validatePasswords,
			onSuccess: () => {
				navigate('/auth/login')
			},
		}
	)
	const [errors, setErrors] = useState({
		password: '',
		confirmPassword: '',
	})

	return (
		<div className={styles.authSideInner}>
			<h1>Enter a secure new password</h1>
			<label className={styles.fieldWrapper}>
				<p>New password</p>
				<Input
					type={'password'}
					autoComplete={'new-password'}
					value={password}
					setValue={setPassword}
					placeholder={'New password'}
					errorMessage={errors.password}
					isError={!!errors.password}
				/>
			</label>
			<label className={styles.fieldWrapper}>
				<p>Confirm new password</p>
				<Input
					type={'password'}
					autoComplete={'new-password'}
					value={confirmPassword}
					setValue={setConfirmPassword}
					placeholder={'Confirm new password'}
					errorMessage={errors.confirmPassword}
					isError={!!errors.confirmPassword}
				/>
			</label>
			<Button style={ButtonStyles.SECONDARY} isLoading={isLoading} onClick={completeReset}>
				Reset Password
			</Button>
		</div>
	)
}

export default AuthNewPassword
