import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import modalStyles from '../Modals.module.scss'
import styles from './CustomerViewModal.module.scss'
import CloseButton from '../../UI/CloseButton/CloseButton'
import CrownSVG from '../../../images/CrownSVG'
import Note from '../../Note/Note'
import Pagination from '../../UI/Pagination/Pagination'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { useAPI } from '../../../hooks/useAPI'
import { BusinessesCustomersService } from '../../../API/BusinessesCustomersService'
import { useAppContext } from '../../../hooks/useAppContext'
import { CustomerView } from '../../../types/CustomerView'
import SideModal from '../../SideModals/SideModal'
import Spinner from '../../UI/Spinner/Spinner'
import NoteModal from '../NoteModal'
import { closeModal } from '../../../utils/CloseModal'
import { format } from 'date-fns'
import { IpAddress } from '../../../types/IpAddress'
import Modal from '../Modal'
import { useSearchParams } from 'react-router-dom'

interface CustomerViewSideModalProps {
	closeModal: () => void
}

const CustomerViewModal: FC<CustomerViewSideModalProps> = ({
	closeModal: closeCustomerViewModal,
}) => {
	const { business } = useAppContext()
	const [searchParams, setSearchParams] = useSearchParams()
	const [ipsCurrentPage, setIpsCurrentPage] = useState(0)
	const [totalIps, setTotalIps] = useState(0)
	const [ipAddresses, setIpAddresses] = useState<IpAddress[]>()
	const [ipsPageSize, setIpsPageSize] = useState(10)
	const [isNoteModalActive, setIsNoteModalActive] = useState(false)
	const [isNoteModalVisible, setIsNoteModalVisible] = useState(false)
	const [isNoteEdit, setIsNoteEdit] = useState(false)
	const currentCustomerId = searchParams.get('customerId')

	const [isGetCustomerLoading, customerView, getCustomer] = useAPI<CustomerView>(
		() => BusinessesCustomersService.getCustomer(business?.id, currentCustomerId),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalIps(response.data.data.ipAddresses.length)
			},
		}
	)
	const [isUpdateCustomerNoteLoading, , updateCustomerNote] = useAPI(
		(note: string | null) =>
			BusinessesCustomersService.updateCustomerNote(business?.id, currentCustomerId, { note }),
		false,
		'Customer note successfully updated',
		{
			onSuccess: () => {
				getCustomer()
				closeModal(setIsNoteModalActive, setIsNoteModalVisible)
			},
		}
	)

	useEffect(() => {
		getCustomer()
	}, [])

	useEffect(() => {
		setIpAddresses(
			customerView?.ipAddresses?.filter(
				(item, index) =>
					index >= ipsPageSize * (ipsCurrentPage - 1) && index < ipsPageSize * ipsCurrentPage
			)
		)
	}, [ipsPageSize, ipsCurrentPage])

	return (
		<>
			<div className={`${modalStyles.modalInner}`}>
				<header className={styles.customerViewHeader}>
					<CloseButton
						onClick={() => {
							searchParams.delete('customerId')
							setSearchParams(searchParams)
							closeCustomerViewModal()
						}}
					/>
					<div className={styles.topCustomerTitle}>
						<h2>CUSTOMER</h2>
					</div>
					<span className={styles.customerNumber}>&nbsp;&nbsp;</span>
				</header>
				{isGetCustomerLoading ? (
					<Spinner />
				) : (
					<div className={styles.customerView}>
						<div className={styles.customerMainInfo}>
							<div className={styles.customer}>
								<div className={styles.customerAva}>{customerView?.email[0]}</div>
								<div>
									{customerView?.name && <h4>{customerView?.name}</h4>}

									<span className={styles.span}>{customerView?.email}</span>
								</div>
							</div>
							<button
								onClick={event => {
									event.stopPropagation()
									setIsNoteModalActive(true)
								}}
							>
								Add note
							</button>
						</div>
						{isUpdateCustomerNoteLoading ? (
							<Spinner />
						) : (
							customerView?.note && (
								<div className={styles.noteWrapper}>
									<Note
										secondStyle={true}
										deleteNote={() => {
											updateCustomerNote(null)
										}}
										editNote={() => {
											setIsNoteEdit(true)
											setIsNoteModalActive(true)
										}}
										note={customerView?.note}
									/>
								</div>
							)
						)}

						<div className={styles.section}>
							<h2 className={styles.h2}>Overview</h2>
							<div className={styles.tableHeader}>
								<h3>Total Spend</h3>
								<h3>Total Payments</h3>
								<h3>Customer Since</h3>
							</div>
							<div className={styles.tableBody}>
								<div className={styles.tableBodyItem}>
									<p>${customerView?.totalSpend.toFixed(2)}</p>
								</div>
								<div className={styles.tableBodyItem}>
									<p>{customerView?.totalPayments}</p>
								</div>
								<div className={styles.tableBodyItem}>
									<p>
										{customerView?.firstSeen &&
											format(new Date(customerView?.firstSeen), `MMM \u2018dd`)}
									</p>
								</div>
							</div>
						</div>
						{/*<div className={styles.section}>*/}
						{/*	<h2 className={styles.h2}>Engagement Score</h2>*/}
						{/*	<div className={styles.graph}>*/}
						{/*		<ResponsiveContainer width={'100%'} height={90}>*/}
						{/*			<LineChart*/}
						{/*				data={[*/}
						{/*					{ count: 2, dateOrHour: '22 mm' },*/}
						{/*					{ count: 3, dateOrHour: '22 mm' },*/}
						{/*				]}*/}
						{/*			>*/}
						{/*				<XAxis dataKey={'dateOrHour'} />*/}
						{/*				<XAxis dataKey={'count'} />*/}
						{/*				<Line*/}
						{/*					type='linear'*/}
						{/*					dataKey={'count'}*/}
						{/*					stroke='#A7E23D'*/}
						{/*					strokeWidth={2}*/}
						{/*					dot={false}*/}
						{/*					connectNulls={true}*/}
						{/*				/>*/}
						{/*				<Tooltip*/}
						{/*					labelStyle={{*/}
						{/*						color: 'var(--light_color)',*/}
						{/*					}}*/}
						{/*					contentStyle={{*/}
						{/*						backgroundColor: 'var(--dark_color)',*/}
						{/*						borderRadius: 10,*/}
						{/*						borderColor: 'none',*/}
						{/*					}}*/}
						{/*				/>*/}
						{/*			</LineChart>*/}
						{/*		</ResponsiveContainer>*/}
						{/*	</div>*/}
						{/*	<div className={styles.tableHeader}>*/}
						{/*		<h3>Points</h3>*/}
						{/*		<h3>Top Percent</h3>*/}
						{/*		<h3>Last Payment</h3>*/}
						{/*	</div>*/}
						{/*	<div className={styles.tableBody}>*/}
						{/*		<div className={styles.tableBodyItem}>*/}
						{/*			<p>${customerView?.totalSpend.toFixed(2)}</p>*/}
						{/*		</div>*/}
						{/*		<div className={styles.tableBodyItem}>*/}
						{/*			<p>{customerView?.totalPayments}</p>*/}
						{/*		</div>*/}
						{/*		<div className={styles.tableBodyItem}>*/}
						{/*			<p>*/}
						{/*				{customerView?.firstSeen && format(new Date(customerView?.firstSeen), 'MMM dd')}*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{customerView?.ipAddresses?.length ? (
							<div className={styles.section}>
								<h2 className={styles.h2}>Customer Details</h2>
								<div className={styles.graph}></div>
								<div className={styles.tableHeader}>
									<h3>IP Address</h3>
									<h3>Payment Date</h3>
								</div>
								{customerView?.ipAddresses?.map(ipAddress => (
									<div className={styles.tableBody}>
										<div className={styles.tableBodyItem}>
											<p>{ipAddress.ip}</p>
										</div>
										<div className={styles.tableBodyItem}>
											<p></p>
										</div>
									</div>
								))}
								<div className={styles.paginationWrapper}>
									<Pagination
										totalItems={totalIps}
										pageSize={ipsPageSize}
										setPageSize={setIpsPageSize}
										setCurrentPage={setIpsCurrentPage}
										currentPage={ipsCurrentPage}
									/>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
			{isNoteModalActive && (
				<Modal
					closeModal={() => closeModal(setIsNoteModalActive, setIsNoteModalVisible)}
					visible={isNoteModalVisible}
					setVisible={setIsNoteModalVisible}
				>
					<NoteModal
						isEdit={isNoteEdit}
						isLoading={isUpdateCustomerNoteLoading}
						handleNote={updateCustomerNote}
						note={customerView?.note}
					/>
				</Modal>
			)}
		</>
	)
}

export default CustomerViewModal
