import React, { FC } from 'react'
import styles from './CardsPayment.module.scss'
import HoodpayLogo from '../../images/HoodpayLogo'
import CheckedSvg from '../../images/CheckedSVG'
import Button from '../UI/Button/Button'
import OpenLinkSVG from '../../images/OpenLinkSVG'
import { Settings } from '../../types/Settings'
import Checked2SVG from '../../images/Checked2SVG'

interface CardsPaymentProps {
	setIsCardToCryptoModalActive: (value: boolean) => void
	settings?: Settings
}

const CardsPayment: FC<CardsPaymentProps> = ({ setIsCardToCryptoModalActive, settings }) => {
	return (
		<div className={styles.cardsPayment}>
			<header>
				<HoodpayLogo />
				<h2>Card to Crypto</h2>
			</header>
			<div className={styles.advantages}>
				<div className={styles.advantage}>
					<div className={styles.checkMark}>
						<CheckedSvg />
					</div>
					<p>3% + $0.30 per transaction</p>
				</div>
				<div className={styles.advantage}>
					<div className={styles.checkMark}>
						<CheckedSvg />
					</div>
					<p>Automated dispute fighter</p>
				</div>
				<div className={styles.advantage}>
					<div className={styles.checkMark}>
						<CheckedSvg />
					</div>
					<p>High-risk payments</p>
				</div>
				<div className={styles.advantage}>
					<div className={styles.checkMark}>
						<CheckedSvg />
					</div>
					<p>Direct crypto payouts</p>
				</div>
			</div>
			<Button onClick={() => setIsCardToCryptoModalActive(true)}>
				{settings?.onRampCryptoSettings?.isActive ? (
					<div className={styles.active}>
						Active <Checked2SVG />
					</div>
				) : (
					<>Activate</>
				)}
			</Button>
		</div>
	)
}

export default CardsPayment
