import React, { FC } from 'react'
import styles from './Pagination.module.scss'
import ReactPaginate from 'react-paginate'
import { toArray } from 'recharts/types/util/ReactUtils'

interface PaginationProps {
	totalItems: number
	pageSize: number
	setPageSize: (pageSize: number) => void
	setCurrentPage: (currentPage: number) => void
	currentPage: number
}

const Pagination: FC<PaginationProps> = ({
	totalItems,
	setCurrentPage,
	pageSize,
	setPageSize,
	currentPage,
}) => {
	const pageCount = Math.ceil(totalItems / pageSize)
	const handlePageClick = (event: { selected: number }) => {
		setCurrentPage(event.selected)
	}

	return (
		<div className={styles.pagination}>
			<ReactPaginate
				breakLabel='...'
				nextLabel='Next'
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				initialPage={currentPage}
				pageCount={pageCount}
				previousLabel='Previous'
				renderOnZeroPageCount={() => null}
				containerClassName={styles.paginateContainer}
				previousClassName={styles.paginatePrevNext}
				nextClassName={styles.paginatePrevNext}
				pageClassName={styles.paginatePage}
				activeClassName={styles.paginateActive}
			/>
			<div className={styles.pageSizeController}>
				Showing{' '}
				<select value={pageSize} onChange={event => setPageSize(+event.target.value)}>
					<option value='10'>1 - 10</option>
					<option value='20'>1 - 20</option>
					<option value='30'>1 - 30</option>
					<option value='40'>1 - 40</option>
					<option value='50'>1 - 50</option>
				</select>{' '}
				of {totalItems} results
			</div>
		</div>
	)
}

export default Pagination
