import React, { useEffect, useRef, useState, MouseEvent } from 'react'
import styles from './BusinessSwitcher.module.scss'
import ChooseableSvg from '../../images/ChooseableSVG'
import { useAppContext } from '../../hooks/useAppContext'
import { Business } from '../../types/Business'
import PlusIcon from '../../images/PlusIcon'
import { useNavigate } from 'react-router-dom'

const BusinessSwitcher = () => {
	const [isBusinessMenuActive, setIsBusinessMenuActive] = useState(false)
	const businessSwitcherRef = useRef<HTMLDivElement | null>(null)
	const { user, business, setBusiness } = useAppContext()
	const navigate = useNavigate()

	const handleClickOutside = (event: any) => {
		if (!businessSwitcherRef.current?.contains(event.target as Node)) {
			setIsBusinessMenuActive(false)
		}
	}

	const handleBusinessClick = (business: Business) => {
		localStorage.setItem('businessId', business.id.toString())
		setBusiness(business)
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside)

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<div className={styles.businessSwitcher} ref={businessSwitcherRef}>
			<div className={`${styles.businesses} ${isBusinessMenuActive && styles.active}`}>
				{user?.businesses
					.filter(businessItem => businessItem.id !== business?.id)
					.map(business => (
						<div className={styles.businessWrapper} onClick={() => handleBusinessClick(business)}>
							<div className={styles.ava}>
								{business.url && (
									<img src={`https://imagedelivery.net/${business.url}/logo`} alt='businessLogo' />
								)}
							</div>
							<div>
								<h3>{business.name}</h3>
								<p>{user?.email}</p>
							</div>
						</div>
					))}
				<div className={styles.businessWrapper} onClick={() => navigate('/auth/new-business')}>
					<div className={styles.ava}>
						<PlusIcon />
					</div>
					<h3>New account</h3>
				</div>
			</div>
			<div
				className={`${styles.businessWrapper} ${styles.current}`}
				onClick={() => setIsBusinessMenuActive(!isBusinessMenuActive)}
			>
				<div className={styles.ava}>
					{business?.url && (
						<img src={`https://imagedelivery.net/${business.url}/logo`} alt='businessLogo' />
					)}
				</div>

				<h3>{business?.name}</h3>

				<ChooseableSvg />
			</div>
		</div>
	)
}

export default BusinessSwitcher
