import React, { useEffect, useRef, useState } from 'react'
import styles from './CustomersPage.module.scss'
import Container from '../../components/UI/Container/Container'
import Button from '../../components/UI/Button/Button'
import PlusIcon from '../../images/PlusIcon'
import CustomersTable from '../../components/CustomersTable/CustomersTable'
import { useAppContext } from '../../hooks/useAppContext'
import SideModal from '../../components/SideModals/SideModal'
import CreateCustomerModal from '../../components/Modals/CreateCustomerModal'
import { closeModal } from '../../utils/CloseModal'
import CustomerViewModal from '../../components/Modals/CustomerViewModal/CustomerViewModal'
import Modal from '../../components/Modals/Modal'
import { useSearchParams } from 'react-router-dom'
const CustomersPage = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [isCreateCustomerModalActive, setIsCreateCustomerModalActive] = useState(false)
	const [isCreateCustomerModalVisible, setIsCreateCustomerModalVisible] = useState(false)
	const [isCustomerViewModalVisible, setIsCustomerViewModalVisible] = useState(false)
	const [isCustomerViewModalActive, setIsCustomerViewModalActive] = useState(false)
	const [wasCustomerCreated, setWasCustomerCreated] = useState(false)
	const { business } = useAppContext()

	useEffect(() => {
		const customerId = searchParams.get('customerId')

		if (customerId) {
			setIsCustomerViewModalActive(true)
		}
	}, [searchParams])

	return (
		<Container>
			<div className={styles.customersPage}>
				{isCustomerViewModalActive && (
					<Modal
						closeModal={() => {
							closeModal(setIsCustomerViewModalActive, setIsCustomerViewModalVisible)
							searchParams.delete('customerId')
							setSearchParams(searchParams)
						}}
						visible={isCustomerViewModalVisible}
						setVisible={setIsCustomerViewModalVisible}
					>
						<CustomerViewModal
							closeModal={() =>
								closeModal(setIsCustomerViewModalActive, setIsCustomerViewModalVisible)
							}
						/>
					</Modal>
				)}
				{isCreateCustomerModalActive && (
					<Modal
						closeModal={() =>
							closeModal(setIsCreateCustomerModalActive, setIsCreateCustomerModalVisible)
						}
						visible={isCreateCustomerModalVisible}
						setVisible={setIsCreateCustomerModalVisible}
					>
						<CreateCustomerModal
							close={() =>
								closeModal(setIsCreateCustomerModalActive, setIsCreateCustomerModalVisible)
							}
							setWasCustomerCreated={setWasCustomerCreated}
						/>
					</Modal>
				)}
				<header className={styles.pageHeader}>
					<h1 className={styles.h1}>Customers</h1>

					{/*<Button onClick={() => setIsCreateCustomerModalActive(true)}>*/}
					{/*	<div className={styles.buttonInner}>*/}
					{/*		Create customer <PlusIcon />*/}
					{/*	</div>*/}
					{/*</Button>*/}
				</header>

				<CustomersTable
					searchParams={searchParams}
					setSearchParams={setSearchParams}
					businessId={business?.id}
					wasCustomerCreated={wasCustomerCreated}
					setWasCustomerCreated={setWasCustomerCreated}
					setIsCustomerViewModalActive={setIsCustomerViewModalActive}
				/>
			</div>
		</Container>
	)
}

export default CustomersPage
