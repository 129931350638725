import React, { FC, SVGProps } from 'react'

const PenIcon: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='11'
			height='11'
			viewBox='0 0 11 11'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M10.074 10.7369H1.29218C1.09215 10.7369 0.92627 10.571 0.92627 10.371C0.92627 10.171 1.09215 10.0051 1.29218 10.0051H10.074C10.2741 10.0051 10.4399 10.171 10.4399 10.371C10.4399 10.571 10.2741 10.7369 10.074 10.7369ZM9.10803 1.70232C8.16154 0.755831 7.23457 0.731437 6.26368 1.70232L5.67335 2.29265C5.62456 2.34144 5.60504 2.4195 5.62456 2.48781C5.81115 3.12809 6.1562 3.71098 6.62778 4.18257C7.09936 4.65415 7.68225 4.99919 8.32254 5.18579C8.35712 5.19711 8.39417 5.19854 8.42951 5.18992C8.46486 5.1813 8.49709 5.16297 8.52257 5.137L9.10803 4.54666C9.59103 4.06854 9.82521 3.60505 9.82521 3.13669C9.83009 2.65369 9.59591 2.18532 9.10803 1.70232ZM7.44435 5.62878C7.30287 5.56048 7.16626 5.49218 7.03453 5.41412C6.92753 5.35037 6.82335 5.28201 6.72229 5.20921C6.63935 5.15554 6.54178 5.07748 6.44908 4.99942C6.41922 4.97765 6.39145 4.95315 6.36614 4.92623C6.20514 4.78963 6.02462 4.61399 5.86362 4.41884C5.84898 4.40908 5.82459 4.37493 5.79044 4.33102C5.74165 4.27247 5.65871 4.1749 5.58553 4.06269C5.51598 3.97093 5.45243 3.87478 5.39526 3.77484C5.31719 3.64311 5.24889 3.51138 5.18059 3.37477C5.17043 3.35293 5.16051 3.33097 5.15083 3.30891C5.07862 3.14645 4.86639 3.09912 4.74101 3.22451L1.94594 6.01958C1.88252 6.083 1.82397 6.20497 1.80933 6.28791L1.54588 8.15649C1.49709 8.48825 1.58979 8.8005 1.7947 9.01028C1.97033 9.18104 2.21427 9.27374 2.47773 9.27374C2.53628 9.27374 2.59482 9.26886 2.65337 9.2591L4.52683 8.99565C4.61465 8.98101 4.73662 8.92246 4.79516 8.85904L7.58682 6.06788C7.71367 5.94103 7.66536 5.72392 7.50095 5.65318C7.48206 5.64509 7.4632 5.63696 7.44435 5.62878Z' />
		</svg>
	)
}

export default PenIcon
