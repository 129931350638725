import { privateAPI } from './API'

export const BusinessesCustomersService = {
	search: async (businessId: number | undefined, data: string) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/search`, {
			params: {
				data,
			},
		})
	},
}
