import React from 'react'

const PaperSvg = () => {
	return (
		<svg width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.25 1.39258H1.25V15.8926L2.849 14.6496C3.03223 14.507 3.25938 14.4326 3.49147 14.4391C3.72356 14.4457 3.94613 14.5328 4.121 14.6856L5.5 15.8926L6.841 14.7186C7.02336 14.5588 7.25755 14.4707 7.5 14.4707C7.74245 14.4707 7.97664 14.5588 8.159 14.7186L9.5 15.8926L10.879 14.6856C11.0539 14.5328 11.2764 14.4457 11.5085 14.4391C11.7406 14.4326 11.9678 14.507 12.151 14.6496L13.75 15.8926V6.89258M9.25 1.39258L13.75 6.89258M9.25 1.39258V4.89258C9.25 5.42301 9.46071 5.93172 9.83579 6.30679C10.2109 6.68186 10.7196 6.89258 11.25 6.89258H13.75'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PaperSvg
