import React, { FC, useEffect, useState } from 'react'
import styles from './SettingsCheckoutPage.module.scss'
import Switch from '../../../components/UI/Switch/Switch'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { useAppContext } from '../../../hooks/useAppContext'
import { BusinessSettingsCheckoutService } from '../../../API/BusinessSettingsCheckoutService'
import { SettingsPageProps } from '../../../types/SettingsPageProps'
import settingsStyles from '../Settings.module.scss'
import WarningIcon from '../../../images/WarningIcon'
import OpenLinkSVG from '../../../images/OpenLinkSVG'
import logoTest from '../../../images/polygonIcon.png'
import FileInput from '../../../components/UI/FormComponents/FileInput'
import ColorInput from '../../../components/UI/FormComponents/ColorInput'
import WarningLink from '../../../components/UI/WarningLink/WarningLink'
import { BusinessSettingsGeneralService } from '../../../API/BusinessSettingsGeneralService'

const SettingsCheckoutPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	const { business, getUser } = useAppContext()
	const [enableRedirectLink, setEnableRedirectLink] = useState(false)
	const [coverProcessingFee, setCoverProcessingFee] = useState(false)
	const [redirectLink, setRedirectLink] = useState('')
	const [logoFile, setLogoFile] = useState<File | null>(null)
	const [brandColor, setBrandColor] = useState('#000000')
	const [fieldsUnchangedValues, setFieldsUnchangedValues] = useState({
		redirectLink: '',
		enableRedirectLink: false,
	})
	const [isCoverProcessingFeeLoading, , updateCoverProcessingFee] = useAPI(
		() =>
			BusinessSettingsCheckoutService.updateCoverProcessingFee(business?.id, coverProcessingFee),
		false,
		'Cover processing fee successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isRedirectLinkLoading, , updateRedirectLink] = useAPI(
		() =>
			BusinessSettingsCheckoutService.updateRedirectUrl(
				business?.id,
				enableRedirectLink ? redirectLink : null
			),
		false,
		'Redirect link successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)

	const [isUpdateLogoLoading, , updateLogo] = useAPI(
		() => {
			const formData: FormData = new FormData()

			formData.append('file', logoFile || 'null')

			return BusinessSettingsGeneralService.updateLogo(business?.id, formData)
		},
		false,
		'Business logo updated successfully',
		{
			onSuccess: () => {
				getSettings(business?.id)
				getUser()
			},
		}
	)

	const [isUpdateDesignLoading, , updateDesign] = useAPI(
		() => {
			return BusinessSettingsGeneralService.updateDesign(business?.id, { mainHexColor: brandColor })
		},
		false,
		'Business design updated successfully',
		{
			onSuccess: () => {
				getSettings(business?.id)
				getUser()
			},
		}
	)

	const [isTransactionalEmailsLoading, , updateTransactionalEmails] = useAPI(
		(value: boolean) =>
			BusinessSettingsCheckoutService.updateTransactionalEmails(business?.id, value),
		false,
		'Transactional emails successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)

	const onCheckoutSave = () => {
		updateRedirectLink()
		updateCoverProcessingFee()
	}

	const onBrandingSave = () => {
		updateDesign()
		updateLogo()
	}

	useEffect(() => {
		if (settings) {
			// setTransactionalEmail(settings.transactionalEmails || false)
			setCoverProcessingFee(settings.coverProcessingFee || false)
			setRedirectLink(settings.redirectUrl || '')
			setEnableRedirectLink(!!settings.redirectUrl)
			setFieldsUnchangedValues({
				redirectLink: settings.redirectUrl || '',
				enableRedirectLink: !!settings.redirectUrl,
			})
			setBrandColor(settings.design.mainHexColor)
		}
	}, [settings])

	return (
		<div className={styles.settingsCheckoutPage}>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Checkout</h1>
						<p>
							Set certain requirements for customers during checkout or customize the checkout flow.
						</p>
					</div>
					<Button
						onClick={onCheckoutSave}
						isLoading={isRedirectLinkLoading || isCoverProcessingFeeLoading}
					>
						Save
					</Button>
				</header>
				<div className={settingsStyles.sectionBody}>
					<div className={settingsStyles.fieldWrapper}>
						<header>
							<div>
								<h2>Redirect link</h2>
								<p className={settingsStyles.p}>
									Customers will be redirected to a custom URL immediately after checkout.
								</p>
							</div>
							<Switch
								checked={enableRedirectLink}
								setChecked={checked => setEnableRedirectLink(checked)}
							/>
						</header>

						{enableRedirectLink && (
							<Input
								value={redirectLink}
								setValue={setRedirectLink}
								placeholder={'https://example.com'}
							/>
						)}
					</div>
					<div className={settingsStyles.fieldWrapper}>
						<header>
							<div>
								<h2>COVER PROCESSING FEE</h2>
								<p className={settingsStyles.p}>
									If enabled, we’ll automatically add the Hoodpay fee to the total your customer has
									to pay.
								</p>
							</div>
							<Switch
								checked={coverProcessingFee}
								setChecked={checked => setCoverProcessingFee(checked)}
							/>
						</header>
					</div>
					{/*<div className={settingsStyles.fieldWrapper}>*/}
					{/*	<header>*/}
					{/*		<div>*/}
					{/*			<h2>VERIFY EMAIL</h2>*/}
					{/*			<p className={settingsStyles.p}>*/}
					{/*				Require customers to verify their email address with a 6-digit code before*/}
					{/*				checkout.*/}
					{/*			</p>*/}
					{/*		</div>*/}
					{/*		<Switch*/}
					{/*			checked={coverProcessingFee}*/}
					{/*			setChecked={checked => setCoverProcessingFee(checked)}*/}
					{/*		/>*/}
					{/*	</header>*/}
					{/*</div>*/}
					{/*<WarningLink href={''} text={'See the emails we send out'} />*/}
					{/*<div className={settingsStyles.fieldWrapper}>*/}
					{/*	<header>*/}
					{/*		<div>*/}
					{/*			<h2>VERIFY EMAIL</h2>*/}
					{/*			<p className={settingsStyles.p}>*/}
					{/*				Require customers to verify their email address with a code before checkout.*/}
					{/*			</p>*/}
					{/*		</div>*/}
					{/*		<Switch*/}
					{/*			checked={transactionalEmail}*/}
					{/*			setChecked={checked => setTransactionalEmail(checked)}*/}
					{/*		/>*/}
					{/*	</header>*/}
					{/*</div>*/}
				</div>
			</section>
			<section className={settingsStyles.section}>
				<header className={settingsStyles.sectionHeader}>
					<div>
						<h1>Branding</h1>
						<p className={settingsStyles.p}>
							Your logo, colors, and copyright date will be visible during and after checkout.
						</p>
					</div>
					<Button onClick={onBrandingSave} isLoading={isUpdateLogoLoading || isUpdateDesignLoading}>
						Save
					</Button>
				</header>
				<div className={settingsStyles.sectionBody}>
					<div className={styles.brandingWrapper}>
						<h2>LOGO</h2>
						<div className={styles.brandingInner}>
							<div className={styles.logo}>
								{business?.url ? (
									<img src={`https://imagedelivery.net/${business.url}/logo`} alt='logo' />
								) : logoFile ? (
									<img src={URL.createObjectURL(logoFile)} alt='logo' />
								) : (
									'?'
								)}
							</div>
							<div className={styles.inputs}>
								<div className={settingsStyles.fieldWrapper}>
									<FileInput
										text={'Upload image (JPG, GIF or PNG. 1MB max)'}
										file={logoFile}
										setFile={setLogoFile}
									/>
								</div>

								<h2>Brand color</h2>
								<ColorInput colorValue={brandColor} setColorValue={setBrandColor} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*<section className={settingsStyles.section}>*/}
			{/*	<header className={settingsStyles.sectionHeader}>*/}
			{/*		<div>*/}
			{/*			<h1>Analytics</h1>*/}
			{/*			<p>*/}
			{/*				Track customer experiences from checkout through third-party analytics partners for*/}
			{/*				retargeting.*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*	</header>*/}
			{/*	<div className={settingsStyles.sectionBody}>*/}
			{/*		<div className={settingsStyles.fieldWrapper}>*/}
			{/*			<header>*/}
			{/*				<h2>GOOGLE TAGS MANAGER ID</h2>*/}
			{/*				<a className={styles.link} href='' target={'_blank'}>*/}
			{/*					<OpenLinkSVG />*/}
			{/*					<p>View docs</p>*/}
			{/*				</a>*/}
			{/*			</header>*/}
			{/*			<Input value={redirectLink} setValue={setRedirectLink} placeholder={'GTM-00000000'} />*/}
			{/*		</div>*/}
			{/*		<div className={settingsStyles.fieldWrapper}>*/}
			{/*			<header>*/}
			{/*				<h2>GOOGLE ANALYTICS ID</h2>*/}
			{/*				<a className={styles.link} href='' target={'_blank'}>*/}
			{/*					<OpenLinkSVG />*/}
			{/*					<p>View docs</p>*/}
			{/*				</a>*/}
			{/*			</header>*/}
			{/*			<Input value={redirectLink} setValue={setRedirectLink} placeholder={'GA-00000000'} />*/}
			{/*		</div>*/}
			{/*		<div className={settingsStyles.fieldWrapper}>*/}
			{/*			<header>*/}
			{/*				<h2>META PIXEL ID</h2>*/}
			{/*				<a className={styles.link} href='' target={'_blank'}>*/}
			{/*					<OpenLinkSVG />*/}
			{/*					<p>View docs</p>*/}
			{/*				</a>*/}
			{/*			</header>*/}
			{/*			<Input value={redirectLink} setValue={setRedirectLink} placeholder={'FB-00000000'} />*/}
			{/*		</div>*/}
			{/*		<div className={settingsStyles.fieldWrapper}>*/}
			{/*			<header>*/}
			{/*				<h2>GOOGLE TAGS MANAGER ID</h2>*/}
			{/*				<a className={styles.link} href='' target={'_blank'}>*/}
			{/*					<OpenLinkSVG />*/}
			{/*					<p>TIKTOK PIXEL ID</p>*/}
			{/*				</a>*/}
			{/*			</header>*/}
			{/*			<Input value={redirectLink} setValue={setRedirectLink} placeholder={'TK-00000000'} />*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</section>*/}
		</div>
	)
}

export default SettingsCheckoutPage
