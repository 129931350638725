import React from 'react'
import styles from './TicketsPage.module.scss'
import Container from '../../components/UI/Container/Container'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import Button from '../../components/UI/Button/Button'
import CustomerTicket from '../../components/CustomerTicket/CustomerTicket'
import TicketMessage from '../../components/TicketMessage/TicketMessage'
import MessageForm from '../../components/MessageForm/MessageForm'

const TicketsPage = () => {
	return (
		<Container>
			<PageWrapper>
				<div className={styles.ticketsPage}>
					<div className={styles.sidebar}>
						<header className={styles.sidebarHeader}>
							<h1 className={styles.h1}>Inbox</h1>
						</header>
						<div className={styles.sidebarBody}>
							<div className={styles.filters}>
								<select>
									<option value=''>3 Open</option>
								</select>
								<select>
									<option value=''>Newest</option>
								</select>
							</div>

							<div className={styles.tickets}>
								<CustomerTicket />
								<CustomerTicket />
								<CustomerTicket />
								<CustomerTicket />
								<CustomerTicket />
								<CustomerTicket />
								<CustomerTicket />
							</div>
						</div>
					</div>
					<div className={styles.openTicket}>
						<header className={styles.openTicketHeader}>
							<div>
								<p>help@gmail.com</p>
								<p>45826b-0b6e79c8a0-f674ba</p>
							</div>
							<Button>Close</Button>
						</header>
						<header className={styles.openTicketSecondHeader}>
							<div className={styles.addressWrapper}>
								<p>IP: 123.123.123</p>
								<p>Country: UNITED STATES</p>
							</div>
							<button>Issue Refund</button>
						</header>
						<div className={styles.openTicketBody}>
							<div className={styles.messagesWrapper}>
								<TicketMessage isUsers={true} />
								<TicketMessage isUsers={false} />
								<TicketMessage isUsers={false} />
								<TicketMessage isUsers={false} />
							</div>
							<div className={styles.messageFormWrapper}>
								<MessageForm />
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</Container>
	)
}

export default TicketsPage
