import React from 'react'

const UploadSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.20953 10.7988V12.2988C1.20953 13.9557 2.55268 15.2988 4.20953 15.2988H12.7095C14.3664 15.2988 15.7095 13.9557 15.7095 12.2988V10.7988M8.45953 10.2988V1.04883M5.20953 4.29883L8.45953 0.798828L11.7095 4.29883'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default UploadSvg
