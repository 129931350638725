import React, { FC, SVGProps } from 'react'

const CopyIcon: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{ cursor: 'pointer' }}
			{...props}
		>
			<path
				d='M2.87451 11.624C1.90801 11.624 1.12451 10.8405 1.12451 9.87402V3.12402C1.12451 2.01945 2.01994 1.12402 3.12451 1.12402H9.87451C10.841 1.12402 11.6245 1.90752 11.6245 2.87402M7.12451 5.12402H13.6245C14.7291 5.12402 15.6245 6.01945 15.6245 7.12402V13.624C15.6245 14.7286 14.7291 15.624 13.6245 15.624H7.12451C6.01994 15.624 5.12451 14.7286 5.12451 13.624V7.12402C5.12451 6.01945 6.01994 5.12402 7.12451 5.12402Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
				style={{
					stroke: 'var(--dark_color)',
				}}
			/>
		</svg>
	)
}

export default CopyIcon
