import React from 'react'

const DesktopSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.56689 12.083H14.0669C15.1715 12.083 16.0669 11.1876 16.0669 10.083V3.58301C16.0669 2.47844 15.1715 1.58301 14.0669 1.58301H3.56689C2.46232 1.58301 1.56689 2.47844 1.56689 3.58301V10.083C1.56689 11.1876 2.46232 12.083 3.56689 12.083H6.56689ZM6.56689 12.083C6.56689 12.083 6.81689 15.083 4.81689 16.083H12.8169C10.8169 15.083 11.0669 12.083 11.0669 12.083'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default DesktopSvg
