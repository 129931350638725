import React, { ReactNode, useEffect, useRef } from 'react'
import styles from './AuthPage.module.scss'
import AuthSignUp from './AuthSignUp'
import AuthSignIn from './AuthSignIn'
import AuthResetPassword from './AuthResetPassword'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import AuthSetUp from './AuthSetUp'
import ReCAPTCHA from 'react-google-recaptcha'
import AuthNewPassword from './AuthNewPassword'
import AuthGoogle from './AuthGoogle'
import AuthHeader from '../../components/AuthHeader/AuthHeader'
import AuthNewBusiness from './AuthNewBusiness'

const AuthPage = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const pathname = location.pathname
	const recaptchaRef = useRef(null)

	useEffect(() => {
		if (location.pathname === '/auth') {
			navigate('/auth/login')
		}
	}, [])

	return (
		<div className={styles.authPage}>
			<AuthHeader />

			<div className={styles.authInner}>
				<div
					className={`${styles.authFormWrapper} ${
						location.pathname === '/auth/register' && styles.withSidebar
					}`}
				>
					<Routes>
						<Route path={'login'} element={<AuthSignIn recaptchaRef={recaptchaRef} />} />
						<Route path={'register'} element={<AuthSignUp recaptchaRef={recaptchaRef} />} />
						<Route path={'reset'} element={<AuthResetPassword recaptchaRef={recaptchaRef} />} />
						<Route path={'reset/password'} element={<AuthNewPassword />} />
						<Route path={'google/callback'} element={<AuthGoogle />} />
						<Route path={'setup'} element={<AuthSetUp />} />
						<Route path={'new-business'} element={<AuthNewBusiness />} />
					</Routes>
				</div>
			</div>

			<ReCAPTCHA
				ref={recaptchaRef}
				sitekey={'6Ld5nT8kAAAAAO2Yd9KUvLwnP6EENe8KnhWuVMmn'}
				size={'invisible'}
			/>
		</div>
	)
}

export default AuthPage
