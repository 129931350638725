import React from 'react'

const WebSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.5 15.4705C12.5041 15.4705 15.75 12.2245 15.75 8.22046C15.75 4.21639 12.5041 0.970459 8.5 0.970459M8.5 15.4705C4.49594 15.4705 1.25 12.2245 1.25 8.22046C1.25 4.21639 4.49594 0.970459 8.5 0.970459M8.5 15.4705C9.7426 15.4705 11.75 12.7205 11.75 8.22046C11.75 3.72046 9.7426 0.970459 8.5 0.970459M8.5 15.4705C7.2574 15.4705 5.25 12.7205 5.25 8.22046C5.25 3.72046 7.2574 0.970459 8.5 0.970459M1.5 8.22046H15.5'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default WebSvg
