import { privateAPI } from './API'

export const BusinessesCustomersService = {
	getCustomers: async (
		businessId: number | undefined,
		PageNumber: number,
		PageSize: number,
		filter: string | null,
		searchString: string | null
	) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/customers`, {
			params: {
				PageNumber,
				PageSize,
				filter,
				searchString,
			},
		})
	},
	createCustomer: async (
		businessId: number | undefined,
		data: { email: string; name: string | null }
	) => {
		return await privateAPI.post(`/v1/dash/businesses/${businessId}/customers`, data)
	},
	getCustomer: async (businessId: number | undefined, customerId: string | null) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/customers/${customerId}`)
	},
	updateCustomerNote: async (
		businessId: number | undefined,
		customerId: string | null,
		data: {
			note: string | null
		}
	) => {
		return await privateAPI.put(
			`/v1/dash/businesses/${businessId}/customers/${customerId}/note`,
			data
		)
	},
}
