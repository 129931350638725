import { privateAPI } from './API'

export const PaymentsDashboardService = {
	getDashboard: async (
		businessId: number | undefined,
		fromTime: Date | null,
		toTime: Date | null
	) => {
		return await privateAPI.get(`/v1/businesses/${businessId}/payments/dashboard`, {
			params: {
				fromTime,
				toTime,
			},
		})
	},
}
