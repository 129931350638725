import { privateAPI } from './API'
import { ApiKeyPost } from '../types/ApiKeyPost'

export const UserSettingsDeveloperService = {
	getApiKeys: async () => {
		return await privateAPI.get(`/v1/dash/user/settings/developer/api-keys`)
	},
	postApiKeys: async (apiKey: ApiKeyPost) => {
		return await privateAPI.post(`/v1/dash/user/settings/developer/api-keys`, apiKey)
	},
	deleteApiKey: async (apiKeyId: number) => {
		return await privateAPI.delete(`/v1/dash/user/settings/developer/api-keys/${apiKeyId}`)
	},
}
