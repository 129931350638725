import React, { FC, useState } from 'react'
import styles from './Modals.module.scss'
import Input from '../UI/FormComponents/Input'
import Checkbox from '../UI/Checkbox/Checkbox'
import Button from '../UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { BusinessSettingsDeveloperService } from '../../API/BusinessSettingsDeveloperService'

interface WebhookSharedSecretSideModalProps {
	sharedSecret?: string
	businessId?: number
	getWebhooks: () => void
}

const WebhookSharedSecretModal: FC<WebhookSharedSecretSideModalProps> = ({
	sharedSecret,
	businessId,
	getWebhooks,
}) => {
	const [isLoading, , resetSharedSecret] = useAPI(
		() => BusinessSettingsDeveloperService.resetWebhooksSecret(businessId),
		false,
		'Shared secret successfully reset',
		{
			onSuccess: () => {
				getWebhooks()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<h1 className={styles.h1}>Webhook Shared Secret</h1>
			<Input
				disabled={true}
				value={sharedSecret || ''}
				setValue={() => {}}
				placeholder={'7b47695f-f9e8-4069-9f2c-573828bd63ca'}
			/>

			<div className={styles.button}>
				<Button isLoading={isLoading} onClick={resetSharedSecret}>
					Rotate Shared Secret
				</Button>
			</div>
		</div>
	)
}

export default WebhookSharedSecretModal
