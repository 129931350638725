import React, { useEffect, useState } from 'react'
import styles from './WebhookEventPage.module.scss'
import SettingsButton from '../../components/UI/SettingsButton/SettingsButton'
import Container from '../../components/UI/Container/Container'
import WebhookEventAttempts from '../../components/WebhookEventAttempts/WebhookEventAttempts'
import ActionMenu from '../../components/UI/ActionMenu/ActionMenu'
import CopyIcon from '../../images/CopyIcon'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import { WebhookService } from '../../API/WebhookService'
import { WebhookDetailed } from '../../types/WebhookDetailed'
import StatusBadge from '../../components/UI/StatusBadge/StatusBadge'
import Spinner from '../../components/UI/Spinner/Spinner'
import { Statuses } from '../../types/Status'
import { format } from 'date-fns'

const WebhookEventPage = () => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const params = useParams()
	const [searchParams] = useSearchParams()
	const externalMessageId = params.externalMessageId
	const externalEndpointId = searchParams.get('externalEndpointId')
	const { business } = useAppContext()
	const [isWebhookLoading, webhook, getWebhook] = useAPI<WebhookDetailed>(
		() => WebhookService.get(business?.id, externalMessageId, externalEndpointId || undefined),
		false
	)

	useEffect(() => {
		if (business?.id) {
			getWebhook()
		}
	}, [business])

	if (isWebhookLoading || !webhook) {
		return <Spinner />
	}

	return (
		<Container>
			<div className={styles.eventPage}>
				<div className={styles.eventMainDetails}>
					<div className={styles.eventMainDetailsRightSide}>
						<div className={styles.titles}>
							<p>WEBHOOK EVENT</p>
							<h2>{webhook?.url}</h2>
						</div>
					</div>
					<div className={styles.eventMainDetailsLeftSide}>
						<div className={styles.eventIdDetail}>
							<span>Request ID</span>
							<p>{webhook?.externalMessageId}</p>
						</div>

						<div className={styles.actionBadgeWrapper}>
							<SettingsButton onClick={() => setIsActionMenuActive(true)} />
							<ActionMenu
								actionButtons={[
									{
										icon: <CopyIcon />,
										title: 'Copy ID',
										onClick: () =>
											window.navigator.clipboard.writeText(webhook?.externalMessageId as string),
									},
								]}
								isActive={isActionMenuActive}
								setIsActive={setIsActionMenuActive}
							/>
						</div>
					</div>
				</div>
				<div className={styles.eventDetails}>
					<div className={styles.eventDetailsItem}>
						<span>Status</span>
						<StatusBadge statusType={webhook?.status || Statuses.CONFIRMING} />
					</div>
					<div className={styles.eventDetailsItem}>
						<span>Created At</span>
						<p>{format(new Date(webhook?.createdAt), 'MMM dd, yyyy')}</p>
						<span>{format(new Date(webhook?.createdAt), 'HH:mm aa zzzz')}</span>
					</div>
					<div className={styles.eventDetailsItem}>
						<span>Event</span>
						<p>{webhook.event}</p>
					</div>
					<div className={styles.eventDetailsItem}>
						<span>Next Attempt</span>
						<p>
							{format(
								new Date(webhook.attempts[webhook.attempts.length - 1].createdAt),
								'MMM dd, yyyy'
							)}
						</p>
						<span>{format(new Date(webhook?.createdAt), 'HH:mm aa zzzz')}</span>
					</div>
				</div>
				<WebhookEventAttempts attempts={webhook.attempts} payloadJson={webhook.payloadJson} />
			</div>
		</Container>
	)
}

export default WebhookEventPage
