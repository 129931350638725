import React, { useEffect } from 'react'
import './App.scss'
import Header from './components/Header/Header'
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import HomePage from './pages/HomePage/HomePage'
import PaymentsPage from './pages/PaymentsPage/PaymentsPage'
import PaymentDetailPage from './pages/PaymentDetailPage/PaymentDetailPage'
import CustomersPage from './pages/CustomersPage/CustomersPage'
import Settings from './pages/Settings/Settings'
import WebhookEventPage from './pages/WebhookEventPage/WebhookEventPage'
import Footer from './components/Footer/Footer'
import AuthPage from './pages/AuthPage/AuthPage'
import ActionMessage from './components/UI/ActionMessage/ActionMessage'
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import Sidebar from './components/Sidebar/Sidebar'
import BalancesPage from './pages/BalancesPage/BalancesPage'
import Deactivated from './components/Deactivated/Deactivated'
import TicketsPage from './pages/TicketsPage/TicketsPage'

function App() {
	const location = useLocation()
	const isAuthPage = location.pathname.includes('auth')

	return (
		<div className={`App ${isAuthPage && 'authPage'}`}>
			{!isAuthPage && (
				<>
					<Sidebar />
					<Header />
				</>
			)}

			<ActionMessage />

			{/*<Deactivated />*/}

			<div className={`AppContent`}>
				<ErrorBoundary>
					<Routes>
						<Route index path='/' element={<HomePage />} />
						<Route path='/payments' element={<PaymentsPage />} />
						<Route path='/analytics' element={<AnalyticsPage />} />
						<Route path='/balances/*' element={<BalancesPage />} />
						<Route path='/customers' element={<CustomersPage />} />
						<Route path='/tickets' element={<TicketsPage />} />
						<Route path='/settings/*' element={<Settings />} />
						<Route path='/payments/:id' element={<PaymentDetailPage />} />
						<Route path='/webhook-events/:externalMessageId' element={<WebhookEventPage />} />
						<Route path='/auth/*' element={<AuthPage />} />
					</Routes>
				</ErrorBoundary>
			</div>
			{/*<Footer />*/}
		</div>
	)
}

export default App
