import React, { FC, useEffect, useState } from 'react'
import styles from './SettingsLegalPage.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import Switch from '../../../components/UI/Switch/Switch'
import { useAPI } from '../../../hooks/useAPI'
import { useAppContext } from '../../../hooks/useAppContext'
import { BusinessSettingsLegalService } from '../../../API/BusinessSettingsLegalService'
import { SettingsPageProps } from '../../../types/SettingsPageProps'

const SettingsLegalPage: FC<SettingsPageProps> = ({ settings, getSettings }) => {
	const { business } = useAppContext()
	const [companyNumber, setCompanyNumber] = useState('')
	const [terms, setTerms] = useState('')
	const [privacyPolicy, setPrivacyPolicy] = useState('')
	const [termsCheckbox, setTermsCheckbox] = useState(false)
	const [fieldsUnchangedValues, setFieldsUnchangedValues] = useState({
		companyNumber: '',
		terms: '',
		privacyPolicy: '',
		termsCheckbox: false,
	})
	const [isCompanyNumberLoading, , updateCompanyNumber] = useAPI(
		() => BusinessSettingsLegalService.updateCompanyNumber(business?.id, companyNumber),
		false,
		'Company number successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isTermsLoading, , updateTerms] = useAPI(
		() => BusinessSettingsLegalService.updateTerms(business?.id, terms),
		false,
		'Terms successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isPrivacyPolicyLoading, , updatePrivacyPolicy] = useAPI(
		() => BusinessSettingsLegalService.updatePrivacyPolicy(business?.id, privacyPolicy),
		false,
		'Privacy policy successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)
	const [isTermsCheckboxLoading, , updateTermsCheckbox] = useAPI(
		(value: boolean) => BusinessSettingsLegalService.updateTermsCheckbox(business?.id, value),
		false,
		'Terms consent successfully updated',
		{
			onSuccess: () => {
				getSettings(business?.id)
			},
		}
	)

	useEffect(() => {
		if (settings) {
			setFieldsUnchangedValues({
				companyNumber: settings?.companyNumber || '',
				terms: settings?.termsUrl || '',
				privacyPolicy: settings?.privacyPolicyUrl || '',
				termsCheckbox: settings?.termsCheckbox || false,
			})
			setCompanyNumber(settings?.companyNumber || '')
			setTerms(settings?.termsUrl || '')
			setPrivacyPolicy(settings?.privacyPolicyUrl || '')
			setTermsCheckbox(settings?.termsCheckbox || false)
		}
	}, [settings])

	return (
		<div className={styles.settingsLegalPage}>
			<div className={styles.fieldBox}>
				<header>
					<h2>Registered Company Number</h2>
					<p>This can be a VAT, tax, or any other legal ID for your company</p>
				</header>
				<div className={styles.fieldBoxBody}>
					<Input
						value={companyNumber}
						setValue={value => setCompanyNumber(value)}
						placeholder={'GB999999999'}
					/>
					<div
						className={`${styles.buttonWrapper} ${
							fieldsUnchangedValues.companyNumber !== companyNumber ? styles.active : ''
						}`}
					>
						<Button
							style={ButtonStyles.SECONDARY}
							isLoading={isCompanyNumberLoading}
							onClick={updateCompanyNumber}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
			<div className={styles.fieldBox}>
				<header>
					<h2>Terms of Service</h2>
					<p>A link to your terms of service will be present at checkout</p>
				</header>
				<div className={styles.fieldBoxBody}>
					<Input
						value={terms}
						setValue={value => setTerms(value)}
						placeholder={'https://example.com/terms-of-service'}
					/>
					<div
						className={`${styles.buttonWrapper} ${
							fieldsUnchangedValues.terms !== terms ? styles.active : ''
						}`}
					>
						<Button style={ButtonStyles.SECONDARY} isLoading={isTermsLoading} onClick={updateTerms}>
							Save
						</Button>
					</div>
				</div>
			</div>
			<div className={styles.fieldBox}>
				<header>
					<h2>Privacy Policy</h2>
					<p>A link to your privacy policy will be present at checkout</p>
				</header>
				<div className={styles.fieldBoxBody}>
					<Input
						value={privacyPolicy}
						setValue={value => setPrivacyPolicy(value)}
						placeholder={'https://example.com/privacy-policy'}
					/>
					<div
						className={`${styles.buttonWrapper} ${
							fieldsUnchangedValues.privacyPolicy !== privacyPolicy ? styles.active : ''
						}`}
					>
						<Button
							style={ButtonStyles.SECONDARY}
							isLoading={isPrivacyPolicyLoading}
							onClick={updatePrivacyPolicy}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
			<div className={`${styles.fieldBox} ${styles.switch}`}>
				<header>
					<h2>Require Consent to Terms</h2>
					<p>Customers will be required to consent to your terms before checkout</p>
				</header>
				<div className={styles.fieldBoxBody}>
					<div className={styles.switchWrapper}>
						<Switch
							checked={termsCheckbox}
							setChecked={checked => {
								setTermsCheckbox(checked)
								updateTermsCheckbox(checked)
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SettingsLegalPage
