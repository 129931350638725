import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './DatePicker.module.scss'
import { Calendar } from 'react-date-range'
import CalendarSVG from '../../../images/CalendarSVG'
import { format } from 'date-fns'

interface DatePickerProps {
	date: Date
	setDate: (date: Date) => void
}

const DatePicker: FC<DatePickerProps> = ({ date, setDate }) => {
	const [isActive, setIsActive] = useState(false)
	const datePickerRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: any) => {
		if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
			setIsActive(false)
		}
	}

	useEffect(() => {
		if (isActive) {
			datePickerRef.current?.classList.add(styles.active)
			setTimeout(() => {
				datePickerRef.current?.classList.add(styles.visible)
			}, 10)
		} else {
			datePickerRef.current?.classList.remove(styles.visible)
			setTimeout(() => {
				datePickerRef.current?.classList.remove(styles.active)
			}, 200)
		}

		if (datePickerRef.current) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isActive])
	return (
		<div className={styles.datePicker} ref={datePickerRef} onClick={() => setIsActive(!isActive)}>
			<div className={styles.front}>{format(date, 'MMM dd, yyyy')}</div>
			<div className={styles.interface} onClick={event => event.stopPropagation()}>
				<Calendar
					color={'#A7E23D'}
					rangeColors={['#A7E23D', '#A7E23D']}
					className={styles.dateRange}
					date={date}
					onChange={setDate}
					classNames={{
						dayToday: styles.datePickerDayToday,
						nextButton: styles.dateRangeNextButton,
						prevButton: styles.dateRangePrevButton,
						nextPrevButton: styles.dateRangeNextPrevButton,
						dayPassive: styles.dateRangeDayPassive,
						weekDay: styles.dateRangeWeekDay,
						dayNumber: styles.dateRangeDayNumber,
						dateDisplayItem: styles.dateRangeDisplayItem,
						dateDisplayWrapper: styles.dateRangeDisplayWrapper,
					}}
				/>
			</div>
		</div>
	)
}

export default DatePicker
