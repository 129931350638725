import { Cryptocurrencies, Cryptocurrency } from '../types/Cryptocurrencies'

import moneroIcon from '../images/Monero.svg'

import { PaymentMethods } from '../types/PaymentMethods'

export const getPaymentMethodIconId = (paymentMethod?: Cryptocurrency | PaymentMethods) => {
	switch (paymentMethod) {
		case Cryptocurrencies.BITCOIN:
			return 'bitcoin'
		case Cryptocurrencies.BITCOIN_CASH:
			return 'bitcoinCash'
		case Cryptocurrencies.LITECOIN:
			return 'litecoin'
		case Cryptocurrencies.ETH_BUSD:
			return 'binanceUsd'
		case Cryptocurrencies.ETH_DAI:
			return 'dai'
		case Cryptocurrencies.ETH_CRONOS:
			return 'cronos'
		case Cryptocurrencies.MONERO:
			return moneroIcon
		case Cryptocurrencies.ETHEREUM:
			return 'ethereum'
		case Cryptocurrencies.ETH_TETHER:
			return 'tether'
		case Cryptocurrencies.TRX_TETHER:
			return 'tether'
		case Cryptocurrencies.ETH_BNB:
			return 'binanceCoin'
		case Cryptocurrencies.ETH_USD_COIN:
			return 'usdCoin'
		case Cryptocurrencies.TRX_USD_C:
			return 'usdCoin'
		case Cryptocurrencies.ETH_MATIC:
			return 'polygon'
		case Cryptocurrencies.ETH_UNISWAP:
			return 'uniswap'
		case Cryptocurrencies.ETH_APE_COIN:
			return 'apeCoin'
		case Cryptocurrencies.ETH_SHIBA_INU:
			return 'shibaInu'
		case Cryptocurrencies.TRON:
			return 'tron'
		case PaymentMethods.APPLE_PAY:
			return 'applePay'
		case PaymentMethods.GOOGLE_PAY:
			return 'googlePay'
		case PaymentMethods.CASHAPP:
			return 'cashapp'
		case PaymentMethods.BANK_CARD:
			return 'bankCard'
		case PaymentMethods.WIRE:
			return 'wire'
		case PaymentMethods.VENMO:
			return 'venmo'
		case PaymentMethods.OPEN_BANKING:
			return 'bankTransfer'
		case PaymentMethods.SEPA:
			return 'sepa'
	}
}
