import React, { useEffect, useState } from 'react'
import styles from './AnalyticsPage.module.scss'
import AnalyticsStatistics from '../../components/AnalyticsStatistics/AnalyticsStatistics'
import DateRangePicker from '../../components/UI/DateRangePicker/DateRangePicker'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import Container from '../../components/UI/Container/Container'
import { useAPI } from '../../hooks/useAPI'
import { BusinessesAnalyticsDashboardService } from '../../API/BusinessesAnalyticsDashboardService'
import { useAppContext } from '../../hooks/useAppContext'
import { AnalyticsStatData } from '../../types/AnalyticsStatData'

const AnalyticsPage = () => {
	const { business } = useAppContext()
	const [fromDate, setFromDate] = useState<Date | null>(() => {
		const today = new Date()
		const yesterday = new Date(today)
		yesterday.setDate(today.getDate() - 1)
		return yesterday
	})
	const [toDate, setToDate] = useState<Date | null>(new Date())
	const durationMs = toDate && fromDate ? toDate.getTime() - fromDate.getTime() : 0
	const prevFromDate = fromDate ? new Date(fromDate.getTime() - durationMs) : null

	const [isLoading, dashboardData, getDashboard] = useAPI<{
		revenueChartDate: AnalyticsStatData[]
	}>(() => BusinessesAnalyticsDashboardService.getDashboard(business?.id, fromDate, toDate), false)

	const [isPrevLoading, prevDashboardData, getPrevDashboard] = useAPI<{
		revenueChartDate: AnalyticsStatData[]
	}>(
		() => BusinessesAnalyticsDashboardService.getDashboard(business?.id, prevFromDate, fromDate),
		false
	)

	useEffect(() => {
		if (business?.id) {
			getDashboard()
			getPrevDashboard()
		}
	}, [fromDate, toDate, business])

	return (
		<PageWrapper>
			<Container>
				<div className={styles.analyticsPage}>
					<header className={styles.header}>
						<div>
							<h1>Analytics</h1>
							<p>Graph breakdown of payments activity across this workspace</p>
						</div>
						<DateRangePicker
							toDate={toDate}
							fromDate={fromDate}
							setFromDate={setFromDate}
							setToDate={setToDate}
						/>
					</header>
					<AnalyticsStatistics
						analyticsStatDatas={dashboardData?.revenueChartDate}
						prevAnalyticsStatDatas={prevDashboardData?.revenueChartDate}
						toDate={toDate}
						fromDate={fromDate}
						durationMs={durationMs}
					/>
				</div>
			</Container>
		</PageWrapper>
	)
}

export default AnalyticsPage
