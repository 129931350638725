import React, { useState } from 'react'
import styles from './MessageForm.module.scss'
import ArrowSvg from '../../images/ArrowSVG'

const MessageForm = () => {
	const [message, setMessage] = useState('')

	return (
		<div className={styles.messageForm}>
			<textarea
				value={message}
				onChange={event => setMessage(event.target.value)}
				placeholder={'Type a message...'}
			/>
			<button>
				<ArrowSvg />
			</button>
		</div>
	)
}

export default MessageForm
