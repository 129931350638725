export enum Statuses {
	SUCCESS = 'COMPLETED',
	CONFIRMING = 'PROCESSING',
	NEW = 'NEW',
	EXPIRED = 'EXPIRED',
	PENDING = 'PENDING',
	FAILED = 'FAILED',
	CANCELLED = 'CANCELLED',
	AWAITING_PAYMENT = 'AWAITING_PAYMENT',
	UNDERPAID = 'UNDERPAID',
}

export type Status =
	| Statuses.NEW
	| Statuses.EXPIRED
	| Statuses.CONFIRMING
	| Statuses.SUCCESS
	| Statuses.CANCELLED
	| Statuses.FAILED
	| Statuses.PENDING
	| Statuses.AWAITING_PAYMENT
	| Statuses.UNDERPAID
