export enum WebhookEventsEnum {
	PAYMENT_CREATED = 'PAYMENT_CREATED',
	PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED',
	PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
	PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
	PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
}

export type WebhookEvent =
	| WebhookEventsEnum.PAYMENT_CANCELLED
	| WebhookEventsEnum.PAYMENT_COMPLETED
	| WebhookEventsEnum.PAYMENT_CREATED
	| WebhookEventsEnum.PAYMENT_EXPIRED
	| WebhookEventsEnum.PAYMENT_METHOD_SELECTED
