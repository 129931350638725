import React, { FC, useState } from 'react'
import styles from './FormComponents.module.scss'
const currencies = [
	'USD',
	'EUR',
	'JPY',
	'GBP',
	'AUD',
	'CAD',
	'CHF',
	'CNY',
	'SEK',
	'NZD',
	'PLN',
	'ILS',
	'HKD',
	'ISK',
	'PHP',
	'DKK',
	'HUF',
	'CZK',
	'RON',
	'IDR',
	'INR',
	'BRL',
	'RUB',
	'HRK',
	'THB',
	'MYR',
	'BGN',
	'NOK',
	'ZAR',
	'MXN',
	'SGD',
	'KRW',
]

interface PriceFieldProps {
	currency: string
	setCurrency: (value: string) => void
	setPrice: (value: string) => void
	price: string
}
const PriceField: FC<PriceFieldProps> = ({ setPrice, price, currency, setCurrency }) => {
	const [isFocused, setIsFocused] = useState(false)
	const [isHovered, setIsHovered] = useState(false)

	return (
		<div>
			<div
				className={`${styles.priceField} ${isHovered ? styles.hover : ''} ${
					isFocused ? styles.focus : ''
				}`}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<select
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					value={currency}
					onChange={event => setCurrency(event.target.value)}
				>
					{currencies.map(currency => (
						<option value={currency} key={currency}>
							{currency}
						</option>
					))}
				</select>
				<input
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					type='number'
					value={price}
					onChange={event => setPrice(event.target.value)}
					placeholder={'0.00'}
				/>
			</div>
		</div>
	)
}

export default PriceField
