export enum PaymentMethods {
	PAYPAL = 'PAYPAL',
	VENMO = 'VENMO',
	BANK_CARD = 'BANK_CARD',
	CRYPTO = 'CRYPTO',
	WIRE = 'WIRE',
	CASHAPP = 'CASHAPP',
	APPLE_PAY = 'APPLE_PAY',
	GOOGLE_PAY = 'GOOGLE_PAY',
	SEPA = 'SEPA',
	OPEN_BANKING = 'OPEN_BANKING',
}
