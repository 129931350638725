import React, { FC, useState } from 'react'
import styles from './PaymentsTable.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import CopyIcon from '../../images/CopyIcon'
import DollarSVG from '../../images/DollarSVG'
import { Payment } from '../../types/Payment'
import getSymbolFromCurrency from 'currency-symbol-map'
import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { useCopy } from '../../hooks/useCopy'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import PaymentMethod from '../PaymentMethod/PaymentMethod'

interface PaymentsTableBodyProps {
	payment: Payment
	search?: boolean
}

const PaymentsTableBody: FC<PaymentsTableBodyProps> = ({ payment, search }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const copyToClipboard = useCopy()
	const navigate = useNavigate()
	const cryptCharge =
		payment.directCryptoCharge || payment.xPubCryptoCharge || payment.onRampCryptoCharge

	return (
		<div className={`${styles.tableBodyWrapper} ${search && styles.search}`}>
			<Link to={`/payments/${payment.id}`} className={styles.tableBody}>
				<div className={styles.tableBodyItem}>
					<h4>
						{getSymbolFromCurrency(payment.currency)}
						{payment.endAmount.toFixed(2)}
					</h4>
					{cryptCharge && (
						<span className={styles.span}>
							{cryptCharge?.amount} {getCryptoShortName(cryptCharge.coinName)}
						</span>
					)}
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<StatusBadge statusType={payment.status} />
				</div>
				<div className={`${styles.tableBodyItem} ${styles.large}`}>
					<p>{payment.description}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<PaymentMethod
						paymentMethod={payment?.paymentMethod || payment.onRampCryptoCharge?.paymentMethod}
					/>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{payment.customerEmail || '-'}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{format(new Date(payment.createdAt), 'MMM dd, HH:mm aa')}</p>
				</div>
				<div className={`${styles.tableBodyItem} ${styles.settings}`}>
					<div className={styles.actionMenuWrapper}>
						<SettingsButton
							onClick={event => {
								event.stopPropagation()
								event.preventDefault()
								setIsActionMenuActive(!isActionMenuActive)
							}}
						/>
						<ActionMenu
							actionButtons={[
								{
									icon: <CopyIcon />,
									onClick: () => copyToClipboard(payment.id),
									title: 'Copy payment ID',
								},
								{
									icon: <DollarSVG />,
									onClick: () => navigate(`/payments/${payment.id}`),
									title: 'View more details',
								},
							]}
							isActive={isActionMenuActive}
							setIsActive={setIsActionMenuActive}
						/>
					</div>
				</div>
			</Link>
		</div>
	)
}

export default PaymentsTableBody
