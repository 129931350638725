import React, { FC, useContext, useState } from 'react'
import styles from './Navigation.module.scss'
import { Link, useLocation } from 'react-router-dom'
import HomeIcon from '../../images/HomeIcon'
import GearIcon from '../../images/GearIcon'
import CardSVG from '../../images/CardSVG'
import EarthSVG from '../../images/EarthSVG'
import BankSVG from '../../images/BankSVG'
import ContactMessageIcon from '../../images/ContactMessageIcon'

interface NavigationProps {
	isVertical?: boolean
}

const Navigation: FC<NavigationProps> = ({ isVertical }) => {
	const location = useLocation()

	return (
		<nav className={`${styles.nav} ${isVertical ? styles.vertical : ''}`}>
			<ul>
				<li>
					<Link to={'/'} className={location.pathname === '/' ? styles.active : ''}>
						<HomeIcon /> <span>Home</span>
					</Link>
				</li>
				<li>
					<Link to={'/payments'} className={location.pathname === '/payments' ? styles.active : ''}>
						<CardSVG /> <span>Payments</span>
					</Link>
				</li>
				{/*<li className={location.pathname === '/balances' ? styles.active : ''}>*/}
				{/*	<Link to={'/balances'}>*/}
				{/*		<BankSVG /> <span>Balances</span>*/}
				{/*	</Link>*/}
				{/*</li>*/}
				{/*<li className={location.pathname === '/tickets' ? styles.active : ''}>*/}
				{/*	<Link to={'/tickets'}>*/}
				{/*		<ContactMessageIcon /> <span>Tickets</span>*/}
				{/*	</Link>*/}
				{/*</li>*/}
				<li>
					<Link
						to={'/customers'}
						className={location.pathname === '/customers' ? styles.active : ''}
					>
						<EarthSVG /> <span>Customers</span>
					</Link>
				</li>
				<li>
					<Link
						to={'/settings/general'}
						className={location.pathname.includes('settings') ? styles.active : ''}
					>
						<GearIcon /> <span>Settings</span>
					</Link>
				</li>
			</ul>
		</nav>
	)
}

export default Navigation
