import React from 'react'

const TaxIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13.4852 6.08203L8.98517 1.58203M13.4852 6.08203H9.98517C9.71995 6.08203 9.4656 5.97667 9.27806 5.78914C9.09053 5.6016 8.98517 5.34725 8.98517 5.08203V1.58203M13.4852 6.08203V7.08203M8.98517 1.58203H2.98517C2.45474 1.58203 1.94603 1.79274 1.57095 2.16782C1.19588 2.54289 0.985168 3.0516 0.985168 3.58203V14.082C0.985168 14.6125 1.19588 15.1212 1.57095 15.4962C1.94603 15.8713 2.45474 16.082 2.98517 16.082H6.48517M9.98517 11.682L12.7352 10.582L15.4852 11.682C15.4852 11.682 15.4852 16.082 12.7352 16.082C9.98517 16.082 9.98517 11.682 9.98517 11.682Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default TaxIcon
