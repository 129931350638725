import { privateAPI } from './API'

export const BusinessSettingsGeneralService = {
	updateSupportEmail: async (businessId: number | undefined, supportEmail: string) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/general/support-email`, {
			supportEmail,
		})
	},
	updateName: async (businessId: number | undefined, name: string) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/general/name`, {
			name,
		})
	},
	updateDefaultCurrency: async (businessId: number | undefined, defaultCurrency: string) => {
		return await privateAPI.put(
			`v1/dash/businesses/${businessId}/settings/general/default-currency`,
			{
				defaultCurrency,
			}
		)
	},
	updateDesign: async (
		businessId: number | undefined,
		data: {
			mainHexColor: string
		}
	) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/general/design`, data)
	},
	updateLogo: async (businessId: number | undefined, formData: FormData, token?: string) => {
		return await privateAPI.put(
			`/v1/dash/businesses/${businessId}/settings/general/design/logo`,
			formData,
			{
				headers: {
					Authorization: `bearer ${token || localStorage.getItem('token')}`,
				},
			}
		)
	},
	updateGeneral: async (
		businessId: number | undefined,
		data: {
			defaultCurrency: string
			name: string
			businessWebsiteUrl: string | null
			supportEmail: string | null
			termsOfServiceUrl: string | null
			privacyPolicyUrl: string | null
		}
	) => {
		return await privateAPI.put(`/v1/dash/businesses/${businessId}/settings/general`, data)
	},
}
