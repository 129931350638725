import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './StatItem.module.scss'
import { ResponsiveContainer, LineChart, XAxis, Line, Tooltip, TooltipProps } from 'recharts'
import { StatData } from '../../types/StatData'
import { format } from 'date-fns'

interface StatItemProps {
	withoutGraph?: boolean
	isHorizontal?: boolean
	data?: StatData[]
	prevData?: StatData[]
	isMoney?: boolean
	fromDate: Date | null
	toDate: Date | null
	durationMs?: number
	title: string
	graphHeight?: number
	isAverage?: boolean
}

const StatItem: FC<StatItemProps> = ({
	withoutGraph,
	data,
	isMoney,
	fromDate,
	toDate,
	prevData,
	durationMs,
	title,
	isHorizontal,
	graphHeight,
	isAverage,
}) => {
	const totalValue = useRef({
		count: 0,
		dateOrHour: new Date(),
	})
	const prevTotalAmount = useRef(0)
	const dayMilliseconds = 86400000
	const [focusValue, setFocusValue] = useState<StatData>(totalValue.current)
	const dateDiff = fromDate && toDate ? toDate.getTime() - fromDate.getTime() : 999999999999
	const dateFormat = dateDiff > dayMilliseconds ? 'dd MMM' : 'HH:mm'
	const [handledData, setHandledData] = useState<{ count: number; dateOrHour: string }[]>()
	const [valueDiff, setValueDiff] = useState(0)

	const handleData = (
		data: {
			count: number
			dateOrHour: Date
		}[]
	) => {
		const handledData: { count: number; dateOrHour: string }[] = []
		const count = Math.ceil(dateDiff / dayMilliseconds)
		for (let i = 0; i < (count === 1 ? 25 : count + 2); i++) {
			if (fromDate && toDate) {
				const startDate = new Date(fromDate)
				const currentDate =
					count === 1
						? new Date(startDate.setHours(startDate.getHours() + 1 + i, 0, 0, 0))
						: new Date(new Date(startDate.setDate(startDate.getDate() + i)).setUTCHours(0, 0, 0, 0))
				console.log(currentDate)
				handledData.push({
					count:
						data?.find(item => {
							console.log(new Date(item.dateOrHour).getDay())
							return (
								new Date(item.dateOrHour).getTime() ===
								new Date(currentDate.toISOString()).getTime()
							)
						})?.count || 0,
					dateOrHour: format(currentDate, dateFormat),
				})
			}
		}

		return handledData
	}

	function getDiffCount(x: number, y: number) {
		let diff = 0

		if (x > y) {
			if (y === 0) {
				diff = 100 * x
			} else {
				diff = ((x - y) / y) * 100
			}
		} else if (x < y) {
			if (x === 0) {
				diff = 100 * y
			} else {
				diff = ((y - x) / x) * 100
			}
		}

		return diff
	}

	useEffect(() => {
		const prevValue = prevData?.find(statData => {
			return durationMs
				? new Date(statData.dateOrHour).getTime() ===
						new Date(focusValue.dateOrHour).getTime() - durationMs
				: false
		})
		setValueDiff(getDiffCount(prevValue ? prevValue.count : 0, focusValue.count))
	}, [focusValue])

	useEffect(() => {
		if (data && prevData) {
			const handledCurrentData = handleData(data)
			const handledPrevData = handleData(prevData)
			setHandledData(handledCurrentData)

			const currentDataCount = handledCurrentData
				.map(statData => statData.count)
				.reduce((prevValue, currentValue) => prevValue + currentValue, 0)
			const prevTotalCount = handledPrevData
				.map(statData => statData.count)
				.reduce((prevValue, currentValue) => prevValue + currentValue, 0)

			totalValue.current = {
				count: isAverage
					? currentDataCount > 0
						? currentDataCount / data.filter(item => item.count).length
						: 0
					: currentDataCount,
				dateOrHour: new Date(),
			}
			prevTotalAmount.current = isAverage
				? prevTotalCount > 0
					? prevTotalCount / prevData.length
					: 0
				: prevTotalCount
			setFocusValue(totalValue.current)
			setValueDiff(getDiffCount(totalValue.current.count, prevTotalAmount.current))
		}
	}, [prevData, data])

	return (
		<div
			className={`${styles.statItem} ${withoutGraph ? styles.withoutGraph : ''} ${
				isHorizontal ? styles.horizontal : ''
			}`}
		>
			<div className={styles.statisticsItemInfo}>
				<div>
					<h3>{title}</h3>
					<span className={styles.numbers}>
						{isMoney ? '$' : ''}
						{isMoney ? focusValue.count.toFixed(2) : focusValue.count}
					</span>
				</div>

				{/*<span className={`${styles.changeLabel} ${valueDiff > 0 ? styles.up : styles.down}`}>*/}
				{/*	{valueDiff > 0 ? '↑' : '↓'} {valueDiff.toFixed(2)}%*/}
				{/*</span>*/}
			</div>
			{!withoutGraph && (
				<div className={styles.statisticsItemGraph}>
					<ResponsiveContainer width={'100%'} height={graphHeight || 90}>
						<LineChart
							data={handledData}
							onMouseLeave={() => {
								setFocusValue(totalValue.current)
								setValueDiff(getDiffCount(totalValue.current.count, prevTotalAmount.current))
							}}
						>
							<XAxis dataKey={'dateOrHour'} />
							<XAxis dataKey={'count'} />
							<Line
								type='linear'
								dataKey={'count'}
								stroke='#A7E23D'
								strokeWidth={2}
								dot={false}
								connectNulls={true}
							/>
							<Tooltip
								labelStyle={{
									color: 'var(--light_color)',
								}}
								contentStyle={{
									display: 'none',
									backgroundColor: 'var(--dark_color)',
									borderRadius: 10,
									borderColor: 'none',
								}}
								formatter={(value, name, props) => {
									if (focusValue.dateOrHour !== props.payload.dateOrHour) {
										setFocusValue(props.payload)
									}

									return value
								}}
							/>
						</LineChart>
					</ResponsiveContainer>

					<div className={styles.dates}>
						<span>{fromDate && format(new Date(fromDate), dateFormat)}</span>
						<span>
							{dateDiff > 86400000 ? toDate && format(new Date(toDate), dateFormat) : 'Today'}
						</span>
					</div>
				</div>
			)}
		</div>
	)
}

export default StatItem
