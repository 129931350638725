import React, { useState } from 'react'
import styles from './AuthHeader.module.scss'
import ThemeChanger from '../UI/ThemeChanger/ThemeChanger'

import Logo from '../UI/Logo/Logo'
import Button from '../UI/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'

const AuthHeader = () => {
	const location = useLocation()
	const navigate = useNavigate()

	return (
		<div className={styles.authHeader}>
			<div className={styles.headerLeftSide}>
				<Logo />
			</div>
			<div className={styles.headerRightSide}>
				{location.pathname.includes('login') && (
					<>
						<p>Don’t have a business account?</p>
						<Button onClick={() => navigate('/auth/register')}>Sign up</Button>
					</>
				)}

				<ThemeChanger />
			</div>
		</div>
	)
}

export default AuthHeader
