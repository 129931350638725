import React, { useState } from 'react'
import styles from './SettingsTeamPage.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import Switch from '../../../components/UI/Switch/Switch'
import TeamMembersTable from '../../../components/Tables/TeamMembersTable'
import SideModal from '../../../components/SideModals/SideModal'
import InviteMemberSideModal from '../../../components/SideModals/InviteMemberSideModal'
import { closeModal } from '../../../utils/CloseModal'

const SettingsTeamPage = () => {
	const [enableTeam2FA, setEnableTeam2FA] = useState(false)
	const [isInviteMemberSideModalActive, setIsInviteMemberSideModalActive] = useState(false)
	const [isInviteMemberSideModalVisible, setIsInviteMemberSideModalVisible] = useState(false)
	const [fieldsChangedState, setFieldsChangedState] = useState({
		enableTeam2FA: false,
	})

	const fieldsChangedStateHandler = (fieldName: string) => {
		setFieldsChangedState({
			...fieldsChangedState,
			[fieldName]: true,
		})
	}

	return (
		<div className={styles.settingsTeamPage}>
			{isInviteMemberSideModalActive && (
				<SideModal
					closeModal={() =>
						closeModal(setIsInviteMemberSideModalActive, setIsInviteMemberSideModalVisible)
					}
					visible={isInviteMemberSideModalVisible}
					setVisible={setIsInviteMemberSideModalVisible}
				>
					<InviteMemberSideModal />
				</SideModal>
			)}
			<div className={styles.fieldBox}>
				<header>
					<div>
						<h2>Team Members</h2>
						<p>Members who have access to this workspace (admins can remove and revoke access)</p>
					</div>
					<Button
						onClick={event => {
							event.stopPropagation()
							setIsInviteMemberSideModalActive(true)
						}}
					>
						Invite team members
					</Button>
				</header>
				<TeamMembersTable />
			</div>
			<div className={styles.fieldBox}>
				<header>
					<div>
						<h2>Enable Team 2FA</h2>
						<p>Forces the requirement for team members to enable 2FA</p>
					</div>
					<Switch
						checked={enableTeam2FA}
						setChecked={checked => {
							setEnableTeam2FA(checked)
							fieldsChangedStateHandler('enableTeam2FA')
						}}
					/>
				</header>
				<div
					className={`${styles.buttonWrapper} ${
						fieldsChangedState.enableTeam2FA ? styles.active : ''
					}`}
				>
					<Button style={ButtonStyles.SECONDARY}>Save</Button>
				</div>
			</div>
		</div>
	)
}

export default SettingsTeamPage
