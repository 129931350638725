import React, { useState } from 'react'
import styles from './SideModal.module.scss'
import Input from '../UI/FormComponents/Input'
import Checkbox from '../UI/Checkbox/Checkbox'
import Button from '../UI/Button/Button'

const InviteMemberSideModal = () => {
	const [email, setEmail] = useState('')
	const [permissions, setPermissions] = useState({
		admin: false,
		support: false,
		developer: false,
		accountant: false,
	})

	return (
		<div className={styles.sideModalInner}>
			<h1>Invite Team Member</h1>
			<Input value={email} setValue={setEmail} placeholder={'example@gmail.com'} />

			<div className={styles.checkboxes}>
				<div className={styles.checkboxWrapper}>
					<Checkbox
						id={'admin'}
						checked={permissions.admin}
						setChecked={value =>
							setPermissions({
								...permissions,
								admin: value,
							})
						}
						text={'Administrator'}
					/>
					<p>Member can access your entire business</p>
				</div>
				<div className={styles.checkboxWrapper}>
					<Checkbox
						id={'support'}
						checked={permissions.support}
						setChecked={value =>
							setPermissions({
								...permissions,
								support: value,
							})
						}
						text={'Support'}
					/>
					<p>Members can view and create payment links</p>
				</div>
				<div className={styles.checkboxWrapper}>
					<Checkbox
						id={'developer'}
						checked={permissions.developer}
						setChecked={value =>
							setPermissions({
								...permissions,
								developer: value,
							})
						}
						text={'Developer'}
					/>
					<p>Member can access event and developer logs</p>
				</div>
				<div className={styles.checkboxWrapper}>
					<Checkbox
						id={'accountant'}
						checked={permissions.accountant}
						setChecked={value =>
							setPermissions({
								...permissions,
								accountant: value,
							})
						}
						text={'Accountant'}
					/>
					<p>Members can view payments and analytics</p>
				</div>
			</div>

			<div className={styles.button}>
				<Button>Send Member Invite</Button>
			</div>
		</div>
	)
}

export default InviteMemberSideModal
