export const splitArrayByLength = <T>(array: T[], splitNumber: number) => {
	const resultArray: T[][] = []

	for (let i = 0; i < array.length; i += splitNumber) {
		const chunk = array.slice(i, i + splitNumber)
		resultArray.push(chunk)
	}

	return resultArray
}
