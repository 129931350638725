import React, { useEffect, useState } from 'react'
import styles from './ThemeChanger.module.scss'
import MoonSvg from '../../../images/MoonSvg'

const ThemeSwitch = () => {
	const [theme, setTheme] = useState('default')

	useEffect(() => {
		const savedTheme = localStorage.getItem('theme')

		if (savedTheme) {
			setTheme(savedTheme)
		}
	}, [])

	useEffect(() => {
		if (theme !== 'default') {
			window.document.documentElement.dataset.theme = theme
			localStorage.setItem('theme', theme)
		}
	}, [theme])

	return (
		<div
			className={styles.themeChanger}
			onClick={() => {
				if (theme === 'dark') setTheme('light')
				else setTheme('dark')
			}}
		>
			<MoonSvg />
			{/*<input*/}
			{/*	type='checkbox'*/}
			{/*	id={'themeChanger'}*/}
			{/*	checked={theme === 'dark'}*/}
			{/*	onChange={event => (event.target.checked ? setTheme('dark') : setTheme('light'))}*/}
			{/*/>*/}
			{/*<label*/}
			{/*	htmlFor={'themeChanger'}*/}
			{/*	className={styles.inputAfter}*/}
			{/*	style={{ backgroundImage: `url(${theme === 'light' ? moonIcon : sunIcon})` }}*/}
			{/*/>*/}
		</div>
	)
}

export default ThemeSwitch
