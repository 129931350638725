import React, { FC, ReactNode, RefObject, useEffect } from 'react'
import styles from './Modals.module.scss'

interface ModalProps {
	children: ReactNode
	closeModal: () => void
	visible: boolean
	setVisible: (value: boolean) => void
	refObject?: RefObject<HTMLDivElement>
}

const Modal: FC<ModalProps> = ({ children, closeModal, visible, setVisible, refObject }) => {
	const handleClick = () => {
		closeModal()
	}

	useEffect(() => {
		setVisible(true)
		/* eslint-disable */
	}, [])

	return (
		<div
			className={`${styles.modal} ${visible ? styles.visible : ''}`}
			onClick={handleClick}
			ref={refObject}
		>
			<div
				className={`${styles.modalContent} ${visible ? styles.visible : ''}`}
				onClick={event => event.stopPropagation()}
			>
				{children}
			</div>
		</div>
	)
}

export default Modal
