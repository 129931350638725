import React, { FC, SVGProps } from 'react'

const HourglassSvg: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='12'
			height='13'
			viewBox='0 0 12 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M1.27344 1.27979H10.2744M3.61369 5.06019H7.93416M1.27344 11.7209H10.2744M1.99352 1.27979H9.55434V2.17988C9.55434 4.26774 7.86179 5.96029 5.77393 5.96029C3.68607 5.96029 1.99352 4.26775 1.99352 2.17988V1.27979ZM1.99352 11.7209H9.55434V10.4608C9.55434 8.37291 7.86179 6.68037 5.77393 6.68037C3.68607 6.68037 1.99352 8.37291 1.99352 10.4608V11.7209Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default HourglassSvg
