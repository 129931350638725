import { privateAPI } from './API'

export const BusinessSettingsLegalService = {
	updateTerms: async (businessId: number | undefined, url: string) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/legal/terms`, {
			url,
		})
	},
	updatePrivacyPolicy: async (businessId: number | undefined, url: string) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/legal/privacy-policy`, {
			url,
		})
	},
	updateReturnPolicy: async (businessId: number | undefined, url: string) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/legal/return-policy`, {
			url,
		})
	},
	updateTermsCheckbox: async (businessId: number | undefined, termsCheckbox: boolean) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/legal/terms-checkbox`, {
			termsCheckbox,
		})
	},
	updateCompanyNumber: async (businessId: number | undefined, companyNumber: string) => {
		return await privateAPI.put(`v1/dash/businesses/${businessId}/settings/legal/company-number`, {
			companyNumber,
		})
	},
}
