import React from 'react'

const QuestionMarkSvg = () => {
	return (
		<svg width='19' height='32' viewBox='0 0 19 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1 5.65162C1.66802 4.43369 2.62119 3.38602 3.78236 2.59341C4.94353 1.80079 6.28006 1.28551 7.68377 1.08928C9.08748 0.89304 10.5189 1.02136 11.8622 1.46385C13.2055 1.90634 14.423 2.65057 15.416 3.63629C16.409 4.62201 17.1496 5.82152 17.578 7.13774C18.0064 8.45395 18.1105 9.84988 17.8818 11.2125C17.6531 12.5752 17.0981 13.8663 16.2617 14.9812C15.4253 16.0962 14.331 17.0038 13.0674 17.6305C10.9761 18.6672 8.97195 20.4365 8.97195 22.723M8.97195 30.4827V31'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default QuestionMarkSvg
