import React from 'react'

const CardSvg = () => {
	return (
		<svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.5 6.04883H15.5M4.25 10.0488H6.75M12.25 10.0488H12.75M1.25 3.54883C1.25 2.44426 2.14543 1.54883 3.25 1.54883H13.75C14.8546 1.54883 15.75 2.44426 15.75 3.54883V12.0488C15.75 13.1534 14.8546 14.0488 13.75 14.0488H3.25C2.14543 14.0488 1.25 13.1534 1.25 12.0488V3.54883Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CardSvg
