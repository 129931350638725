import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import styles from './AuthPage.module.scss'
import ProfileIcon from '../../images/ProfileIcon'
import HourglassSVG from '../../images/HourglassSVG'
import MarketSVG from '../../images/MarketSVG'
import PaperSVG from '../../images/ScrollSVG'
import Xsvg from '../../images/XSVG'
import CreateAccountForm from '../../components/AuthForms/CreateAccountForm'
import BusinessDetailsForm from '../../components/AuthForms/BusinessDetailsForm'
import AuthReviewTerms from '../../components/AuthReviewTerms/AuthReviewTerms'
import CheckedSVG from '../../images/CheckedSVG'
import XSVG from '../../images/XSVG'
import { useAPI } from '../../hooks/useAPI'
import { AuthService } from '../../API/AuthService'
import { BusinessesService } from '../../API/BusinessesService'
import { BusinessSettingsGeneralService } from '../../API/BusinessSettingsGeneralService'
import { useNavigate } from 'react-router-dom'

interface AuthSignUpProps {
	recaptchaRef: MutableRefObject<any>
}

const AuthSignUp: FC<AuthSignUpProps> = ({ recaptchaRef }) => {
	const [step, setStep] = useState(0)
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [businessName, setBusinessName] = useState('')
	const [businessDetails, setBusinessDetails] = useState('')
	const [businessSiteUrl, setBusinessSiteUrl] = useState('')
	const [monthlyRecurringRevenue, setMonthlyRecurringRevenue] = useState('')
	const [businessLogo, setBusinessLogo] = useState<File | null>(null)

	const [isBusinessLogoLoading, , updateBusinessLogo] = useAPI(
		(businessId: number) => {
			const formData = new FormData()
			formData.append('file', businessLogo || 'null')
			return BusinessSettingsGeneralService.updateLogo(
				businessId,
				formData,
				localStorage.getItem('token') || undefined
			)
		},
		false,
		undefined,
		{
			onSuccess: () => {
				window.location.pathname = '/'
			},
		}
	)

	const [isCreateBusinessLoading, , createBusiness] = useAPI(
		(token: string) =>
			BusinessesService.create(
				{
					siteUrl: 'https://' + businessSiteUrl,
					name: businessName,
					details: businessDetails,
					mrr: monthlyRecurringRevenue || null,
				},
				token
			),
		false,
		'Successfully created',
		{
			onSuccess: response => {
				if (businessLogo) {
					updateBusinessLogo(response.data.data.id)
				} else {
					window.location.pathname = '/'
				}
			},
		}
	)

	const [isRegisterLoading, , register] = useAPI<{
		accessToken: string
	}>(
		async () => {
			const recaptcha = await recaptchaRef.current.executeAsync()
			recaptchaRef.current.reset()

			return AuthService.register({
				email: email,
				password: password,
				recaptcha: recaptcha,
			})
		},
		false,
		'Successfully signed up',
		{
			onSuccess: response => {
				localStorage.setItem('token', response.data.data.accessToken)
				createBusiness(response.data.data.accessToken)
			},
		}
	)

	useEffect(() => {
		if (localStorage.getItem('token')) {
			navigate('/')
		}
	}, [])

	return (
		<div className={styles.authForm}>
			<div className={styles.stepsSidebar}>
				<div className={styles.stepDetail}>
					<div className={styles.stepDetailHead}>
						<ProfileIcon />
						<h3>Create account</h3>
					</div>
					{step === 0 ? (
						<HourglassSVG className={styles.hourglassSVG} />
					) : (
						<CheckedSVG className={styles.checkedSVG} />
					)}
				</div>
				<div className={styles.stepDetail}>
					<div className={styles.stepDetailHead}>
						<MarketSVG />
						<h3>Business details</h3>
					</div>
					{step === 1 ? (
						<HourglassSVG className={styles.hourglassSVG} />
					) : step < 1 ? (
						<XSVG className={styles.xSVG} />
					) : (
						<CheckedSVG className={styles.checkedSVG} />
					)}
				</div>
				<div className={styles.stepDetail}>
					<div className={styles.stepDetailHead}>
						<PaperSVG />
						<h3>Review terms</h3>
					</div>
					{step === 2 ? (
						<HourglassSVG className={styles.hourglassSVG} />
					) : step < 2 ? (
						<XSVG className={styles.xSVG} />
					) : (
						<CheckedSVG className={styles.checkedSVG} />
					)}
				</div>
			</div>
			<div className={styles.stepWrapper}>
				{step === 0 && (
					<CreateAccountForm
						recaptchaRef={recaptchaRef}
						setStep={setStep}
						setEmail={setEmail}
						setPassword={setPassword}
						email={email}
						password={password}
					/>
				)}
				{step === 1 && (
					<BusinessDetailsForm
						setStep={setStep}
						businessDetails={businessDetails}
						businessName={businessName}
						businessLogo={businessLogo}
						monthlyRecurringRevenue={monthlyRecurringRevenue}
						setBusinessDetails={setBusinessDetails}
						businessSiteUrl={businessSiteUrl}
						setBusinessLogo={setBusinessLogo}
						setBusinessName={setBusinessName}
						setBusinessSiteUrl={setBusinessSiteUrl}
						setMonthlyRecurringRevenue={setMonthlyRecurringRevenue}
					/>
				)}
				{step === 2 && (
					<AuthReviewTerms
						register={register}
						isCreateBusinessLoading={isCreateBusinessLoading}
						isBusinessLogoLoading={isBusinessLogoLoading}
						isRegisterLoading={isRegisterLoading}
					/>
				)}
			</div>
		</div>
	)
}

export default AuthSignUp
