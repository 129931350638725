import React from 'react'

const ScrollSVG = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.0603 5.03369H9.5603M6.0603 8.03369H9.5603M13.9353 1.03369C13.0378 1.03369 12.5603 2.04105 12.5603 3.28369V3.53369M13.9353 1.03369C14.8328 1.03369 15.5603 2.04105 15.5603 3.28369V3.53369H12.5603M13.9353 1.03369H5.0603C3.95573 1.03369 3.0603 1.92912 3.0603 3.03369V13.0337M12.5603 3.53369V13.2837C12.5603 14.5263 11.5828 15.5337 10.6853 15.5337M3.0603 13.0337H9.0603V13.2837C9.0603 14.5263 9.7878 15.5337 10.6853 15.5337M3.0603 13.0337H1.0603V13.5337C1.0603 14.6383 1.95573 15.5337 3.0603 15.5337H10.6853'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default ScrollSVG
