import React from 'react'

const DocumentSvg = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.25 11.9705L4.75 15.4705M4.75 11.9705L1.25 15.4705M8.25 15.4705H11.75M5.25 4.97046H11.75M6.25 7.97046H10.75M15.75 15.4705V2.97046C15.75 1.86589 14.8546 0.970459 13.75 0.970459H3.25C2.14543 0.970459 1.25 1.86589 1.25 2.97046V8.47046'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default DocumentSvg
