import React, { FC } from 'react'
import styles from './StatusCodeBadge.module.scss'

interface StatusCodeBadgeProps {
	statusCode: number
}

const StatusCodeBadge: FC<StatusCodeBadgeProps> = ({ statusCode }) => {
	const isSuccess = statusCode === 200

	return (
		<div
			className={`${styles.statusCodeBadge} ${statusCode === 200 ? styles.success : styles.error}`}
		>
			{statusCode} {isSuccess ? 'OK' : 'ERR'}
		</div>
	)
}

export default StatusCodeBadge
