import React from 'react'

const MoonSvg = () => {
	return (
		<svg width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.25 11.3961C13.7575 11.5083 13.2459 11.5674 12.7209 11.5674C8.8082 11.5674 5.63607 8.28325 5.63607 4.23184C5.63607 3.11728 5.87614 2.06078 6.3057 1.11475C3.12795 1.83862 0.75 4.77134 0.75 8.27905C0.75 12.3304 3.9221 15.6147 7.8348 15.6147C10.6711 15.6147 13.1182 13.8889 14.25 11.3961Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default MoonSvg
