import { privateAPI } from './API'

export const DashboardService = {
	getMainStat: async (
		businessId: number | undefined,
		fromTime: Date | null,
		toTime: Date | null
	) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/dashboard/main`, {
			params: {
				fromTime,
				toTime,
			},
		})
	},
	getMainGraphStats: async (
		businessId: number | undefined,
		fromTime: Date | null,
		toTime: Date | null
	) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/dashboard/main/graphs`, {
			params: {
				fromTime,
				toTime,
			},
		})
	},
	getMainTextStats: async (
		businessId: number | undefined,
		fromTime: Date | null,
		toTime: Date | null
	) => {
		return await privateAPI.get(`/v1/dash/businesses/${businessId}/dashboard/main/text`, {
			params: {
				fromTime,
				toTime,
			},
		})
	},
}
