import React, { FC, SVGProps } from 'react'

const Xsvg: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='12'
			height='11'
			viewBox='0 0 12 11'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M10.2745 0.999512L1.27344 10.0006M1.27344 0.999512L10.2745 10.0006'
				stroke='#C11B40'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default Xsvg
