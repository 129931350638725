import React, { FC, ReactNode } from 'react'
import styles from './FormComponents.module.scss'

export enum SelectStyles {
	secondary = 'secondary',
}

interface SelectProps {
	value: string
	setValue: (value: string) => void
	children: ReactNode[]
	style?: SelectStyles
}

const Select: FC<SelectProps> = ({ children, setValue, value, style }) => {
	const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setValue(event.target.value)
	}

	return (
		<div className={`${styles.selectWrapper} ${style && styles[style]}`}>
			<select className={`${styles.select}`} value={value} onChange={handleOnChange}>
				{children}
			</select>
		</div>
	)
}

export default Select
