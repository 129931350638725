import React from 'react'

const ErrorStatusIcon = () => {
	return (
		<svg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.20801 1.34961L1.13892 8.4187M1.13892 1.34961L8.20801 8.4187'
				stroke='#C11B40'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default ErrorStatusIcon
