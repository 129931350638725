import React, { FC, useEffect, useState } from 'react'
import styles from './WebhookEventAttempts.module.scss'
import CheckedSVG from '../../images/CheckedSVG'
import XSVG from '../../images/XSVG'
import { JsonViewer } from '@textea/json-viewer'
import StatusCodeBadge from '../UI/StatusCodeBadge/StatusCodeBadge'
import RequestMethodBadge from '../UI/RequestMethodBadge/RequestMethodBadge'
import { WebhookAttempt } from '../../types/WebhookDetailed'
import { format } from 'date-fns'
import {
	WebhookMessageEventStatus,
	WebhookMessageEventStatusEnum,
} from '../../types/WebhookMessageEventStatus'

interface WebhookEventAttemptsProps {
	attempts: WebhookAttempt[]
	payloadJson: string
}

const WebhookEventAttempts: FC<WebhookEventAttemptsProps> = ({ attempts, payloadJson }) => {
	const wasAttemptSucceed = (status: WebhookMessageEventStatus) => {
		return status === WebhookMessageEventStatusEnum.SUCCESS
	}
	const [filter, setFilter] = useState<WebhookMessageEventStatus | null>(null)
	const [selectAttempt, setSelectAttempt] = useState<WebhookAttempt>(attempts[0])
	const [filteredAttempts, setFilteredAttempts] = useState<WebhookAttempt[]>(attempts)

	useEffect(() => {
		setFilteredAttempts(attempts.filter(attempt => (filter ? attempt.status === filter : true)))
	}, [filter])

	return (
		<div className={styles.webhookEventAttempts}>
			<header className={styles.header}>
				<div className={styles.head}>
					<h1>Attempts</h1>
					<div className={styles.filterTabs}>
						<h3 className={!filter ? styles.active : ''} onClick={() => setFilter(null)}>
							All
						</h3>
						<h3
							className={filter === WebhookMessageEventStatusEnum.SUCCESS ? styles.active : ''}
							onClick={() => setFilter(WebhookMessageEventStatusEnum.SUCCESS)}
						>
							Succeeded
						</h3>
						<h3
							className={filter === WebhookMessageEventStatusEnum.FAIL ? styles.active : ''}
							onClick={() => setFilter(WebhookMessageEventStatusEnum.FAIL)}
						>
							Failed
						</h3>
					</div>
				</div>
				<div className={styles.attemptDetails}>
					<div className={styles.detail}>
						<span>Status</span>
						<p>{selectAttempt.status}</p>
					</div>
					<div className={styles.detail}>
						<span>Trigger Type</span>
						<p>{selectAttempt.triggerType}</p>
					</div>
					<div className={styles.detail}>
						<span>Created At</span>
						<p>{format(new Date(selectAttempt.createdAt), 'MMM dd, yyyy')}</p>
					</div>
				</div>
			</header>
			<div className={styles.body}>
				<div className={styles.attemptItems}>
					{filteredAttempts.map(attempt => (
						<div
							key={attempt.id}
							className={`${styles.attemptItemWrapper}`}
							onClick={() => setSelectAttempt(attempt)}
						>
							<div
								className={`${styles.attemptItem} ${
									selectAttempt.id === attempt.id ? styles.active : ''
								}`}
							>
								<div className={styles.head}>
									<div
										className={`${styles.attemptStatus} ${
											!wasAttemptSucceed(attempt.status) ? styles.error : ''
										}`}
									>
										{wasAttemptSucceed(attempt.status) ? <CheckedSVG /> : <XSVG />}
									</div>
									<p>
										{attempt.responseStatusCode} {wasAttemptSucceed(attempt.status) ? 'OK' : 'ERR'}{' '}
										- {attempt.triggerType}
									</p>
								</div>

								<time>
									{' '}
									{format(new Date(attempt.createdAt), 'MMM dd, yyyy')} at{' '}
									{format(new Date(attempt.createdAt), 'HH:mm aa zzzz')}
								</time>
							</div>
						</div>
					))}
				</div>
				<div className={styles.attemptEventBody}>
					<div>
						<h2>Request event body</h2>
						<StatusCodeBadge statusCode={selectAttempt.responseStatusCode} />
						<div className={styles.jsonWrapper}>
							<JsonViewer
								style={{ color: 'var(--dark_color)' }}
								value={JSON.parse(payloadJson)}
								className={styles.jsonViewer}
							/>
						</div>
					</div>

					<div>
						<h2>Response body</h2>
						<RequestMethodBadge method={'POST'} />
						<div className={styles.jsonWrapper}>
							<p className={styles.noPostBody}>No POST body</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WebhookEventAttempts
