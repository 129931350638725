import React, { FC, useState } from 'react'
import styles from './Modals.module.scss'
import Input from '../UI/FormComponents/Input'
import Button, { ButtonStyles } from '../UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { UserSettingsDeveloperService } from '../../API/UserSettingsDeveloperService'
import { BusinessSettingsDeveloperService } from '../../API/BusinessSettingsDeveloperService'
import { useAppContext } from '../../hooks/useAppContext'
import Switch from '../UI/Switch/Switch'
import { WebhookEvent, WebhookEventsEnum } from '../../types/WebhookEvent'
import Textarea from '../UI/FormComponents/Textarea'

interface WebhookSharedSecretSideModalProps {
	closeModal: () => void
	getWebhooks: () => void
}

const WebhookSharedSecretSideModal: FC<WebhookSharedSecretSideModalProps> = ({
	closeModal,
	getWebhooks,
}) => {
	const [endpoint, setEndpoint] = useState('')
	const [description, setDescription] = useState('')
	const { business } = useAppContext()
	const [eventsState, setEventsState] = useState({
		[WebhookEventsEnum.PAYMENT_CREATED]: false,
		[WebhookEventsEnum.PAYMENT_EXPIRED]: false,
		[WebhookEventsEnum.PAYMENT_CANCELLED]: false,
		[WebhookEventsEnum.PAYMENT_METHOD_SELECTED]: false,
		[WebhookEventsEnum.PAYMENT_COMPLETED]: false,
	})
	const [errors, setErrors] = useState({
		endpoint: '',
		description: '',
	})

	const validator = () => {
		setErrors({
			endpoint: endpoint ? '' : 'Required',
			description: description ? '' : 'Required',
		})

		return !!(description && endpoint)
	}

	const webhookEvents = {
		[WebhookEventsEnum.PAYMENT_COMPLETED]: {
			eventName: 'payment:completed',
			description: 'Sent when a payment is successfully processed.',
		},
		[WebhookEventsEnum.PAYMENT_CANCELLED]: {
			eventName: 'payment:cancelled',
			description: 'Sent when a payment is cancelled.',
		},
		[WebhookEventsEnum.PAYMENT_EXPIRED]: {
			eventName: 'payment:expired',
			description: 'Sent when a payment is expired.',
		},
		[WebhookEventsEnum.PAYMENT_METHOD_SELECTED]: {
			eventName: 'payment:method_selected',
			description: 'Sent when a payment method selected.',
		},
		[WebhookEventsEnum.PAYMENT_CREATED]: {
			eventName: 'payment:created',
			description: 'Sent when a payment is successfully created.',
		},
	}

	const [isLoading, , addWebhook] = useAPI(
		() =>
			BusinessSettingsDeveloperService.addWebhooks(business?.id, {
				url: endpoint || null,
				events: Object.keys(eventsState).filter(
					eventName => eventsState[eventName as WebhookEvent]
				) as WebhookEvent[],
				description: description || null,
			}),
		false,
		'Webhook successfully added',
		{
			onSuccess: () => {
				closeModal()
				getWebhooks()
			},
			validator: validator,
		}
	)

	return (
		<div className={styles.modalInner}>
			<h1 className={styles.h1}>Create webhook subscription</h1>
			<div className={styles.fieldWrapper}>
				<h3>ENDPOINT</h3>
				<Input
					value={endpoint}
					setValue={setEndpoint}
					placeholder={'Endpoint (https://...)'}
					isError={!!errors.endpoint}
					errorMessage={errors.endpoint}
				/>
			</div>
			<div className={styles.fieldWrapper}>
				<h3>Description</h3>
				<Textarea
					value={description}
					setValue={setDescription}
					placeholder={'Description'}
					isError={!!errors.description}
					errorMessage={errors.description}
				/>
			</div>
			<h1 className={styles.h1}>Events</h1>
			<div className={styles.switches}>
				{Object.keys(webhookEvents).map(webhookEvent => (
					<div className={styles.switchWrapper}>
						<div>
							<p>{webhookEvents[webhookEvent as WebhookEvent].eventName}</p>
							<span>{webhookEvents[webhookEvent as WebhookEvent].description}</span>
						</div>
						<Switch
							checked={eventsState[webhookEvent as WebhookEvent]}
							setChecked={checked =>
								setEventsState({
									...eventsState,
									[webhookEvent]: checked,
								})
							}
						/>
					</div>
				))}
			</div>
			<div className={styles.buttons}>
				<Button style={ButtonStyles.TRANSPARENT}>Cancel</Button>
				<Button onClick={addWebhook} isLoading={isLoading}>
					Save
				</Button>
			</div>
		</div>
	)
}

export default WebhookSharedSecretSideModal
