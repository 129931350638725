import React from 'react'

const GunSvg = () => {
	return (
		<svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.25 6.22046L1.71967 6.75076C1.86032 6.89146 2.05109 6.97046 2.25 6.97046V6.22046ZM9.25 6.22046L9.9776 6.03856C9.8941 5.70468 9.5942 5.47046 9.25 5.47046V6.22046ZM11.25 14.2205L10.5224 14.4024C10.6059 14.7363 10.9058 14.9705 11.25 14.9705V14.2205ZM13.25 6.22046L12.7197 5.69013C12.5279 5.88194 12.4546 6.16236 12.528 6.42356L13.25 6.22046ZM15.5 3.97046L16.0303 4.50079C16.171 4.36014 16.25 4.16937 16.25 3.97046H15.5ZM15.5 1.72046H16.25C16.25 1.30625 15.9142 0.970459 15.5 0.970459V1.72046ZM2.25 1.72046V0.970459C2.05109 0.970459 1.86032 1.04948 1.71967 1.19013L2.25 1.72046ZM1 2.97046L0.46967 2.44013C0.32902 2.58078 0.25 2.77155 0.25 2.97046H1ZM1 4.97046H0.25C0.25 5.16937 0.32902 5.36014 0.46967 5.50079L1 4.97046ZM2.25 6.97046H9.25V5.47046H2.25V6.97046ZM8.5224 6.40236L10.5224 14.4024L11.9776 14.0386L9.9776 6.03856L8.5224 6.40236ZM11.25 14.9705H12.8599V13.4705H11.25V14.9705ZM15.5072 11.476L13.972 6.01736L12.528 6.42356L14.0632 11.8821L15.5072 11.476ZM13.7803 6.75076L16.0303 4.50079L14.9697 3.44013L12.7197 5.69013L13.7803 6.75076ZM16.25 3.97046V1.72046H14.75V3.97046H16.25ZM15.5 0.970459H2.25V2.47046H15.5V0.970459ZM1.71967 1.19013L0.46967 2.44013L1.53033 3.50079L2.78033 2.25079L1.71967 1.19013ZM0.25 2.97046V4.97046H1.75V2.97046H0.25ZM0.46967 5.50079L1.71967 6.75076L2.78033 5.69013L1.53033 4.44013L0.46967 5.50079ZM12.8599 14.9705C14.6822 14.9705 16.0006 13.2302 15.5072 11.476L14.0632 11.8821C14.2875 12.6795 13.6882 13.4705 12.8599 13.4705V14.9705Z'
				fill='#1F1F1F'
			/>
			<path
				d='M5.70485 6.21416C5.56329 5.82488 5.13297 5.62406 4.74369 5.76561C4.35442 5.90717 4.1536 6.33746 4.29515 6.72676L5.70485 6.21416ZM9.75 9.97046C10.1642 9.97046 10.5 9.63466 10.5 9.22046C10.5 8.80626 10.1642 8.47046 9.75 8.47046V9.97046ZM4.29515 6.72676L4.81642 8.16026L6.22611 7.64766L5.70485 6.21416L4.29515 6.72676ZM7.4009 9.97046H9.75V8.47046H7.4009V9.97046ZM4.81642 8.16026C5.21161 9.24706 6.24446 9.97046 7.4009 9.97046V8.47046C6.8752 8.47046 6.4057 8.14166 6.22611 7.64766L4.81642 8.16026Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default GunSvg
