import React, { FC, ReactNode } from 'react'
import styles from './SecretKeyWrapper.module.scss'
import CopyIcon from '../../../images/CopyIcon'
import { useCopy } from '../../../hooks/useCopy'
import KeySVG from '../../../images/KeySVG'

interface SecretKeyWrapperProps {
	secretKey: string
	icon: ReactNode
}

const SecretKeyWrapper: FC<SecretKeyWrapperProps> = ({ secretKey, icon }) => {
	const convertToSecret = (secretKey: string) => {
		const slice = secretKey.slice(5, secretKey.length - 4)
		return secretKey.replace(slice, ' ••••••••••••••••••• ')
	}
	const copyToClipboard = useCopy()

	return (
		<div className={styles.secretKeyWrapper} onClick={() => copyToClipboard(secretKey)}>
			{icon}
			<p>{convertToSecret(secretKey)}</p>
		</div>
	)
}

export default SecretKeyWrapper
