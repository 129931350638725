import React, { FC } from 'react'
import styles from './WarningLink.module.scss'
import WarningIcon from '../../../images/WarningIcon'
import OpenLinkSVG from '../../../images/OpenLinkSVG'

interface WarningLinkProps {
	href: string
	text: string
}

const WarningLink: FC<WarningLinkProps> = ({ href, text }) => {
	return (
		<a className={styles.warningLink} href={href} target={'_blank'}>
			<WarningIcon />
			<p>{text}</p>
			<OpenLinkSVG />
		</a>
	)
}

export default WarningLink
