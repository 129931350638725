import { privateAPI } from './API'
import { PaymentPost } from '../types/PaymentPost'
import { Status } from '../types/Status'
import { Cryptocurrency } from '../types/Cryptocurrencies'

export const PaymentsService = {
	getAll: async (
		businessId: number | undefined,
		PageNumber?: number,
		PageSize?: number,
		fromTime?: Date | null,
		toTime?: Date | null,
		status?: Status | null,
		paymentMethod?: Cryptocurrency | null,
		fromAmount?: number | null,
		toAmount?: number | null,
		searchString?: string | null,
		onBehalfOfBusinessId?: number | null
	) => {
		return await privateAPI.get(`v1/businesses/${businessId}/payments`, {
			params: {
				PageNumber,
				PageSize,
				fromTime,
				toTime,
				status,
				paymentMethod,
				fromAmount,
				toAmount,
				searchString,
				onBehalfOfBusinessId,
			},
		})
	},
	get: async (businessId: number | undefined, paymentId: string | undefined) => {
		return await privateAPI.get(`v1/businesses/${businessId}/payments/${paymentId}`)
	},
	create: async (businessId: number | undefined, data: PaymentPost) => {
		return await privateAPI.post(`v1/businesses/${businessId}/payments`, data)
	},
	handleNote: async (
		businessId: number | undefined,
		paymentId: string | undefined,
		note: null | string
	) => {
		return await privateAPI.put(`/v1/businesses/${businessId}/payments/${paymentId}/note`, {
			note,
		})
	},
}
