import React, { FC, useState } from 'react'
import styles from './CryptocurrencyConfig.module.scss'
import Button from '../UI/Button/Button'
import bitcoinIcon from '../../images/BitcoinSvg.svg'
import cronosIcon from '../../images/Cronos.svg'
import ethIcon from '../../images/Ethereum.svg'
import tetherIcon from '../../images/Tether.svg'
import ltcIcon from '../../images/LitecoinSvg.svg'
import moneroIcon from '../../images/Monero.svg'
import shibaIcon from '../../images/ShibaInu.svg'
import daiIcon from '../../images/Dai.svg'
import polygonIcon from '../../images/Polygon.svg'
import btcCashIcon from '../../images/BitcoinCash.svg'
import tronIcon from '../../images/Tron.svg'
import usdcIcon from '../../images/USDCoin.svg'
import { Cryptocurrencies, Cryptocurrency } from '../../types/Cryptocurrencies'
import ActiveBadge from '../UI/ActiveBadge/ActiveBadge'
import { CryptocurrencySetting } from '../../types/CryptocurrencySetting'
import { getPaymentMethodIconId } from '../../utils/getPaymentMethodIconId'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { Settings } from '../../types/Settings'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'

interface CryptocurrencyConfigProps {
	cryptocurrency: Cryptocurrency
	setCurrentCryptocurrency: (value: {
		cryptocurrency: Cryptocurrency
		networks?: {
			ethereum: Cryptocurrency
			tron: Cryptocurrency
		}
	}) => void
	setIsCryptoModalActive: (value: boolean) => void
	networks?: {
		ethereum: Cryptocurrency
		tron: Cryptocurrency
	}
	settings?: Settings
}

const CryptocurrencyConfig: FC<CryptocurrencyConfigProps> = ({
	cryptocurrency,
	setCurrentCryptocurrency,
	setIsCryptoModalActive,
	networks,
	settings,
}) => {
	const isActive =
		!!settings?.directCryptoSettings?.cryptos
			.filter(
				cryptoSetting =>
					cryptoSetting.crypto === networks?.ethereum ||
					cryptoSetting.crypto === networks?.tron ||
					cryptoSetting.crypto === cryptocurrency
			)
			.some(cryptoSetting => cryptoSetting.isActive) ||
		!!settings?.xPubCryptoSettings?.cryptos.find(
			cryptoSetting => cryptoSetting.cryptoCoin === cryptocurrency
		)?.isActive ||
		false

	const cryptocurrencies = {
		[Cryptocurrencies.ETH_CRONOS]: {
			name: 'Cronos',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_DAI]: {
			name: 'Dai',
			type: 'eth',
		},
		[Cryptocurrencies.MONERO]: {
			name: 'Monero',
			type: null,
		},
		[Cryptocurrencies.BITCOIN_CASH]: {
			name: 'Bitcoin Cash',
			type: null,
		},
		[Cryptocurrencies.ETHEREUM]: {
			name: 'Ethereum',
			type: null,
		},
		[Cryptocurrencies.LITECOIN]: {
			name: 'Litecoin',
			type: null,
		},
		[Cryptocurrencies.BITCOIN]: {
			name: 'Bitcoin',
			type: null,
		},
		[Cryptocurrencies.ETH_SHIBA_INU]: {
			name: 'Shiba Inu',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_USD_COIN]: {
			name: 'USD Coin',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_TETHER]: {
			name: 'Tether',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_BNB]: {
			name: 'Binance Coin',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_APE_COIN]: {
			name: 'Ape Coin',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_BUSD]: {
			name: 'Binance USD',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_MATIC]: {
			name: 'Polygon',
			type: 'eth',
		},
		[Cryptocurrencies.ETH_UNISWAP]: {
			name: 'Uniswap',
			type: 'eth',
		},
		[Cryptocurrencies.TRON]: {
			name: 'Tron',
			type: null,
		},
		[Cryptocurrencies.TRX_USD_C]: {
			name: 'USD Coin',
			type: 'trx',
		},
		[Cryptocurrencies.TRX_TETHER]: {
			name: 'Tether',
			type: 'trx',
		},
	}

	const onClickConfigureHandler = (event: React.MouseEvent) => {
		event.stopPropagation()
		setIsCryptoModalActive(true)
		setCurrentCryptocurrency({
			cryptocurrency: cryptocurrency,
			networks: networks,
		})
	}

	return (
		<div className={styles.cryptoConfig}>
			<div className={styles.head}>
				<SpriteIcon iconId={getPaymentMethodIconId(cryptocurrency) || ''} width={24} height={24} />
				<div className={styles.name}>
					<h3>{cryptocurrencies[cryptocurrency].name}</h3>
					<p className={styles.p}>{getCryptoShortName(cryptocurrency)}</p>
				</div>
			</div>
			<div className={styles.tail}>
				<ActiveBadge isActive={isActive} onClick={onClickConfigureHandler} />
			</div>
		</div>
	)
}

export default CryptocurrencyConfig
