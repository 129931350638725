import React, { FC, ReactNode } from 'react'
import styles from './Button.module.scss'
import Spinner from '../Spinner/Spinner'

interface ButtonProps {
	icon?: ReactNode
	children: ReactNode
	style?:
		| ButtonStyles.SECONDARY
		| ButtonStyles.TRANSPARENT
		| ButtonStyles.DANGER
		| ButtonStyles.PRIMARY
		| ButtonStyles.THIRD
	size?: 'small' | 'large'
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	isLoading?: boolean
	isDisabled?: boolean
	width?: string | number
}

export enum ButtonStyles {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	THIRD = 'third',
	TRANSPARENT = 'transparent',
	DANGER = 'danger',
}

const Button: FC<ButtonProps> = ({
	style,
	icon,
	children,
	size,
	onClick,
	isLoading,
	isDisabled,
	width,
}) => {
	return (
		<button
			onClick={event => {
				event.stopPropagation()
				onClick && onClick(event)
			}}
			disabled={isDisabled || isLoading}
			className={`${styles.button} ${style && styles[style]} ${size && styles[size]}`}
			style={{
				width,
			}}
		>
			{children}
			{icon}
			{isLoading && <Spinner className={styles.spinner} />}
		</button>
	)
}

export default Button
