import { useAppContext } from './useAppContext'

export const useCopy = () => {
	const { setActionMessage } = useAppContext()

	return (value: string) => {
		navigator.clipboard.writeText(value)
		setActionMessage({
			status: true,
			message: 'Successfully copied',
			title: 'Success',
			isActive: true,
		})
	}
}
