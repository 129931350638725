import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './Profile.module.scss'
import HoodpayLogo from '../../images/HoodpayLogo'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../UI/Button/Button'
import PlusIcon from '../../images/PlusIcon'
import { useAPI } from '../../hooks/useAPI'
import { UserService } from '../../API/UserService'
import { useAppContext } from '../../hooks/useAppContext'
import { Business } from '../../types/Business'
import defaultProfileLogo from '../../images/ProfileLogo.png'
import GearIcon from '../../images/GearIcon'
import PhoneSVG from '../../images/PhoneSVG'
import CardSVG from '../../images/CardSVG'
import SignOutSvg from '../../images/SignOutSVG'

interface ProfileProps {
	setIsActive: (value: boolean) => void
	isActive: boolean
}

const Profile: FC<ProfileProps> = ({ setIsActive, isActive }) => {
	const [isVisible, setIsVisible] = useState(false)

	const { user, business, setBusiness } = useAppContext()
	const [isLoading, , logout] = useAPI(() => UserService.logout(), false, 'Successfully logout', {
		onSuccess: () => {
			localStorage.removeItem('businessId')
			localStorage.removeItem('token')
			window.location.pathname = '/'
		},
	})

	const closeProfile = () => {
		setIsVisible(false)
		setTimeout(() => {
			setIsActive(false)
		}, 200)
	}

	useEffect(() => {
		if (isActive) {
			setTimeout(() => {
				setIsVisible(true)
			}, 10)
		}
	}, [isActive])

	return (
		<>
			<div
				className={`${styles.background} ${isActive && styles.active} ${
					isVisible && styles.visible
				}`}
				onClick={closeProfile}
			/>
			<div
				className={`${styles.profile} ${isActive && styles.active} ${isVisible && styles.visible}`}
			>
				<div className={styles.business}>
					<div className={`${styles.profileLogo} ${business?.url ? styles.noBg : ''}`}>
						{business?.url && (
							<img src={`https://imagedelivery.net/${business.url}/logo`} alt='businessLogo' />
						)}
					</div>
					<div>
						<h2>{business?.name}</h2>
						<p>{user?.email}</p>
					</div>
				</div>
				<div className={styles.links}>
					<ul>
						<li>
							<a href={'https://support.hoodpay.io\n'} target={'_blank'}>
								<PhoneSVG />
								Contact support
							</a>
						</li>
						<li>
							<Link to={'/payments'}>
								<CardSVG />
								View payments
							</Link>
						</li>
						<li>
							<Link to={'/settings/general'}>
								<GearIcon />
								Account settings
							</Link>
						</li>
						<li
							className={styles.red}
							onClick={() => {
								logout()
							}}
						>
							<a>
								<SignOutSvg />
								Sign Out
							</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default Profile
