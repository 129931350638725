import React, { ChangeEvent, FC } from 'react'
import Input from '../FormComponents/Input'
import styles from './Search.module.scss'
import SearchIcon from '../../../images/SearchIcon'

export enum SearchInputStyles {
	secondary = 'secondary',
}

interface SearchInputProps {
	value: string
	setValue: (value: string) => void
	customClass?: string
	style?: SearchInputStyles
	placeholder?: string
}

const SearchInput: FC<SearchInputProps> = ({
	value,
	setValue,
	customClass,
	style,
	placeholder,
}) => {
	return (
		<div className={`${styles.searchInput} ${customClass} ${style && styles[style]}`}>
			<div className={styles.iconWrapper}>
				<SearchIcon />
			</div>
			<Input value={value} setValue={setValue} placeholder={placeholder} />
		</div>
	)
}

export default SearchInput
