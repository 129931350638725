import { privateAPI } from './API'
import { WebhookEvent } from '../types/WebhookEvent'

export const BusinessSettingsDeveloperService = {
	getWebhooks: async (businessId: number | undefined) => {
		return await privateAPI.get(`v1/dash/businesses/${businessId}/settings/developer/webhooks`)
	},
	addWebhooks: async (
		businessId: number | undefined,
		data: {
			url: string | null
			description: string | null
			events: WebhookEvent[]
		}
	) => {
		return await privateAPI.post(
			`v1/dash/businesses/${businessId}/settings/developer/webhooks`,
			data
		)
	},
	resetWebhooksSecret: async (businessId: number | undefined) => {
		return await privateAPI.post(
			`v1/dash/businesses/${businessId}/settings/developer/webhooks/reset-secret`
		)
	},
	deleteWebhook: async (businessId: number | undefined, webhookId: string) => {
		return await privateAPI.delete(
			`/v1/dash/businesses/${businessId}/settings/developer/webhooks/${webhookId}`
		)
	},
}
