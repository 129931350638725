import React, { FC, useEffect, useState } from 'react'
import styles from './Tables.module.scss'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import RefreshIcon from '../../images/RefreshIcon'
import { useAPI } from '../../hooks/useAPI'
import { PaymentWebhooksService } from '../../API/PaymentWebhooksService'
import { Webhook } from '../../types/Webhook'
import StatusCodeBadge from '../UI/StatusCodeBadge/StatusCodeBadge'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { ApiKey } from '../../types/ApiKey'
import { UserSettingsDeveloperService } from '../../API/UserSettingsDeveloperService'
import { useCopy } from '../../hooks/useCopy'
import SecretKeyWrapper from '../UI/SecretKeyWrapper/SecretKeyWrapper'
import SettingsButton from '../UI/SettingsButton/SettingsButton'
import CopyIcon from '../../images/CopyIcon'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { is } from 'date-fns/locale'
import TrashCanSVG from '../../images/TrashCanSVG'
import Spinner from '../UI/Spinner/Spinner'
import KeySVG from '../../images/KeySVG'

interface APIKeysTableProps {
	apiKeys?: ApiKey[]
	isLoading?: boolean
	getApiKeys: () => void
}

interface TableBodyProps {
	apiKey: ApiKey
	deleteApiKey: (value: number) => void
}

const TableBody: FC<TableBodyProps> = ({ apiKey, deleteApiKey }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	return (
		<div className={styles.tableBodyWrapper} key={apiKey.id}>
			<div className={styles.tableBody}>
				<div className={`${styles.tableBodyItem} ${styles.full}`}>
					<SecretKeyWrapper secretKey={apiKey.accessToken} icon={<KeySVG />} />
				</div>
				<div className={`${styles.tableBodyItem} ${styles.medium}`}>
					<p>{apiKey.name || '-'}</p>
				</div>
				<div className={styles.tableBodyItem}>
					<p>{apiKey.expiresAt ? format(new Date(apiKey.expiresAt), 'MMM dd, hh:mm aa') : '-'}</p>
					{/*<p>{format(new Date(apiKey.expiresAt), 'MMM dd, yyyy')}</p>*/}
				</div>
				<div className={`${styles.tableBodyItem} ${styles.settings}`}>
					<div className={styles.actionMenu}>
						<SettingsButton onClick={() => setIsActionMenuActive(!isActionMenuActive)} />
						<ActionMenu
							actionButtons={[
								{
									title: 'Delete',
									onClick: () => deleteApiKey(apiKey.id),
									icon: <TrashCanSVG />,
								},
							]}
							isActive={isActionMenuActive}
							setIsActive={setIsActionMenuActive}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
const APIKeysTable: FC<APIKeysTableProps> = ({ apiKeys, getApiKeys, isLoading }) => {
	const [isDeleteApiKeyLoading, isApiKeyDeleted, deleteApiKey] = useAPI(
		(apiKeyId: number) => UserSettingsDeveloperService.deleteApiKey(apiKeyId),
		false,
		'API Key successfully deleted',
		{
			onSuccess: () => getApiKeys(),
		}
	)

	if (isLoading) return <Spinner className={styles.spinner} />

	return (
		<div className={styles.table}>
			<div className={styles.tableHeader}>
				<h3 className={styles.full}>KEY</h3>
				<h3 className={styles.medium}>NAME</h3>
				<h3>Expires At</h3>
				<h3 className={styles.settings} />
			</div>
			{apiKeys?.map(apiKey => (
				<TableBody apiKey={apiKey} key={apiKey.id} deleteApiKey={deleteApiKey} />
			))}
		</div>
	)
}

export default APIKeysTable
