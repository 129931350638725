import { Cryptocurrencies, Cryptocurrency } from '../types/Cryptocurrencies'

export const getCryptoShortName = (crypto: Cryptocurrency | undefined) => {
	switch (crypto) {
		case Cryptocurrencies.BITCOIN:
			return 'BTC'
		case Cryptocurrencies.BITCOIN_CASH:
			return 'BCH'
		case Cryptocurrencies.LITECOIN:
			return 'LTC'
		case Cryptocurrencies.ETH_BUSD:
			return 'BUSD'
		case Cryptocurrencies.ETH_DAI:
			return 'DAI'
		case Cryptocurrencies.ETH_CRONOS:
			return 'CRO'
		case Cryptocurrencies.MONERO:
			return 'XMR'
		case Cryptocurrencies.ETHEREUM:
			return 'ETH'
		case Cryptocurrencies.ETH_TETHER:
			return 'USDT'
		case Cryptocurrencies.ETH_BNB:
			return 'BNB'
		case Cryptocurrencies.ETH_USD_COIN:
			return 'USDC'
		case Cryptocurrencies.ETH_MATIC:
			return 'MATIC'
		case Cryptocurrencies.ETH_UNISWAP:
			return 'UNI'
		case Cryptocurrencies.ETH_APE_COIN:
			return 'APE'
		case Cryptocurrencies.ETH_SHIBA_INU:
			return 'SHIBA'
		case Cryptocurrencies.TRON:
			return 'TRX'
		case Cryptocurrencies.TRX_TETHER:
			return 'TRX_USDT'
		case Cryptocurrencies.TRX_USD_C:
			return 'TRX_USDC'
	}
}
