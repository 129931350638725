import React from 'react'

const WarningIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.78162 13.0518L7.04462 2.55477C7.78252 1.083 9.88332 1.08339 10.6207 2.55542L15.8789 13.0525C16.545 14.3823 15.5781 15.9482 14.0907 15.9482H3.56949C2.08176 15.9482 1.11482 14.3817 1.78162 13.0518Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.82935 6.69824V8.69824'
				stroke='#1F1F1F'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.82935 13.6982C9.38163 13.6982 9.82935 13.2505 9.82935 12.6982C9.82935 12.146 9.38163 11.6982 8.82935 11.6982C8.27706 11.6982 7.82935 12.146 7.82935 12.6982C7.82935 13.2505 8.27706 13.6982 8.82935 13.6982Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default WarningIcon
