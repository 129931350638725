import React, { FC, ReactNode } from 'react'
import styles from './PageNavigation.module.scss'
import { Link, useLocation } from 'react-router-dom'

interface PageNavigationProps {
	links: {
		icon: ReactNode
		title: string
		path: string
	}[]
}

const PageNavigation: FC<PageNavigationProps> = ({ links }) => {
	const location = useLocation()

	return (
		<ul className={styles.pageNavigation}>
			{links.map(link => (
				<li>
					<Link to={link.path} className={location.pathname === link.path ? styles.active : ''}>
						<>
							{link.icon}
							{link.title}
						</>
					</Link>
				</li>
			))}
		</ul>
	)
}

export default PageNavigation
